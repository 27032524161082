import { logger } from '@/scripts/utils/log';
import { useCallback, useEffect, useState } from 'preact/hooks';

export type SquadUpTicketData = {
  id: string;
  type: string; // General Admission, VIP, etc.
  qrcode_str: string | null;
  pdf_url: string | null;
};

export type SquadUpEventData = {
  id: string;
  name: string; // Shrek 5
  start_at: string;
  end_at: string;
  image: {
    thumbnail_url: string | null;
    default_url: string | null;
  };
  location: {
    name: string;
    address_line_1: string;
  };
  location_type: string;
  tickets: SquadUpTicketData[];
};

export type FetchTicketsError = {
  error: true;
  message: string;
};

export const isFetchTicketsError = (data: SquadUpEventData[] | FetchTicketsError): data is FetchTicketsError =>
  'error' in data;

const getTickets = async (): Promise<SquadUpEventData[]> => {
  const response = await window.rownd.connectionAction({
    action_type: 'squadup.list-tickets',
    params: {},
    timeout: 20000, // 20 seconds
    retry: 3,
  });

  return (response.data?.events as SquadUpEventData[]) ?? [];
};

const useTickets = () => {
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [events, setEvents] = useState<SquadUpEventData[]>([]);

  const fetchTickets: () => Promise<SquadUpEventData[] | FetchTicketsError> = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setEvents([]);
    try {
      const tickets = await getTickets();
      setEvents(tickets);
      return tickets;
    } catch (err) {
      logger.error('Error fetching tickets', err);
      const message = err instanceof Error ? err.message : 'Error fetching tickets';
      setError(message);
      return { error: true, message };
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  return { refetchTickets: fetchTickets, isLoading, error, events };
};

export default useTickets;
