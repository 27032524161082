import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { logger } from '../utils/log';

type WakeLockSentinel = {
  release: () => Promise<void>;
  addEventListener: (event: string, callback: () => void) => void;
  removeEventListener: (event: string, callback: () => void) => void;
};

const useWakeLock = () => {
  // eslint-disable-next-line no-undef
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

  const requestWakeLock = useCallback(async () => {
    try {
      const newWakeLock = await (navigator as any)?.wakeLock?.request('screen');
      newWakeLock?.addEventListener('release', () => {
        setWakeLock(null);
      });
      setWakeLock(newWakeLock ?? null);
    } catch (error) {
      logger.error('Error requesting wake lock', error);
    }
  }, []);

  useEffect(() => {
    requestWakeLock();
  }, [requestWakeLock]);

  const releaseWakeLock = useCallback(async () => {
    if (wakeLock) {
      await wakeLock.release();
      setWakeLock(null);
    }
  }, [wakeLock]);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible' && !wakeLock) {
        await requestWakeLock();
      } else if (document.visibilityState === 'hidden' && wakeLock) {
        await releaseWakeLock();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (wakeLock) {
        wakeLock.release();
      }
    };
  }, [wakeLock, requestWakeLock, releaseWakeLock]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    logger.info(`wakeLock: ${wakeLock ? 'connected' : 'not connected'}`);
  }, [wakeLock]);

  return null;
};

export default useWakeLock;
