import { createContext, FunctionalComponent } from 'preact';
import { useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useLocationContext } from '../LocationContext/LocationContext';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import * as AutomationTypes from '@/scripts/Automations/types';

const DurationContext = createContext<undefined | { pageDuration: number; appDuration: number }>(undefined);

export const useDurationContext = () => {
  const context = useContext(DurationContext);

  if (context === undefined) {
    throw new Error('useDurationContext must be used within a DurationContext Provider');
  }

  return context;
};

const DurationProvider: FunctionalComponent = ({ children }) => {
  const { url } = useLocationContext();
  const { state } = useGlobalContext();

  // eslint-disable-next-line no-undef
  const timeInterval = useRef<undefined | NodeJS.Timeout>(undefined);

  const [focused, setFocused] = useState(true);
  const [duration, setDuration] = useState({ pageDuration: 0, appDuration: 0 });

  const containsScopedBasedAutomations = useMemo(() => {
    const automations = state.app.config?.automations;
    if (!automations || automations.length === 0) {
      return false;
    }

    const hasScopedBasedTriggers = automations.some((automation) => {
      const triggers = automation.triggers;
      if (!triggers || triggers.length === 0) {
        return false;
      }

      return triggers.some((trigger) =>
        [
          AutomationTypes.TriggerType.TIME,
          AutomationTypes.TriggerType.TIME_ONCE,
          AutomationTypes.TriggerType.URL,
        ].includes(trigger.type),
      );
    });

    return hasScopedBasedTriggers;
  }, [state.app.config?.automations]);

  useEffect(() => {
    const handleInterval = () => {
      if (!containsScopedBasedAutomations) return;
      if (focused) {
        setDuration((prev) => ({ ...prev, pageDuration: prev.pageDuration + 1 }));
      }
      setDuration((prev) => ({ ...prev, appDuration: prev.appDuration + 1 }));
    };
    timeInterval.current = setInterval(handleInterval, 1000);

    return () => {
      clearInterval(timeInterval.current);
    };
  }, [focused, duration, containsScopedBasedAutomations]);

  // Listen for changes to URL
  useEffect(() => {
    setDuration((prev) => ({ ...prev, pageDuration: 0 })); // Reset pageDuration
  }, [url]);

  // Listen for when tab is active/inactive
  useEffect(() => {
    if (!containsScopedBasedAutomations) return;
    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = () => {
      setFocused(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('focus', handleBlur);
    };
  }, [containsScopedBasedAutomations]);

  return (
    <DurationContext.Provider
      value={{
        appDuration: duration.appDuration,
        pageDuration: duration.pageDuration,
      }}
    >
      {children}
    </DurationContext.Provider>
  );
};

export default DurationProvider;
