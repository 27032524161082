import { IConfig } from '..';

/**
 * Returns whether or not the Hub is running as the root origin. In order for this to be true,
 * rootOrigin must be configured in the Hub config (i.e. not null) and the window's origin much
 * match the value.
 * @param config The Hub config
 * @returns Is root origin configured and are we the root origin?
 */
export function isRootOriginConfiguredAndAreWeTheRootOrigin(config: IConfig) {
  return config?.rootOrigin === null || (config?.rootOrigin && config?.rootOrigin === window.origin);
}
