import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { useGlobalContext, UserData } from './hooks/use-global-context';
import templater from 'templater.js';
import { logger } from './utils/log';
import { useRoute } from './hooks';

export enum VALID_ATTRIBUTE_SELECTORS {
  SIGN_IN_BUTTON = 'data-rownd-sign-in-trigger',
  SIGN_OUT_BUTTON = 'data-rownd-sign-out-trigger',
  MANAGE_ACCOUNT_BUTTON = 'data-rownd-manage-account-trigger',
  FIELD_MAPPING = 'data-rownd-field-mapping',
  FIELD_INTERPOLATION = 'data-rownd-field-interpolate',
  AUTHENTICATED_CONTENT = 'data-rownd-authenticated-content',
  UNAUTHENTICATED_CONTENT = 'data-rownd-unauthenticated-content',
  IMAGE_SIZE = 'data-rownd-image-size', // small, medium, large
  REQUEST_SIGN_IN = 'data-rownd-request-sign-in',
  REQUIRE_SIGN_IN = 'data-rownd-require-sign-in',
  AUTO_SIGN_IN = 'data-rownd-auto-sign-in',
  REQUIRE_VERIFICATION = 'data-rownd-require-verification',
  RETURN_TO = 'data-rownd-return-to',
  DEFAULT_USER_IDENTIFIER = 'data-rownd-default-user-identifier',
  TICKETS_OPEN = 'data-rownd-tickets-open',
}

enum PRIVATE_ATTIRBUTE_SELECTORS {
  FILENAME = 'data-rownd-private-filename',
}

export default function ExternalDomController() {
  const { state } = useGlobalContext();

  const { navTo } = useRoute();

  const fetchingImageForField = useRef<Record<string, boolean>>({});
  const [imageForField, setImageForField] = useState<Record<string, Record<string, any>>>({});

  // Set up listeners for DOM elements we should control
  // useEffect(() => {

  // }, []);

  /* Respond to specific state changes */

  // Authenticated vs. unauthenticated content
  useEffect(() => {
    const authenticatedElements = document.querySelectorAll(`[${VALID_ATTRIBUTE_SELECTORS.AUTHENTICATED_CONTENT}]`);
    const unauthenticatedElements = document.querySelectorAll(`[${VALID_ATTRIBUTE_SELECTORS.UNAUTHENTICATED_CONTENT}]`);

    authenticatedElements.forEach((el) => {
      const htmlEl = el as HTMLElement;
      if (state.auth.access_token) {
        htmlEl.style.display = 'block';
      } else {
        htmlEl.style.display = 'none';
      }
    });

    unauthenticatedElements.forEach((el) => {
      const htmlEl = el as HTMLElement;
      if (!state.auth.access_token) {
        htmlEl.style.display = 'block';
      } else {
        htmlEl.style.display = 'none';
      }
    });

    // Sign-in/out trigger elements
    const signInTriggerElements = document.querySelectorAll(`[${VALID_ATTRIBUTE_SELECTORS.SIGN_IN_BUTTON}]`);
    if (state.auth?.access_token) {
      signInTriggerElements.forEach((el) => {
        const htmlEl = el as HTMLElement;
        if (!htmlEl.dataset.rowndAuthenticatedText || htmlEl.dataset.rowndStatus === 'authenticated') {
          return;
        }

        htmlEl.dataset.rowndUnauthenticatedText = htmlEl.textContent!;
        htmlEl.textContent = htmlEl.dataset.rowndAuthenticatedText;
        htmlEl.dataset.rowndStatus = 'authenticated';
      });
    } else {
      signInTriggerElements.forEach((el) => {
        const htmlEl = el as HTMLElement;
        if (!htmlEl.dataset.rowndUnauthenticatedText) {
          return;
        }

        htmlEl.textContent = htmlEl.dataset.rowndUnauthenticatedText;
        htmlEl.dataset.rowndStatus = undefined;
      });
    }
  }, [state.auth?.access_token]);

  const handleMappingForImage = useCallback(
    async (imageEl: HTMLImageElement, fieldName: string, data: Record<string, any>) => {
      if (fetchingImageForField.current[fieldName]) {
        return;
      }

      // We save the image filename to the element's data attributes each time we fetch it. If the value
      // in the user data is the same as the attribute, we can assume nothing has changed.
      // This could be improved if the API server responded with a unique value in the user data response
      // for image field types. We could then check that value for changes instead of the filename.
      if (imageEl.getAttribute(PRIVATE_ATTIRBUTE_SELECTORS.FILENAME) === data.filename) {
        return;
      }

      fetchingImageForField.current[fieldName] = true;

      let previewSize = imageEl.getAttribute(VALID_ATTRIBUTE_SELECTORS.IMAGE_SIZE) || '';
      if (previewSize && !['small', 'medium', 'large'].includes(previewSize)) {
        previewSize = 'medium';
        // log a warning
        logger.warn(`Invalid image size ${previewSize} on element ${imageEl.outerHTML}`);
      }

      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${state.auth.access_token}`,
          'Content-Type': data?.mime_type,
        },
      };
      const fieldUrl = `${data.url}?${
        previewSize
          ? new URLSearchParams({
              preview: previewSize,
            })
          : ''
      }`;

      try {
        const response = await fetch(fieldUrl, requestOptions);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        imageEl.src = objectUrl;

        // Revoke the old object url if we have one
        if (imageForField[fieldName]?.object_url) {
          URL.revokeObjectURL(imageForField[fieldName].object_url);
        }

        imageEl.setAttribute(PRIVATE_ATTIRBUTE_SELECTORS.FILENAME, data.filename);

        setImageForField({
          ...imageForField,
          [fieldName]: {
            url: data.url,
            object_url: objectUrl,
            exp: Date.now() + 5 * 60 * 1000,
          },
        });
      } finally {
        fetchingImageForField.current[fieldName] = false;
      }
    },
    [imageForField, state.auth.access_token],
  );

  const handleMappingForInput = (inputEl: HTMLInputElement, data: any) => {
    switch (inputEl.type) {
      case 'text':
      case 'email': {
        inputEl.value = data;
        break;
      }
      case 'checkbox': {
        inputEl.checked = !!data;
        break;
      }
    }
  };

  const handleFieldInterpolatedElements = useCallback(
    async (el: Element) => {
      let dompurify: any;
      try {
        dompurify = (await import('dompurify')).default;
      } catch (err) {
        return logger.error(`DOMPurify was not imported: ${err}`);
      }

      const htmlEl = el as HTMLElement;
      if (!htmlEl.dataset.rowndOriginalTemplate) {
        htmlEl.dataset.rowndOriginalTemplate = `${htmlEl.innerHTML}`;
      }
      const template = templater(htmlEl.dataset.rowndOriginalTemplate);

      const sanitizedUserObject = Object.keys(state.user.data).reduce((acc: UserData, key) => {
        acc[key] = dompurify.sanitize(state.user.data?.[key]);
        return acc;
      }, {});

      htmlEl.innerHTML = template(sanitizedUserObject);
    },
    [state.user.data],
  );

  // User data
  useEffect(() => {
    // INTERPOLATIONS
    const fieldInterploatedElements = document.querySelectorAll(`[${VALID_ATTRIBUTE_SELECTORS.FIELD_INTERPOLATION}]`);
    fieldInterploatedElements.forEach((el) => handleFieldInterpolatedElements(el));

    // MAPPINGS
    const fieldMappedElements = document.querySelectorAll(`[${VALID_ATTRIBUTE_SELECTORS.FIELD_MAPPING}]`);

    fieldMappedElements.forEach((el) => {
      const htmlEl = el as HTMLElement;
      const fieldName = htmlEl.getAttribute(VALID_ATTRIBUTE_SELECTORS.FIELD_MAPPING);

      if (fieldName && state.user?.data?.[fieldName]) {
        if (state.user.data[fieldName]?.url && state.app?.schema?.[fieldName]?.type === 'image') {
          handleMappingForImage(htmlEl as HTMLImageElement, fieldName, state.user.data[fieldName]);
        } else if (htmlEl instanceof HTMLInputElement) {
          handleMappingForInput(htmlEl as HTMLInputElement, state.user.data[fieldName]);
        } else {
          htmlEl.textContent = state.user.data[fieldName];
        }
      }
    });
  }, [handleFieldInterpolatedElements, handleMappingForImage, state.app?.schema, state.user.data]);

  useEffect(() => {
    let requestSignInElements: NodeList | Element[] = document.querySelectorAll(
      `[${VALID_ATTRIBUTE_SELECTORS.REQUEST_SIGN_IN}],[${VALID_ATTRIBUTE_SELECTORS.REQUIRE_SIGN_IN}]`,
    );
    if (!requestSignInElements.length) {
      return;
    }

    requestSignInElements = Array.from(requestSignInElements) as Element[];

    const hasAccessToken = state.auth.access_token;
    const requireVerification = requestSignInElements.some((el) => {
      return el.hasAttribute(VALID_ATTRIBUTE_SELECTORS.REQUIRE_VERIFICATION);
    });

    // Return if we have an access token and the user is verified if that is required
    if (hasAccessToken && (!requireVerification || (requireVerification && state.auth.is_verified_user))) {
      return;
    }

    const shouldRequireSignIn = requestSignInElements.some((el: Element) =>
      el.hasAttribute(VALID_ATTRIBUTE_SELECTORS.REQUIRE_SIGN_IN),
    );

    const defaultUserIdentifierEl = requestSignInElements.find((el: Element) =>
      el.hasAttribute(VALID_ATTRIBUTE_SELECTORS.DEFAULT_USER_IDENTIFIER),
    );

    const autoSignInEl = requestSignInElements.find((el: Element) =>
      el.hasAttribute(VALID_ATTRIBUTE_SELECTORS.AUTO_SIGN_IN),
    );

    navTo('/account/login', void 0, {
      use_modal: true,
      prevent_closing: shouldRequireSignIn,
      identifier: defaultUserIdentifierEl
        ? defaultUserIdentifierEl.getAttribute(VALID_ATTRIBUTE_SELECTORS.DEFAULT_USER_IDENTIFIER)
        : null,
      auto_sign_in: autoSignInEl ? autoSignInEl.getAttribute(VALID_ATTRIBUTE_SELECTORS.AUTO_SIGN_IN) === 'true' : null,
    });
  }, [navTo, state.auth.access_token, state.auth.is_verified_user]);

  return null;
}
