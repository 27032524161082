import preact, { h } from 'preact';
import classNames from 'classnames';

interface PickerProps {
  customClass?: string;
  onChange: () => void;
  isChecked: boolean;
  label?: string | object;
  disabled?: boolean;
}

export default function Picker({ customClass, onChange, isChecked, label, disabled }: PickerProps) {
  const attributes: { disabled?: true } = {};
  if (disabled) {
    attributes.disabled = true;
  }

  const onKeyDown = (e: h.JSX.TargetedKeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onChange()
    }
  }

  return (
    <div
      className={classNames('rph-picker', {
        'rph-picker__disabled': disabled,
        [`${customClass}`]: customClass
      })}
      tabIndex={0}
      onKeyDown={onKeyDown}
      onClick={() => (disabled ? undefined : onChange())}
    >
      <input
        onChange={onChange}
        className="rph-picker__picker"
        {...attributes}
        checked={isChecked}
        type="radio"
      />
      <label
        className={'rph-picker__label'}
      >
        {label}
      </label>
    </div>
  );
}
