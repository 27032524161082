import _set from 'lodash-es/set';
import postRobot from 'post-robot';
import { localStore } from './local';
import { logger } from '../utils/log';
import { GlobalState } from '../hooks/use-global-context';

export type StorageSyncEventData = Record<string, string | null>;

export function rootStore() {
  const store = localStore();

  function collectStorageItems() {
    const syncData: StorageSyncEventData = {};

    Object.keys(window.localStorage)
      .filter((k) => k.startsWith('rph_'))
      .forEach((k) => {
        let storeValue = store.getItem(k);
        if (k === 'rph_state' && storeValue) {
          const rphStateObj = JSON.parse(storeValue as string);
          _set(rphStateObj, 'app.config', null);
          storeValue = JSON.stringify(rphStateObj);
        }
        syncData[k] = storeValue;
      });

    return syncData;
  }

  postRobot.on('storage.getItem', (event) => {
    logger.debug('rootStore.getItem', event);
    return store.getItem(event.data.key);
  });

  postRobot.on('storage.setItem', (event) => {
    let newValue = event.data.value;
    const eventTs = event.data.ts || Date.now();
    const prevTs = store.getItem(`${event.data.key}_ts`);

    if (prevTs && eventTs < prevTs) {
      return;
    }

    if (event.data.key === 'state') {
      const originalStateString = store.getItem('state');
      const newStateString = newValue;
      if (!newStateString) return;

      const originalState: GlobalState = JSON.parse(originalStateString);
      const newState: GlobalState = JSON.parse(newStateString);

      // Overwrite newState appConfig with original, because we could be dealing with
      // multiple app variants (i.e., sub-brands)
      _set(newState, 'app.config', originalState?.app?.config || newState?.app?.config || {});
      newValue = JSON.stringify(newState);
    }

    logger.debug('rootStore.setItem', { event, newValue });

    store.setItem(`${event.data.key}_ts`, eventTs);
    store.setItem(event.data.key, newValue);

    // Sync back to client
    postRobot.send(event.source, 'storage.sync', collectStorageItems()).catch(logger.error);
  });

  postRobot.on('storage.removeItem', (event) => {
    logger.debug('rootStore.removeItem', event);
    store.removeItem(event.data.key);
    postRobot.send(event.source, 'storage.sync', collectStorageItems()).catch(logger.error);
  });

  postRobot.on('storage.init', (event) => {
    logger.debug('rootStore.init', event);
    postRobot.send(event.source, 'storage.sync', collectStorageItems()).catch(logger.error);
    postRobot.send(event.source, 'storage.init').catch(logger.error);
  });
}
