import ContainerInner from '@/scripts/ContainerInner';
import { FunctionComponent } from 'preact';
import { Trans, useTranslation } from 'preact-i18next';
import Button from '../Common/Button/Button';
import { RowndHomePage } from '@/scripts/utils/links';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { useRoute } from '@/scripts/hooks';
import CloseControl from '@/scripts/CloseControl';

const PoweredByRownd: FunctionComponent = () => {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const { setPopupRoute } = useRoute();
  const legal = state.app.config?.hub?.legal;
  const privacyPolicy = legal?.privacy_policy_url;
  const termsConditions = legal?.terms_conditions_url;

  return (
    <ContainerInner>
      {() => (
        <>
          <CloseControl onClose={() => setPopupRoute(undefined)} />
          <div className="rph-modal rph-powered-by">
            <div className="rph-powered-by__title">{t('Powered by Rownd')}</div>
            <p className="rph-powered-by__subtitle">
              <Trans
                i18nKey="This app uses Rownd for sign-in, security, and data privacy. This means:"
                components={{
                  a: (
                    <a href={RowndHomePage} target="_blank" rel="noreferrer">
                      Rownd
                    </a>
                  ),
                }}
              />
            </p>
            <ul>
              <li>{t('You can sign in without having to use your password')}</li>
              <li>{t('Your data is safer and more secure')}</li>
            </ul>
            {(!!privacyPolicy || !!termsConditions) && (
              <p className="rph-powered-by__footer">
                <Trans
                  i18nKey="For more information, check out our privacy policy or terms and conditions."
                  components={{
                    privacyPolicy: (
                      <a target="_blank" rel="noreferrer" href={privacyPolicy || undefined}>
                        privacy policy
                      </a>
                    ),
                    termsConditions: (
                      <a target="_blank" rel="noreferrer" href={termsConditions || undefined}>
                        terms and conditions
                      </a>
                    ),
                  }}
                />
              </p>
            )}
            <Button
              customClass="rph-powered-by__close-button"
              label={t('Back')}
              handleOnClick={() => setPopupRoute(undefined)}
              type="tertiary"
            />
          </div>
        </>
      )}
    </ContainerInner>
  );
};

export default PoweredByRownd;
