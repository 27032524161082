import { useCallback, useEffect, useState } from 'preact/hooks';
import { useGlobalContext } from './hooks/use-global-context';

export default function CustomScripts() {
  const { state } = useGlobalContext();

  const [hasLoaded, setHasLoaded] = useState(false);

  const loadCustomScripts = useCallback(async () => {
    const customScripts = state.app.config?.hub?.custom_scripts || [];
    for (const [idx, customScript] of customScripts.entries()) {
      const script = document.createElement('script');
      script.id = `rph-custom-script-${idx}`;
      script.type = customScript.type || 'application/javascript';
      script.textContent = customScript.content;

      // Add the script to the document
      document.head.appendChild(script);
    }
    setHasLoaded(true);
  }, [state.app.config?.hub?.custom_scripts]);

  useEffect(() => {
    if (!state.app.config || hasLoaded) {
      return;
    }
    loadCustomScripts();
  }, [hasLoaded, loadCustomScripts, state.app.config]);

  return null;
}
