import { useEffect, useCallback } from 'preact/hooks';
import useApi from './use-api';
import { logger } from '../utils/log';
import { storage } from '../storage';
import { sha256 } from '../utils/crypto';
import { useGlobalContext } from './use-global-context';

interface IFingerprint {
  message: string;
  hash: string;
  challenge: string;
}

interface IChallenge {
  key: string;
  value: string;
}

let fpAgent: {
  get: (options: any) => Promise<any>;
};

let isFingerprintingInProgress = false;

let hashComponents: (components: any) => string;

export async function init() {
  const { load, hashComponents: hashComponentsImp } = await import('@fingerprintjs/fingerprintjs');
  hashComponents = hashComponentsImp;
  fpAgent = await load();
}

export default function () {
  const { state } = useGlobalContext();
  const { client: api } = useApi();

  async function computePossibleChallengeLookupValues(appId: string, rawLookupValues: string[]): Promise<string[]> {
    const lookupHashes = [];
    for (const value of rawLookupValues) {
      lookupHashes.push(await sha256(`${appId}:${value}`));
    }
    return lookupHashes;
  }

  async function removeChallenge(challengeId: string | undefined) {
    if (!challengeId) {
      return;
    }

    const challengesString = storage.getItem('challenges');
    if (!challengesString) {
      return;
    }

    const existingChallenges: Record<string, string[]> = JSON.parse(challengesString);
    const challenges: Record<string, string[]> = {};
    Object.entries(existingChallenges).forEach(([key, value]) => {
      if (key !== challengeId) {
        challenges[key] = value;
      }
    });

    await storage.setItem('challenges', JSON.stringify(challenges));
  }

  const getChallengeIfPresent = useCallback(
    async (appId: string | undefined, challengeLookupValues: string[]): Promise<IChallenge | null> => {
      if (!appId || challengeLookupValues.length == 0) {
        return null;
      }

      const challengesString = storage.getItem('challenges');
      if (!challengesString) {
        return null;
      }

      const challenges: Record<string, string[]> = JSON.parse(challengesString);

      if (!challenges) {
        return null;
      }

      let challengeKey = '';
      let challengeValue = '';
      for (const value of challengeLookupValues) {
        const hash = await sha256(`${appId}:${value}`);
        const challenge = Object.entries(challenges).find(([, hashes]) => hashes.includes(hash));

        if (challenge) {
          challengeKey = hash;
          challengeValue = challenge[0];
          break;
        }
      }

      if (!challengeKey || !challengeValue) {
        return null;
      }

      return {
        key: challengeKey,
        value: challengeValue,
      };
    },
    [],
  );

  const getFingerprint = useCallback(async (options?: any) => {
    if (!fpAgent) {
      await init();
    }

    const result = await fpAgent.get(options);

    // Exclude undesired components
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { screenResolution, screenFrame, colorDepth, colorGamut, hdr, canvas, ...components } = result.components;

    const visitorId = hashComponents(components);

    return {
      ...result,
      components,
      visitorId,
    };
  }, []);

  const registerFingerprint = useCallback(async () => {
    //Don't request fingerprint registration until user lookup values are loaded, otherwise the challenge won't be saved bc the challengeLookupHashes is empty
    if (
      !state.auth.access_token ||
      !state.auth.is_verified_user ||
      !state.app.id ||
      isFingerprintingInProgress ||
      (!state.user?.data?.email && !state.user?.data?.phone_number)
    ) {
      return;
    }

    isFingerprintingInProgress = true;

    try {
      // Check for existing challenge
      const challengeEntry = await getChallengeIfPresent(
        state.app.id,
        [state.user?.data?.email, state.user?.data?.phone_number].filter(Boolean),
      );

      const fingerprint = await getFingerprint();
      const payload: IFingerprint = await api
        .post('hub/auth/fingerprints', {
          authenticated: true,
          json: {
            hash: fingerprint.visitorId,
            challenge: challengeEntry?.value || null, // Might exist from a previous run, in which case this request will be a no-op
          },
        })
        .json();

      if (payload.challenge === challengeEntry?.key) {
        // This is a no-op
        logger.debug('Fingerprint already registered');
        return;
      }

      // Save the challenge for future sign-ins if we don't have one already or we got a new one from the server
      const challengeLookupHashes = await computePossibleChallengeLookupValues(
        state.app.id,
        [state.user?.data?.email, state.user?.data?.phone_number].filter(Boolean),
      );
      if (payload.challenge && challengeLookupHashes.length > 0) {
        const challengesString = storage.getItem('challenges');

        const challenges = challengesString ? JSON.parse(challengesString) : [];
        await storage.setItem(
          'challenges',
          JSON.stringify({
            ...challenges,
            [payload.challenge]: challengeLookupHashes,
          }),
        );
        logger.debug('Saving the challenge', payload.challenge);
      } else {
        //This shouldn't happen anymore since we're checking for user lookup values before registering
        logger.debug('Not doing anything with this challenge', payload.challenge);
      }
    } catch (err) {
      logger.warn('Failed to register fingerprint', err);
    } finally {
      isFingerprintingInProgress = false;
    }
  }, [
    state.auth.access_token,
    state.auth.is_verified_user,
    state.app.id,
    state.user?.data?.email,
    state.user?.data?.phone_number,
    getChallengeIfPresent,
    getFingerprint,
    api,
  ]);

  useEffect(() => {
    if (!state.auth.access_token) {
      return;
    }

    (async () => {
      // If already fingerprinted, don't try again
      const existingChallenge = await getChallengeIfPresent(
        state.app.id,
        [state.user?.data?.email, state.user?.data?.phone_number].filter(Boolean),
      );

      // Don't need to re-register a fingerprint if we already have one registered
      if (existingChallenge) {
        logger.debug('Found existing challenge, so not requesting fingerprint registration.');
        return;
      }

      // We have an access token, so we can use it to get the fingerprint
      registerFingerprint();
    })();
  }, [
    getChallengeIfPresent,
    registerFingerprint,
    state.app.id,
    state.auth.access_token,
    state.user?.data?.email,
    state.user?.data?.phone_number,
  ]);

  return {
    getFingerprint,
    getChallengeIfPresent,
    removeChallenge,
  };
}
