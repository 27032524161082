import { h } from 'preact';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import RequestTermsConditions from './RequestTermsConditions/RequestTermsConditions';

export enum RequestFormTypes {
  TermsAndConditions = 'TermsAndConditions',
}

export default function RequestForm() {
  const { state } = useGlobalContext();
  const { nav } = state;
  const { options } = nav;
  let requestFormType: RequestFormTypes = RequestFormTypes.TermsAndConditions;
  if (Object.values(RequestFormTypes).includes(options?.type)) {
    requestFormType = options?.type;
  }

  const RequestFormComponent = () => ({
    [RequestFormTypes.TermsAndConditions]: <RequestTermsConditions />,
  });

  return RequestFormComponent()[requestFormType];
}
