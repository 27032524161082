import { h } from 'preact';
import { useCallback, useEffect } from 'preact/hooks';
import classnames from 'classnames';
import { useTranslation } from 'preact-i18next';
import { useGlobalContext, ActionType } from './hooks/use-global-context';
import Button from './Components/Common/Button/Button';
import { useRoute } from './hooks';
import { RowndHomePage } from './utils/links';

type WelcomeProps = {
  setIsVisible: (isVisible: boolean) => void;
};

export default function Welcome({ setIsVisible }: WelcomeProps) {
  const { state, dispatch } = useGlobalContext();
  const { navTo } = useRoute();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({
      type: ActionType.SET_SECTION,
      payload: {
        section: 'rownd',
      },
    });
  }, [dispatch, state.nav.current_route]);

  function handleClose() {
    setIsVisible(false);
  }

  const handleAccount = useCallback(() => {
    if (!state.auth.access_token) {
      navTo('/account/login', void 0, {
        use_modal: true,
      });
      return;
    }
    navTo('/account/manage', void 0, {
      use_modal: true,
    });
  }, [navTo, state.auth.access_token]);

  return (
    <div className={classnames('rph-welcome', 'rph-container-inner')}>
      <span className="rph-text">
        <Button type="text" label={t('Your account')} handleOnClick={handleAccount} />
        &nbsp;{t('is Powered by')}&nbsp;
        <a target="_blank" href={RowndHomePage} rel="noreferrer">
          {t('Rownd')}.
        </a>
      </span>
      <span className="rph-close" onClick={() => handleClose()} />
    </div>
  );
}
