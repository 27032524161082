import { useCallback } from 'preact/hooks';
import * as AutomationTypes from '../types';
import { automationClassName, automationLogger, convertStyleObjectToCss, getDisplayedBackgroundColor } from '../utils';
import useActors from '../actors';
import { useGlobalContext } from '../../hooks/use-global-context';
import { RowndButton, RowndDiv } from '../../utils/custom-elements';

const listeners: { className: string; handler: () => void }[] = [];

function useContentGates() {
  const { state } = useGlobalContext();
  const actors = useActors();

  // Ensure the parent element can handle child elements with absolute positioning
  const handleParentElement = useCallback((element: HTMLElement) => {
    const cssStyle = window.getComputedStyle(element);
    const position = cssStyle.position || element.style.position || 'static';
    if (position === 'static') {
      element.style.position = 'relative';
    }
  }, []);

  const handleChildElement = useCallback((parentElement: HTMLElement, element: HTMLElement, style: AutomationTypes.EditContentStyle) => {
      // Set background color to the displayed background color behind the element
      const backgroundColor = style['background-color'];
      if (backgroundColor && backgroundColor === 'displayed') {
        const color = getDisplayedBackgroundColor(parentElement);
        if (color) {
          element.style.backgroundColor = color;
        }
      }
    },
    [],
  );

  const editElementsAutomation = useCallback(
    (id: string, selector: string, actions?: AutomationTypes.Action[]) => {
      actions?.forEach((action, actionIdx) => {
        if (action.type !== AutomationTypes.ActionType.EDIT_ELEMENTS) {
          return;
        }

        const { args } = action as AutomationTypes.EditContentAction;
        const children = args?.children || [];
        const hasChildren = children.length > 0;

        if (selector !== args?.selector) {
          return;
        }

        const htmlElements = document.querySelectorAll<HTMLElement>(selector);

        htmlElements.forEach((htmlElement) => {
          if (hasChildren) {
            handleParentElement(htmlElement);
          }

          const className = automationClassName({ id, actionIdx });
          // Prevent automation from running more than once
          if (htmlElement.classList.contains(className)) {
            return;
          }

          htmlElement.classList.add(className);

          // Append children to element
          children?.forEach((child, childIdx) => {
            const className = automationClassName({ id, actionIdx, childIdx });
            const newElement = document.createElement(child.element);
            handleChildElement(htmlElement, newElement, child.style || {});
            const innerHTML = child.innerHTML;
            const action = child.action;
            if (innerHTML) {
              newElement.innerHTML = innerHTML;
            }
            newElement.classList.add(className);
            if (action) {
              const handler = () => actors?.[action?.type]();
              newElement.addEventListener('click', handler);
              listeners.push({ className, handler });
            }
            htmlElement.appendChild(newElement);
          });
        });
      });
    },
    [actors, handleChildElement, handleParentElement],
  );

  const removeAutomationEdits = useCallback((automation: AutomationTypes.Automation, selector: string) => {
    const id = automation.id;
    const actions = automation.actions?.filter((action) => action.type === AutomationTypes.ActionType.EDIT_ELEMENTS) as
      | undefined
      | AutomationTypes.EditContentAction[];

    actions?.forEach((action, actionIdx) => {
      const { args } = action;
      const children = args?.children || [];

      if (selector !== args?.selector) {
        return;
      }

      const htmlElements = document.querySelectorAll<HTMLElement>(selector);

      htmlElements.forEach((htmlElement) => {
        htmlElement.classList.remove(automationClassName({ id, actionIdx }));
        children?.forEach((_, childIdx) => {
          const className = automationClassName({ id, actionIdx, childIdx });
          const childElement = document.querySelector(`.${className}`);
          if (!childElement) {
            return;
          }
          const listener = listeners.find((listener) => listener.className === className);
          if (listener) {
            childElement.removeEventListener('click', listener.handler);
          }
          childElement.remove();
        });
      });
    });
  }, []);

  // Hide content in the DOM.
  const enableTriggerContent = useCallback(
    (trigger: AutomationTypes.Trigger, automation: AutomationTypes.Automation) => {
      const id = automation.id;
      switch (trigger.type.toUpperCase()) {
        case AutomationTypes.TriggerType.URL:
          // no-op
          break;
        case AutomationTypes.TriggerType.HTML_SELECTOR:
        case AutomationTypes.TriggerType.HTML_SELECTOR_VISIBLE: {
          const selector = trigger.value;
          automationLogger.debug(`Edit html elements (value: ${selector})`);
          const htmlElements = document.querySelectorAll<HTMLElement>(selector);
          if (htmlElements.length === 0) break;
          editElementsAutomation(id, selector, automation.actions);
          break;
        }
        case AutomationTypes.TriggerType.EVENT:
          // no-op
          break;
        default:
        // no-op
      }
    },
    [editElementsAutomation]
  );

  // Reveal content in the DOM.
  // FIXME: This is a naive approach. An element could be hidden in a number of ways and this
  // does not address all of them.
  const disableTriggerContent = useCallback(
    (trigger: AutomationTypes.Trigger, automation: AutomationTypes.Automation) => {
      switch (trigger.type.toUpperCase()) {
        case AutomationTypes.TriggerType.URL:
          // no-op
          break;
        case AutomationTypes.TriggerType.HTML_SELECTOR:
        case AutomationTypes.TriggerType.HTML_SELECTOR_VISIBLE: {
          const selector = trigger.value;
          const htmlElements = document.querySelectorAll<HTMLElement>(selector);
          if (htmlElements.length === 0) break;
          removeAutomationEdits(automation, selector);
          break;
        }
        case AutomationTypes.TriggerType.EVENT:
          // no-op
          break;
        default:
        // no-op
      }
    },
    [removeAutomationEdits]
  );

  const loadAutomationCSS = useCallback(() => {
    const automations = state.app.config?.automations;
    automations?.forEach((automation) => {
      if (automation.platform !== 'web') {
        return;
      }

      const actions: AutomationTypes.EditContentAction[] = [];

      automations.forEach((automation) => {
        automation.actions?.forEach((action) => {
          if (action.type === AutomationTypes.ActionType.EDIT_ELEMENTS) {
            actions.push(action as AutomationTypes.EditContentAction);
          }
        });
      });

      actions.forEach((action, actionIdx) => {
        const { args } = action;
        const children = args?.children || [];
        const style = args?.style;

        const containerCss = convertStyleObjectToCss(automationClassName({ id: automation.id, actionIdx }), style);

        const childrenCss =
          children?.map((child, childIdx) =>
            convertStyleObjectToCss(automationClassName({ id: automation.id, actionIdx, childIdx }), child.style),
          ) || [];

        const cssString = `${containerCss}\n\n${childrenCss.join('\n\n')}`;

        const styleElement = document.createElement('style');
        styleElement.textContent = cssString;
        document.head.appendChild(styleElement);
      });
    });

    if (!customElements.get('rownd-div')) {
      customElements.define('rownd-div', RowndDiv);
    }
    if (!customElements.get('rownd-button')) {
      customElements.define('rownd-button', RowndButton);
    }
  }, [state.app.config?.automations]);

  return {
    enableTriggerContent,
    disableTriggerContent,
    loadAutomationCSS,
  };
}

export default useContentGates;
