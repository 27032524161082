import { h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';

import { useGlobalContext, ActionType } from '../hooks/use-global-context';
import useUserApi from '../hooks/use-user-api';

export type QuestionField = {
  name: string;
  type: 'text' | 'number' | 'email' | 'radio' | 'checkbox' | 'select' | 'tel' | 'phone' | 'url' | 'date' | 'color';
  placeholder?: string;
  options?: [
    {
      label: string | number;
      value: string | number;
    },
  ];
};

type QuestionTrigigerAuto = {
  type: 'auto';
};

type QuestionTriggerManual = {
  type: 'manual';
};

type QuestionTriggerEvent = {
  type: 'event';
  event: 'click' | 'visible';
  selector: string;
};

export type TQuestion = {
  title: string;
  name: string;
  fields: string[] | QuestionField[];
  trigger: QuestionTrigigerAuto | QuestionTriggerManual | QuestionTriggerEvent;
};

type QuestionProps = {
  question: TQuestion;
  closeCollector: () => void;
};

export default function Question({ question, closeCollector }: QuestionProps) {
  const { state, dispatch } = useGlobalContext();
  const { saveUserField } = useUserApi();
  const { current_route } = state.nav;

  useEffect(() => {
    dispatch({
      type: ActionType.SET_SECTION,
      payload: {
        section: 'rownd',
      },
    });
  }, [current_route, dispatch]);

  function setFieldValue(field: string, value: any) {
    saveUserField(field, value);
  }

  function renderField(field: QuestionField | string) {
    // Handle plain string field names
    let fieldSpec: QuestionField;
    if (typeof field === 'string') {
      fieldSpec = {
        name: field,
        type: 'text',
      };
    } else {
      fieldSpec = field;
    }

    if (fieldSpec.type === 'phone') {
      fieldSpec.type = 'tel';
    }

    switch (fieldSpec.type) {
      case 'text':
      case 'number':
      case 'email':
      case 'tel':
      case 'url':
      case 'date':
      case 'color':
        return (
          <input
            type={fieldSpec.type}
            name={fieldSpec.name}
            placeholder={fieldSpec.placeholder}
            onChange={(evt): void => setFieldValue(fieldSpec.name, (evt.target as HTMLInputElement).value)}
          />
        );
      case 'radio':
        return (
          <>
            {fieldSpec.options?.map((option) => {
              return (
                <label className="rph-radio-label" key={option.value}>
                  <input
                    type="radio"
                    name={fieldSpec.name}
                    value={option.value}
                    onChange={(evt): void => setFieldValue(fieldSpec.name, (evt.target as HTMLInputElement).value)}
                  />
                  {option.label}
                </label>
              );
            })}
          </>
        );
      case 'checkbox':
        return (
          <>
            {fieldSpec.options?.map((option) => {
              return (
                <label className="rph-checkbox-label" key={option.value}>
                  <input
                    type="checkbox"
                    name={fieldSpec.name}
                    value={option.value}
                    onChange={(evt): void => setFieldValue(fieldSpec.name, (evt.target as HTMLInputElement).value)}
                  />
                  {option.label}
                </label>
              );
            })}
          </>
        );
      case 'select':
        return (
          <select
            name={fieldSpec.name}
            onChange={(evt): void => setFieldValue(fieldSpec.name, (evt.target as HTMLInputElement).value)}
          >
            {fieldSpec.options?.map((option) => {
              return (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>
        );
    }
  }

  useEffect(() => {
    const isQuestionAnswered = (question.fields as any[]).every((field: string | QuestionField) => {
      const fieldName = (field as QuestionField).name || (field as string);

      if (state.user.data[fieldName]) {
        return true;
      }

      return false;
    });

    if (isQuestionAnswered) {
      setTimeout(closeCollector, 750);
    }
  }, [closeCollector, question.fields, state.user.data]);

  return (
    <div className="rph-question">
      <h4>Site customization</h4>
      <h2>{question.title}</h2>
      {question.fields.map((field) => {
        return <div key={typeof field === 'string' ? field : field.name}>{renderField(field)}</div>;
      })}
    </div>
  );
}
