import { h } from 'preact';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import NearWalletDetails from './Near/NearWalletDetails/NearWalletDetails';
import { WalletTypes } from '../Profile/ProfileWallets/ProfileWallets';

export default function WalletDetails() {
  const { state } = useGlobalContext();
  const { nav } = state;
  const { options } = nav;
  let walletDetails: WalletTypes.Near | '' = '';
  if (Object.values(WalletTypes).includes(options?.type)) {
    walletDetails = options?.type;
  }

  const WalletDetailsComponent = () => ({
    [WalletTypes.Near]: <NearWalletDetails />,
    '': <h1>Missing correct type for WalletDetails</h1>,
  });

  return WalletDetailsComponent()[walletDetails];
}
