import { h } from 'preact';
import { useGlobalContext } from './hooks/use-global-context';
import useHandleClose from './hooks/use-handle-close';
import { useCallback } from 'preact/hooks';

interface Props {
  onClose?: () => void;
  disable?: boolean;
}

export default function CloseControl({ onClose, disable }: Props) {
  const { state } = useGlobalContext();
  const handleClose = useHandleClose();

  const close = useCallback(() => {
    if (onClose) {
      onClose();
      return;
    }

    handleClose();
  }, [handleClose, onClose]);

  if (!state.is_container_visible || disable) {
    return null;
  }

  return <span className="rph-close" onClick={close} />;
}
