import { ActionType, useGlobalContext } from '@/scripts/hooks/use-global-context';
import Button from '../../Common/Button/Button';
import { sendMessageToApp, MessageType } from '@/scripts/utils/mobile-app';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { Trans, useTranslation } from 'preact-i18next';
import { Status } from '@/scripts/ExternalApi';
import LoadingModal from '../../Common/Modals/LoadingModal/LoadingModal';
import FailureModal from '../../Common/Modals/FailureModal/FailureModal';
import { usePasskeys } from '@/scripts/hooks';
import { WebAuthnErrorCode } from '@simplewebauthn/browser';
import useHandleClose from '@/scripts/hooks/use-handle-close';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import PasskeyIcon from '@/scripts/assets/svg/PasskeyIcon';
import PasskeySuccessIcon from '@/scripts/assets/svg/PasskeySuccessIcon';

export default function ConnectPasskey() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const handleClose = useHandleClose();
  const { state, dispatch } = useGlobalContext();
  const { isBottomSheetEnabled } = useBottomSheet();
  const isMobileApp = state.config?.displayContext === 'mobile_app';
  const [status, setStatus] = useState<Status | undefined>(state.nav.options?.status);
  const [error, setError] = useState<string | undefined>(state.nav.options?.error);
  const visualSwoops =
    typeof state.app?.config?.hub?.customizations?.visual_swoops === 'boolean'
      ? state.app?.config?.hub.customizations?.visual_swoops
      : true;
  const passkeys = usePasskeys();

  useEffect(() => {
    if (isMobileApp) {
      return;
    }

    passkeys.eagerlyRequestRegistrationChallenge();
  }, [isMobileApp, passkeys.eagerlyRequestRegistrationChallenge]);

  useEffect(() => {
    setStatus(state.nav.options?.status);
    setError(state.nav.options?.error);
    setIsLoading(false);
  }, [state.nav.options?.error, state.nav.options?.status]);

  const doRegistration = useCallback(async () => {
    try {
      await passkeys.register();
      setStatus(Status.Success);
    } catch (err) {
      if (!isMobileApp) {
        passkeys.eagerlyRequestRegistrationChallenge();
      }
      setStatus(Status.Failed);

      switch ((err as any).code as WebAuthnErrorCode) {
        case 'ERROR_CEREMONY_ABORTED':
          setError(t('Passkey setup was aborted. Please try again.'));
          break;

        default:
          setError(t('An unexpected error occurred during passkey setup.'));
      }
    }
  }, [isMobileApp, passkeys.register, passkeys.eagerlyRequestRegistrationChallenge, t]);

  return (
    <div className="rph-passkey-prompt rph-modal">
      {(() => {
        if (status === Status.Failed) {
          return (
            <FailureModal
              title={t('Add passkey failed')}
              subtitle={t('A passkey could not be added. Please try again. Error: {{error}}', {
                error: error || 'Unknown',
              })}
              onHandleClick={() => {
                isMobileApp
                  ? sendMessageToApp({
                      type: MessageType.TRIGGER_SIGN_UP_WITH_PASSKEY,
                      payload: {
                        ...(state.nav.options?.group_to_join && { group_to_join: state.nav.options?.group_to_join }),
                      },
                    })
                  : setStatus(undefined);
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                }, 10000);
              }}
              isLoading={isLoading}
              buttonLabel={t('Retry')}
            />
          );
        }

        if (status === Status.Success) {
          return (
            <>
              <div className="rph-passkey-prompt__image">
                <PasskeySuccessIcon />
              </div>
              <div className="rph-passkey-prompt__title">{t('All set! Your passkey is ready to use.')}</div>
              <div className="rph-passkey-prompt__subtitle">
                <Trans
                  t={t}
                  i18nKey="Next time you sign in on this device, you'll have the option to <1>Continue with passkey</1> for faster access using your fingerprint, face, or device PIN."
                >
                  Next time you sign in on this device, you'll have the option to <strong>Continue with passkey</strong>{' '}
                  for faster access using your fingerprint, face, or device PIN.
                </Trans>
              </div>
              <div className="rph-passkey-prompt__footer">
                <Button
                  handleOnClick={() => {
                    isMobileApp ? sendMessageToApp({ type: MessageType.CLOSE_HUB_VIEW_CONTROLLER }) : handleClose();
                  }}
                  label={t('Done')}
                />
              </div>
            </>
          );
        }

        if (status === Status.Loading) {
          return (
            <LoadingModal
              swoops={visualSwoops && !isBottomSheetEnabled && state.config?.displayContext !== 'mobile_app'}
              title="Waiting for passkey..."
            />
          );
        }

        return (
          <>
            <div className="rph-passkey-prompt__image">
              <PasskeyIcon />
            </div>
            <div className="rph-passkey-prompt__title">{t('Simplify your next sign in with passkeys')}</div>
            <div className="rph-passkey-prompt__subtitle">
              {t('Create a passkey to sign in with your fingerprint, face or device PIN.')}
            </div>
            <div className="rph-passkey-prompt__subtitle">
              {t("To get started, select 'Create a passkey', then follow your device prompts.")}
            </div>

            <div className="rph-passkey-prompt__footer">
              <Button
                handleOnClick={() => {
                  isMobileApp ? sendMessageToApp({ type: MessageType.TRIGGER_SIGN_UP_WITH_PASSKEY }) : doRegistration();
                  setIsLoading(true);
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 10000);
                }}
                label={t('Create a passkey')}
                isLoading={isLoading}
              />
            </div>

            <Button
              handleOnClick={() => {
                if (isMobileApp) {
                  sendMessageToApp({ type: MessageType.CLOSE_HUB_VIEW_CONTROLLER });
                } else {
                  handleClose();
                  dispatch({
                    type: ActionType.SET_USER_META,
                    payload: {
                      ...state.user.meta,
                      last_passkey_registration_prompt: new Date().toISOString(),
                    },
                  });
                }
              }}
              customClass="rph-passkey-prompt__footer-button"
              label={t('Maybe later')}
              type="text"
            />
          </>
        );
      })()}
    </div>
  );
}
