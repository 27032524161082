import { h } from 'preact';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import ConnectPasskeyLazy from './ConnectPasskey/ConnectPasskeyLazy';

export enum ConnectAuthenticatorTypes {
  Passkey = 'passkey',
}

export default function ConnectAuthenticator() {
  const { state } = useGlobalContext();
  const { nav } = state;
  const { options } = nav;
  let connectAuthenticator: ConnectAuthenticatorTypes | '' = '';
  if (Object.values(ConnectAuthenticatorTypes).includes(options?.type)) {
    connectAuthenticator = options?.type;
  }

  const ConnectAuthenticatorComponent = () => ({
    [ConnectAuthenticatorTypes.Passkey]: <ConnectPasskeyLazy />,
    '': <h1>Missing correct type for ConnectAuthenticator</h1>,
  });

  return ConnectAuthenticatorComponent()[connectAuthenticator];
}
