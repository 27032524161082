function TicketSVG() {
  return (
    <svg id="ticket-icon" xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 32 32">
      <defs>
        <style>{`.cls-1{fill:none;}`}</style>
      </defs>
      <title>ticket</title>
      <path
        d="M29,14a1,1,0,0,0,1-1V8a2,2,0,0,0-2-2H4A2,2,0,0,0,2,8v5a1,1,0,0,0,1,1,2,2,0,0,1,0,4,1,1,0,0,0-1,1v5a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V19a1,1,0,0,0-1-1,2,2,0,0,1,0-4Zm-1,5.87V24H21V21H19v3H4V19.87a4,4,0,0,0,0-7.74V8H19v3h2V8h7v4.13a4,4,0,0,0,0,7.74Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <rect fill="currentColor" x="19" y="13" width="2" height="6" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        className="cls-1"
        width="32"
        height="32"
        fill="currentColor"
      />
    </svg>
  );
}

export default TicketSVG;
