import { useEffect, useCallback } from 'preact/hooks';
import useApi from './use-api';
import { ActionType, useGlobalContext } from './use-global-context';
import { logger } from '../utils/log';

interface DuplicateAppUserResp {
  total_results: number;
  results: any[];
}

let isMergeCheckInProgress = false;

export default function () {
  const { state, dispatch } = useGlobalContext();
  const { client: api } = useApi();

  const retrieveMergeUserInfo = useCallback(() => {
    if (
      !state.app.config?.user_merge_options?.state ||
      state.app.config?.user_merge_options?.state === 'disabled' ||
      !state.auth.access_token ||
      !state.app.id ||
      isMergeCheckInProgress
    ) {
      return;
    }

    isMergeCheckInProgress = true;

    (async () => {
      try {
        const resp: DuplicateAppUserResp = await api
          .get(`me/applications/${state.app.id}/data/merge`, {
            authenticated: true,
          })
          .json();

        logger.debug('retrieveMergeUserInfo response', resp.total_results);
        if (resp.total_results > 0) {
          dispatch({ type: ActionType.SET_CONTAINER_VISIBLE, payload: { isVisible: true } });

          dispatch({
            type: ActionType.CHANGE_ROUTE,
            payload: {
              route: `/account/merge`,
              opts: { use_modal: true },
            },
          });
        }
      } catch (err) {
        logger.log('retrieveMergeUserInfo error', err);
      } finally {
        isMergeCheckInProgress = false;
      }
    })();
  }, [api, dispatch, state.app.id, state.auth.access_token]);

  useEffect(retrieveMergeUserInfo, [retrieveMergeUserInfo]);

  return {
    retrieveMergeUserInfo,
  };
}
