import { useCallback, useEffect, useState } from 'preact/hooks';
import { useGlobalContext } from './hooks/use-global-context';
import { MessageType, sendMessageToApp } from './utils/mobile-app';

export default function CustomStyles() {
  const { state } = useGlobalContext();

  const [hasLoaded, setHasLoaded] = useState(false);

  const loadCustomStyles = useCallback(async () => {
    const customStyles = state.app.config?.hub?.custom_styles || [];
    for (const [idx, customStyle] of customStyles.entries()) {
      const style = document.createElement('style');
      style.id = `rph-custom-style-${idx}`;
      style.textContent = customStyle.content;

      // Add the style to the document
      document.head.appendChild(style);
    }

    // Append a custom-overrides class to the #rownd-privacy-hub node to enable customer style overrides
    const rph = document.querySelector('#rownd-privacy-hub');
    rph?.classList.add('custom-overrides');
    rph?.classList.add('hub-display');
    sendMessageToApp({ type: MessageType.HUB_LOADED });

    setHasLoaded(true);
  }, [state.app.config?.hub?.custom_styles]);

  useEffect(() => {
    if (!state.app.config || hasLoaded) {
      return;
    }
    loadCustomStyles();
  }, [hasLoaded, loadCustomStyles, state.app.config]);

  return null;
}
