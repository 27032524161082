import { h } from 'preact';

interface CheckmarkFilledProps {
  color?: string;
}

function CheckmarkFilled({ color }: CheckmarkFilledProps) {
  const fillColor: string | null = color ? `${color} !important` : 'currentColor';

  return (
    <svg
      style={`fill: ${fillColor}`}
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2ZM14,21.5908l-5-5L10.5906,15,14,18.4092,21.41,11l1.5957,1.5859Z" />
      <polygon
        style="fill:white"
        id="inner-path"
        class="cls-1"
        points="14 21.591 9 16.591 10.591 15 14 18.409 21.41 11 23.005 12.585 14 21.591"
      />
    </svg>
  );
}

export default CheckmarkFilled;
