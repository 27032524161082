import NoTicketSVG from '@/scripts/assets/svg/NoTicketSVG';
import Button from '@/scripts/Components/Common/Button/Button';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import preact from 'preact';
import { useTranslation } from 'preact-i18next';

interface NoTicketProps {
  handleTryDifferentEmail?: () => void;
}

const NoTicket: preact.FunctionComponent<NoTicketProps> = ({ handleTryDifferentEmail }) => {
  const { state } = useGlobalContext();
  const { t } = useTranslation();
  return (
    <div className="rph-no-ticket">
      <div className="rph-no-ticket__title">
        {t('No tickets found for')}&nbsp;<strong>{state.user.data?.email || t('your email')}</strong>
      </div>

      <div className="rph-no-ticket__message">
        {t(
          "Looks like you haven't purchased any tickets yet! Your tickets will appear here after you have purchased or received them."
        )}
      </div>

      <NoTicketSVG />

      <Button type="tertiary" customClass="rph-no-ticket__button" handleOnClick={handleTryDifferentEmail}>
        {t('Try a different email')}
      </Button>
    </div>
  );
};

export default NoTicket;
