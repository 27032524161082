import { logger } from '../utils/log';
import { useCallback } from 'preact/hooks';
import { useApi } from '.';
import { ActionType, useGlobalContext } from './use-global-context';
import { EventType, events } from '../events';
import { RphInvite } from './use-rph-hash';

function useGroups() {
  const { client: api } = useApi();
  const { dispatch } = useGlobalContext();

  const acceptInvite = useCallback(
    async (params: RphInvite & { access_token?: string }): Promise<any> => {
      try {
        logger.debug('Accepting invitation', params);
        dispatch({
          type: ActionType.SET_IS_ACCEPTING_GROUP_INVITE,
          payload: {
            accepting: false,
          },
        });

        const result = await api
          .post(`me/groups/${params.group_id}/invites/${params.id}/accept`, {
            json: {},
            authenticated: !params.access_token,
            headers: params.access_token ? { 'Authorization': `Bearer ${params.access_token}`} : undefined,
          })
          .json<any>();

        logger.debug('Invitation accepted successfully');
        events.dispatch(EventType.GROUP_INVITE_ACCEPT_COMPLETE, result);

        dispatch({
          type: ActionType.SET_REFRESH_USER_DATA,
          payload: {
            needs_refresh: true,
          },
        });
        return result;
      } catch (err) {
        logger.error('Failed to accept invitation', err);
        events.dispatch(EventType.GROUP_INVITE_ACCEPT_COMPLETE, err);
        throw err;
      } finally {
        dispatch({
          type: ActionType.SET_IS_ACCEPTING_GROUP_INVITE,
          payload: {
            accepting: false,
          },
        });
      }
    },
    [api, dispatch],
  );

  return {
    acceptInvite,
  };
}

export default useGroups;
