export class RowndDiv extends HTMLElement {
  constructor() {
    super();
  }
}

export class RowndButton extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.tabIndex = 0;
    this.addEventListener('keydown', this.handleKeydown);
  }

  disconnectedCallback() {
    this.removeEventListener('keydown', this.handleKeydown);
  }

  handleClick() {
    this.click();
  }

  handleKeydown(event: any) {
    if (event.key === 'Enter') {
      this.handleClick();
    }
  }
}
