import { useCallback, useEffect } from 'preact/hooks';
import classNames from 'classnames';

import { addSubscriber, removeSubscriber, EventType } from './dom-listeners';
import { useGlobalContext, ActionType } from './hooks/use-global-context';
import { useRoute } from './hooks';
import { VALID_ATTRIBUTE_SELECTORS } from './ExternalDomController';
import useSafeLocation from './hooks/use-window-location';

type NavItemProps = {
  navTo: (route: string, trigger: string, opts: any) => void;
  route: string;
  css_classes: classNames.Argument | classNames.ArgumentArray;
  label: string;
  section: string;
  isModal: boolean;
};

function NavItem({ navTo, route, css_classes, label, section, isModal = false }: NavItemProps) {
  const { state } = useGlobalContext();
  const { current_route, section: current_section } = state.nav;

  const navItemClick = useCallback(() => {
    const isContainerVisible = current_route.startsWith(route) ? !state.is_container_visible : true;
    navTo(route, 'nav', {
      use_modal: isModal,
      is_container_visible: isContainerVisible,
    });
  }, [current_route, route, state.is_container_visible, navTo, isModal]);

  return (
    <div
      className={classNames(
        'rph-nav-item',
        {
          'rph-nav-item-active':
            (current_route.startsWith(route) || current_section === section) && state.is_container_visible && !isModal,
        },
        css_classes,
      )}
      onClick={navItemClick}
    >
      <span className="rph-nav-label">{label}</span>
    </div>
  );
}

export default function Nav() {
  const { navTo } = useRoute();
  const { state } = useGlobalContext();
  const { auth } = state;

  return (
    <div className={classNames('rph-nav', { 'rph-container-open': state.is_container_visible && !state.use_modal })}>
      {/* <NavItem 
                navTo={navTo} 
                route="/security" 
                css_classes={{ 'rph-nav-item-security': true }} 
                label="Security status"
                section="security" /> */}

      <NavItem
        navTo={navTo}
        route="/account"
        isModal={state.use_modal}
        css_classes={{ 'rph-nav-item-user': true, 'rph-nav-authenticated': !!auth?.access_token }}
        label="Account / profile"
        section="account"
      />

      <NavItem
        navTo={navTo}
        isModal={state.use_modal}
        route="/welcome"
        css_classes={{ 'rph-nav-item-rownd': true }}
        label="Rownd"
        section="rownd"
      />
    </div>
  );
}
