import { MessageType, sendMessageToApp } from './mobile-app';

const LEVELS = ['error', 'warn', 'info', 'debug'];

export const RPH_LOG_LEVELS = 'rph_log_levels';

type LogLevel = 'error' | 'warn' | 'info' | 'debug';

const DEFAULT_LEVEL = process.env.LOG_LEVEL || 'warn';

function format(name: string, level: LogLevel, ...args: any[]): any[] {
  args.unshift(`[${level}]${name === 'default' ? ' ' : ` (${name}) `}${window.origin}:`);
  return args;
}

function getLogLevels(): Record<string, string> {
  const logLevels = window?.localStorage.getItem(RPH_LOG_LEVELS);

  if (!logLevels) {
    return {};
  }

  try {
    return JSON.parse(logLevels);
  } catch {
    window?.localStorage.removeItem(RPH_LOG_LEVELS);
    return {};
  }
}

function print(name: string, level: LogLevel, ...args: any[]): void {
  const defaultLevel = getLogLevels()[name] || window?.localStorage.getItem('rph_log_level') || DEFAULT_LEVEL;
  if (LEVELS.indexOf(level) <= LEVELS.indexOf(defaultLevel)) {
    const logFn = console[level] || console.log;
    const parts = format(name, level, ...args);
    logFn(...parts);
    try {
      sendMessageToApp({ type: MessageType.LOG_MESSAGE, payload: parts.map((part) => !['string', 'number', 'boolean'].includes(typeof part) ? JSON.stringify(part, (key, value) => {
        if (value instanceof Window) {
          return (value as Window).origin;
        }
        return value;
      }) : part).join(' ') }, 'logging');
    } catch (err) {
      // no-op
      console.debug('Failed to pass log message to mobile app handler', err);
    }
  }
}

export const logger = createLogger('default');

export function createLogger(name: string) {
  return {
    log: (...args: any[]) => {
      print(name, 'info', ...args);
    },
    error: (...args: any[]) => {
      print(name, 'error', ...args);
    },
    info: (...args: any[]) => {
      print(name, 'info', ...args);
    },
    warn: (...args: any[]) => {
      print(name, 'warn', ...args);
    },
    debug: (...args: any[]) => {
      print(name, 'debug', ...args);
    },
  };
}