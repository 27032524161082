import { h, Fragment, JSX } from 'preact';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { useTranslation } from 'preact-i18next';
import SwoopsAnimation from '../../Common/Swoops/SwoopsAnimation';
import { useCallback, useMemo } from 'preact/hooks';
import ContainerInner from '@/scripts/ContainerInner';
import { useNear } from '@/scripts/hooks';
import Button from '../../Common/Button/Button';
import { NearStatus } from '@/scripts/hooks/use-near';
import type { Provider } from '@ethersproject/providers';
import useEsmSuported from '@/scripts/hooks/use-esm-supported';
import * as Selectors from '@/scripts/utils/Selectors';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';

export type ProviderInfo = {
  id: string;
  type: ProviderType;
  name: string;
  ethersProvider?: Provider;
  hideWhenSigningIn?: boolean;
};

export enum ProviderType {
  Ethers = 'ethers',
  Near = 'near',
  Unknown = 'unknown',
}

type ChooseWalletProviderProps = {
  // initWeb3: (evt: Event, providerToUse: Provider) => Promise<void>;
  // setStep: (step: LoginStep) => void;
  onProviderChosen: (provider: ProviderInfo, e?: Event) => void;
  // goBack?: () => void;
  includeModal?: boolean;
  providerScopes?: Omit<ProviderType, ProviderType.Unknown>[];
};

export function ChooseWalletProvider({
  includeModal,
  providerScopes = ['ethers', 'near'],
  onProviderChosen,
}: ChooseWalletProviderProps): JSX.Element {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { status: nearStatus } = useNear();
  const visualSwoops = state?.app?.config?.hub?.customizations?.visual_swoops !== false;
  const supportedBrowser = useEsmSuported();
  const appIcon = Selectors.appIcon(state);

  const installMetaMask = () => {
    window.open('https://metamask.io/download/', '_blank');
  };

  const nearProviders = useMemo<ProviderInfo[]>(() => {
    if (nearStatus !== NearStatus.Fetched) {
      return [];
    }
    return [
      { type: ProviderType.Near, name: 'My NEAR Wallet', id: 'my-near-wallet' },
      { type: ProviderType.Near, name: 'Meteor Wallet', id: 'meteor-wallet' },
      { type: ProviderType.Near, name: 'Here Wallet', id: 'here-wallet', hideWhenSigningIn: true },
    ];
  }, [nearStatus]);

  const walletProviders = useMemo(() => {
    const ethersProviders: Provider[] = window.ethereum?.providers || [window.ethereum];
    const providers = [];
    if (providerScopes?.includes(ProviderType.Ethers)) {
      providers.push(...ethersProviders);
    }
    if (providerScopes?.includes(ProviderType.Near)) {
      providers.push(...nearProviders);
    }
    return providers;
  }, [providerScopes, nearProviders]);

  const getProviderInfo = useCallback((provider: any): ProviderInfo => {
    // Reference: https://ethereum.stackexchange.com/questions/24266/elegant-way-to-detect-current-provider-int-web3-js
    if (provider.isMetaMask) {
      return { name: 'MetaMask', id: 'metamask', type: ProviderType.Ethers, ethersProvider: provider };
    } else if (provider.isCoinbaseWallet) {
      return { name: 'Coinbase', id: 'coinbase', type: ProviderType.Ethers, ethersProvider: provider };
    } else if (provider.isToshi) {
      ProviderType;
      return { name: 'Toshi', id: 'toshi', type: ProviderType.Ethers, ethersProvider: provider };
    } else if (provider.isStatus) {
      return { name: 'Status', id: 'status', type: ProviderType.Ethers, ethersProvider: provider };
    } else if (provider.isAlphaWallet) {
      return { name: 'AlphaWallet', id: 'alphawallet', type: ProviderType.Ethers, ethersProvider: provider };
    } else if (provider.isGoWallet) {
      return { name: 'GoWallet', id: 'gowallet', type: ProviderType.Ethers, ethersProvider: provider };
    } else if (provider.isTrust) {
      return { name: 'Trust', id: 'trust', type: ProviderType.Ethers, ethersProvider: provider };
    } else if (provider.name) {
      return provider;
    }
    return { name: 'Unknown Provider', id: 'unknown', type: ProviderType.Unknown };
  }, []);

  const getWalletProviderButton = useCallback(
    (provider: any, idx: number) => {
      const providerInfo = getProviderInfo(provider);
      const classSuffix = providerInfo.name.split(' ').join('-').toLowerCase();
      return (
        <Button
          key={idx}
          customClass={`rph-wallet-provider rph-wallet-provider__${classSuffix}`}
          label={providerInfo.name}
          handleOnClick={async (e) => {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            onProviderChosen(providerInfo, e);
          }}
        />
      );
    },
    [getProviderInfo, onProviderChosen],
  );

  const Content = useMemo((): h.JSX.Element => {
    return (
      <>
        <div className="rph-choose-wallet-provider">
          <>
            {state.app?.config?.hub?.auth?.show_app_icon && state.app?.icon && (
              <div className="rph-login__app-icon">
                <img src={appIcon} alt="Site logo" />
              </div>
            )}
            <h2>{t('Choose your wallet')}</h2>
            <p>{t('Securely connect with one of your detected wallet providers')}</p>
            {supportedBrowser ? (
              <>
                {walletProviders.map((provider, idx) => {
                  return getWalletProviderButton(provider, idx);
                })}
              </>
            ) : (
              <>
                {!supportedBrowser ? (
                  <>
                    <div className="rph-unsupported-browser">
                      <h3>Unsupported browser</h3>
                      <p>
                        {t(
                          `The browser you're using doesn't support wallet sign-in. Please switch to a modern browser.`,
                        )}
                      </p>
                    </div>
                    <div className="rph-no-wallet-providers">
                      <img src="https://static.rownd.io/images/no-wallets-detected.svg" alt="no wallet providers" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="rph-no-wallet-providers">
                      <img src="https://static.rownd.io/images/no-wallets-detected.svg" alt="no wallet providers" />
                      <p>{t('No wallets detected')}</p>
                    </div>
                    <div className="rph-install-metamask">
                      <button className="rph-button-link" onClick={installMetaMask}>
                        Install MetaMask
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </div>
      </>
    );
  }, [
    getWalletProviderButton,
    state.app?.config?.hub?.auth?.show_app_icon,
    state.app.icon,
    supportedBrowser,
    t,
    walletProviders,
  ]);

  if (includeModal) {
    return (
      <ContainerInner>
        {() => (
          <div className="rph-login">
            {visualSwoops && state.config?.displayContext !== 'mobile_app' && !isBottomSheetEnabled && (
              <SwoopsAnimation />
            )}
            {Content}
          </div>
        )}
      </ContainerInner>
    );
  }

  return Content;
}
