import { useCallback, useMemo } from 'preact/hooks';
import { useGlobalContext, ActionType } from '../hooks/use-global-context';
import * as Types from './types';
import useActors from './actors';
import { automationLogger, computeLastRunId, computeLastRunTimestamp } from './utils';
import { EventType, events } from '../events';
import useSafeLocation from '../hooks/use-window-location';
import parseDuration from 'parse-duration';
import { addMilliseconds, isAfter } from 'date-fns';
import { isTokenExpired } from '../utils/token';
import { useDurationContext } from '../Context/DurationContext/DurationContext';
import { ruleEvaluators } from './evaluators';

const CompletedAutomationMetadata = 'complete';

export type ProcessAutomationsProps = {
  type: 'event' | 'scope';
};

function useAutomations() {
  const { state, dispatch } = useGlobalContext();
  const actors = useActors();
  const safeLocation = useSafeLocation();
  const { appDuration, pageDuration } = useDurationContext();

  /**
   * Automations are considered _required_ if the `prevent_closing` argument is set
   * to `true` in at least one action, or if there is a `REQUIRE_AUTHENTICATION` action
   * with `"one_tap"` set as the `method` argument. One Tap sign-in actions are always
   * considered _required_ even though dismissable by the user. Rownd should always
   * show One Tap and defer to its internal logic for choosing whether or not to display.
   */
  const isAutomationRequired = useCallback(
    (automation: Types.Automation) =>
      automation.actions?.some((action) => {
        return (
          Boolean(action?.args?.prevent_closing) ||
          (action.type == Types.ActionType.REQUIRE_AUTHENTICATION && action.args?.method === 'one_tap')
        );
      }),
    [],
  );

  const hasPostSignInAutomation: boolean = useMemo(() => {
    return !!state.app.config?.automations?.some((automation) => {
      return (
        automation.state === 'enabled' &&
        !!automation.triggers.some((trigger) => trigger.type === Types.TriggerType.POST_SIGN_IN)
      );
    });
  }, [state.app.config?.automations]);

  const determineAllTriggers = useCallback((automation: Types.Automation) => {
    let triggers = automation.triggers;

    const scenarios = automation.scenarios;
    if (scenarios && scenarios.length > 0) {
      triggers = scenarios.flatMap((x) => x.triggers);
    }

    return triggers;
  }, []);

  const setAutomationTime = useCallback(
    (automation: Types.Automation, trigger?: Types.Trigger) => {
      const lastRunId = computeLastRunId(automation, trigger);
      dispatch({
        type: ActionType.SET_USER_META,
        payload: {
          ...state.user.meta,
          [lastRunId]: new Date().toISOString(),
        },
      });
    },
    [dispatch, state.user.meta],
  );

  const nextAutomation = useMemo(() => {
    return state.automations?.queue?.[0];
  }, [state.automations?.queue]);

  const activeAutomation = useMemo(() => {
    return state.app.config?.automations?.find((automation) => automation.id === state.automations?.active_automation);
  }, [state.app.config?.automations, state.automations?.active_automation]);

  // Invoke the automation's actions.
  const invokeAction = useCallback(
    ({
      action,
      args,
      automation,
    }: {
      action: Types.ActionType;
      args?: Record<string, any>;
      automation: Types.Automation;
    }) => {
      const actionFn = actors[action.toUpperCase() as Types.ActionType];
      if (!actionFn) {
        automationLogger.error('Automation action function not found for action type:', action);
        return;
      }

      automationLogger.debug('invoking action from automation', automation.id, 'with args', args || 'none');
      actionFn(args);
      automationLogger.debug('invocation complete:', automation.id, 'with args', args || 'none');

      const lastRunId = computeLastRunId(automation);
      const newDate = new Date().toISOString();
      const newPayload: Record<string, string> = { [lastRunId]: newDate };

      const triggers = determineAllTriggers(automation);
      const onceTimeTrigger = triggers.find((trigger) => trigger.type === Types.TriggerType.TIME_ONCE);
      if (onceTimeTrigger) {
        newPayload[computeLastRunId(automation, onceTimeTrigger)] = CompletedAutomationMetadata;
      }

      dispatch({
        type: ActionType.SET_USER_META,
        payload: {
          ...state.user.meta,
          ...newPayload,
        },
      });
    },
    [actors, determineAllTriggers, dispatch, state.user.meta],
  );

  // Invoke the automation
  const invokeAutomation = useCallback(
    ({ automation, queue }: { automation: Types.Automation; queue?: Types.Automation[] }) => {
      dispatch({
        type: ActionType.SET_AUTOMATION_QUEUE,
        payload: {
          queue: (queue || state.automations?.queue)?.filter((prev) => prev.id != automation.id) || [],
          active_automation: automation.id,
        },
      });

      events.dispatch(EventType.AUTOMATION_TRIGGERED, {
        id: automation.id,
        name: automation.name,
      });
      for (const action of automation.actions ?? []) {
        invokeAction({
          action: action?.type ?? Types.ActionType.NONE,
          args: action?.args,
          automation,
        });
      }
    },
    [dispatch, invokeAction, state.automations?.queue],
  );

  const determineDateOfNextPrompt = useCallback((trigger: Types.Trigger, lastRunTimestamp: Date): Date => {
    const triggerFrequency = parseDuration(trigger.value);
    return triggerFrequency === 0 ? lastRunTimestamp : addMilliseconds(lastRunTimestamp, triggerFrequency || 8000);
  }, []);

  const processTrigger = useCallback(
    ({
      automation,
      trigger,
      props,
    }: {
      automation: Types.Automation;
      trigger: Types.Trigger;
      props: ProcessAutomationsProps;
    }): boolean => {
      const lastRunTimestamp = computeLastRunTimestamp(automation, trigger, state.user.meta);
      switch (trigger.type.toUpperCase()) {
        case Types.TriggerType.URL:
          try {
            const url = new URL(trigger.value, window.origin);
            if (url.search.length > 1) {
              safeLocation.assign(url.href);
            }

            const urlMatch = window.location.pathname === url.pathname;

            if (!isAutomationRequired(automation)) {
              return !lastRunTimestamp && urlMatch; // If automation is not required, only trigger URL once
            }

            return urlMatch;
          } catch (err) {
            automationLogger.error('Invalid trigger URL:', trigger.value);
            return false;
          }
        case Types.TriggerType.HTML_SELECTOR:
        case Types.TriggerType.HTML_SELECTOR_VISIBLE: {
          const elements = Array.from(document.querySelectorAll(trigger.value));
          return elements && elements.length > 0;
        }
        case Types.TriggerType.EVENT: {
          if (props.type !== 'event') return false;
          const target = trigger?.target;
          if (!target) return false;
          return document.querySelectorAll(target).length > 0;
        }
        case Types.TriggerType.TIME: {
          // Disable TIME trigger if TIME_ONCE has not completed yet.
          const onceTimeTrigger = automation.triggers.find((trigger) => trigger.type === Types.TriggerType.TIME_ONCE);
          if (
            onceTimeTrigger &&
            computeLastRunTimestamp(automation, onceTimeTrigger, state.user.meta) !== CompletedAutomationMetadata
          ) {
            return false;
          }

          if (!lastRunTimestamp) {
            setAutomationTime(automation, trigger);
            return false;
          }

          if (typeof lastRunTimestamp === 'string') {
            return false;
          }

          const dateOfNextPrompt = determineDateOfNextPrompt(trigger, lastRunTimestamp);
          return isAfter(new Date(), dateOfNextPrompt);
        }
        case Types.TriggerType.TIME_ONCE: {
          const triggerFrequency = parseDuration(trigger.value);
          if (!lastRunTimestamp) {
            setAutomationTime(automation, trigger);
            const isTriggerImmediate = triggerFrequency === 0;
            return isTriggerImmediate;
          }

          if (lastRunTimestamp === CompletedAutomationMetadata) {
            return false;
          }

          if (typeof lastRunTimestamp === 'string') {
            return false;
          }

          const dateOfNextPrompt = determineDateOfNextPrompt(trigger, lastRunTimestamp);
          return isAfter(new Date(), dateOfNextPrompt);
        }

        case Types.TriggerType.POST_SIGN_IN: {
          if (!isAutomationRequired(automation)) {
            return !lastRunTimestamp; // If automation is not required, only trigger POST_SIGN_IN once
          }
          return true;
        }

        default:
          automationLogger.error('Automation trigger not supported:', trigger.type);
          return false;
      }
    },
    [isAutomationRequired, safeLocation, setAutomationTime, state.user.meta, determineDateOfNextPrompt],
  );

  const processTriggers = useCallback(
    (automation: Types.Automation, triggers: Types.Trigger[], props: ProcessAutomationsProps): boolean => {
      if (triggers.length === 0) {
        return true;
      }
      return triggers.some((trigger) => processTrigger({ trigger, automation, props }));
    },
    [processTrigger],
  );

  // metadata is intended to store any information about the user that is not included in actual
  // user data. It is used during automations to determine things like is_authenticated, or is_verified.
  const automationMeta = useMemo((): Types.UserMetadata => {
    const metadata: Types.UserMetadata = {
      is_authenticated: !!state.auth.access_token && !isTokenExpired(state.auth.access_token),
      is_verified: state.auth.is_verified_user,
      are_passkeys_initialized: state.user.passkeys?.is_initialized || false,
      has_prompted_for_passkey: !!state.user.meta.last_passkey_registration_prompt,
      has_passkeys: state.user.passkeys?.registrations?.length > 0,
      auth_level: state.auth.auth_level,
      app_duration: appDuration,
      page_duration: pageDuration,
      pages: state.pages,
    };

    return metadata;
  }, [
    appDuration,
    pageDuration,
    state.auth.access_token,
    state.auth.auth_level,
    state.auth.is_verified_user,
    state.pages,
    state.user.meta.last_passkey_registration_prompt,
    state.user.passkeys?.is_initialized,
    state.user.passkeys?.registrations?.length,
  ]);

  // Evaluate all of the automation's rules to determine if it should run.
  const processRules = useCallback(
    (rules: Types.Rule[], condition?: Types.AutomationRuleType): boolean => {
      const callback = (rule: Types.Rule): boolean => {
        if (!Types.isRule(rule)) {
          automationLogger.debug('Automation $OR: ', rule);
          const result = processRules(rule.$or, Types.AutomationRuleType.OR);
          automationLogger.debug('Automation $OR Result: ', result);
          return result;
        }

        const evalFn = ruleEvaluators[rule.entity_type?.toUpperCase() as Types.EntityType];
        if (!evalFn) {
          automationLogger.error('Automation rule evaluator not found for entity_type', rule.entity_type);
          return false;
        }
        const [result, msg] = evalFn({
          userData: state.user.data,
          metadata: automationMeta as unknown as Types.UserMetadata,
          scope: {
            pages: state.pages.data,
          },
          rule,
        });

        automationLogger.debug('processRules:', msg);

        return result;
      };

      if (condition === Types.AutomationRuleType.OR) {
        return rules.some(callback);
      }

      return rules.every(callback);
    },
    [automationMeta, state.pages.data, state.user.data],
  );

  return {
    nextAutomation,
    hasPostSignInAutomation,
    invokeAutomation,
    invokeAction,
    setAutomationTime,
    activeAutomation,
    processTrigger,
    processTriggers,
    processRules,
    determineAllTriggers
  };
}

export default useAutomations;
