import { h } from 'preact';
import { MutableRef, useMemo, useState } from 'preact/hooks';
import { SignInMethods, useGlobalContext } from '@/scripts/hooks/use-global-context';
import { useTranslation } from 'preact-i18next';
import classnames from 'classnames';
import { renderField } from '@/scripts/utils/form';
import { LoginInputProps } from './LoginInput.types';
import Button from '../../Common/Button/Button';
import { RequestSignInIntent } from '@/scripts/ExternalApi';
import { isIosDevice } from '@/scripts/utils';
import useSignInMethods from '@/scripts/hooks/use-sign-in-methods';

export default function LoginInput({
  setUserIdentifier,
  userIdentifier,
  isSubmitting,
  loginType,
  fieldError,
  phoneDetails,
  validateInput,
  inputRef,
  requiresAdditionalFields,
  addlFieldValues,
  handleAddlFieldChange,
  isValidUserIdentifier,
  selectedMethod,
  intent,
}: LoginInputProps) {
  const [displayError, setDisplayError] = useState(false);
  const { t } = useTranslation();
  const { allowedIdentifiers } = useSignInMethods();

  const { state } = useGlobalContext();
  const { app } = state;

  const preferredAuthMethod: keyof SignInMethods | undefined = app?.config?.hub?.auth?.preferred_method;
  const order = app?.config?.hub?.auth?.order;
  const orderType = window?.webkit?.messageHandlers?.rowndIosSDK
    ? 'ios'
    : window?.rowndAndroidSDK
    ? 'android'
    : 'default';

  const orderArray = order?.[orderType] || order?.default || undefined;

  const preferredInputMethod: keyof SignInMethods | undefined = useMemo(() => {
    if (selectedMethod) return selectedMethod;
    if (!orderArray) {
      return preferredAuthMethod;
    }

    const orderInputs = orderArray.filter((method) => method.type === 'input');

    return orderInputs?.[0]?.name || 'email';
  }, [orderArray, preferredAuthMethod, selectedMethod]);

  const allowedInputIdentifiers: (keyof SignInMethods)[] = useMemo(() => {
    if (selectedMethod) return [selectedMethod];
    if (!orderArray) {
      return allowedIdentifiers;
    }
    return orderArray.flatMap((method) => {
      return allowedIdentifiers.includes(method.name) &&
        method.type === 'input' &&
        (!method?.hidden || intent === RequestSignInIntent.SignIn)
        ? method.name
        : [];
    });
  }, [allowedIdentifiers, orderArray, selectedMethod, intent]);

  let inputLabel = preferredInputMethod === 'phone' ? t('Phone number or Email') : t('Email or Phone number');
  let inputPlaceholder = inputLabel;
  let inputType: 'email' | 'tel' = loginType === 'phone' ? 'tel' : 'email';
  if (allowedInputIdentifiers.length < 2) {
    inputLabel = allowedInputIdentifiers.includes('email') ? t('email') : t('phone number');
    inputLabel = inputLabel.charAt(0).toUpperCase() + inputLabel.slice(1); // Capitalize first letter

    inputPlaceholder = allowedInputIdentifiers.includes('email') ? 'you@domain.com' : '+1 555 555 5555';
    inputType = allowedInputIdentifiers.includes('email') ? 'email' : 'tel';
  }

  return (
    <div className="rph-additional-sign-in-methods">
      <label for="rph-sign-in-identifier-input">{inputLabel}</label>
      <input
        id="rph-sign-in-identifier-input"
        type={inputType}
        name="user_identifier"
        placeholder={inputPlaceholder}
        autocomplete="webauthn"
        className={classnames({
          'rph-input-invalid': fieldError || (displayError && !isValidUserIdentifier),
          'rph-input-phone': loginType === 'phone',
          [`rph-input-phone-${phoneDetails?.countryCode}`]: loginType === 'phone',
        })}
        value={userIdentifier}
        onInput={(evt): void => setUserIdentifier((evt.target as HTMLInputElement).value)}
        onTouchStart={(e) => {
          if (state.config?.displayContext === 'mobile_app' && isIosDevice()) {
            e.preventDefault();
            const current = (inputRef as MutableRef<HTMLInputElement | null>)?.current;
            current?.focus({ preventScroll: true }); // Prevent scroll on focus for iOS mobile
          }
        }}
        onBlur={(): void => validateInput()}
        ref={inputRef}
        autocapitalize="none"
        spellcheck={false}
        autoCorrect="off" // Deal with Safari's stupidity
      />
      {!fieldError && displayError && !isValidUserIdentifier && <div className="rph-login__required-icon" />}
      {loginType === 'phone' && phoneDetails?.countryIso2 && (
        <img
          src={`https://flagcdn.com/${phoneDetails?.countryIso2?.toLowerCase()}.svg`}
          className="rph-input-phone-country"
        />
      )}
      {requiresAdditionalFields &&
        app?.config?.hub?.auth?.additional_fields?.map((field) => {
          return renderField({
            ...field,
            value: addlFieldValues[field.name] || '',
            [['input', 'text', 'tel', 'email'].includes(field.type) ? 'onInput' : 'onChange']: handleAddlFieldChange,
          });
        })}
      {fieldError && <div className="rph-field-error">{fieldError}</div>}
      <Button
        customClass="rph-login__button-continue"
        label={t('Continue')}
        isSubmit={true}
        disabled={!!fieldError || !isValidUserIdentifier || isSubmitting}
        isLoading={isSubmitting}
      >
        {!isValidUserIdentifier && (
          <div className="rph-login__button__disabled" onClick={() => setDisplayError(true)} />
        )}
      </Button>
    </div>
  );
}
