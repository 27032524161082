import preact from 'preact';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useGlobalContext } from '../../hooks/use-global-context';
import * as AutomationType from '../types';

import useAutomations from '../hooks';
import useContentGates from './hooks';

const CONTENT_GATES_TIMER = 500;

export const ContentGates: preact.FunctionComponent = () => {
  const { state } = useGlobalContext();
  const { loadAutomationCSS, enableTriggerContent, disableTriggerContent } = useContentGates();
  const { processTriggers, processRules } = useAutomations();

  // eslint-disable-next-line no-undef
  const timerId = useRef<NodeJS.Timeout | undefined>(undefined);
  const [hasLoadedCss, setHasLoadedCss] = useState(false);

  const disabled = useMemo(
    () => state.is_initializing || !state.app?.config?.automations || state.config?.displayContext === 'mobile_app',
    [state.app?.config?.automations, state.config?.displayContext, state.is_initializing]
  );

  const contentGateAutomations = useMemo(() => {
    return (state.app.config?.automations || []).filter((automation) => {
      return automation.actions?.some((action) => action.type === AutomationType.ActionType.EDIT_ELEMENTS);
    });
  }, [state.app.config?.automations]);

  const processContentGates = useCallback(
    (automation: AutomationType.Automation) => {
      if (automation.state !== 'enabled' || (automation.platform && automation.platform !== 'web')) {
        return null;
      }

      const ruleResult = processRules(automation.rules);

      const disable = () => {
        automation.triggers.forEach((trigger) => {
          disableTriggerContent(trigger, automation);
        });
      };

      if (!ruleResult) {
        disable();
        return;
      }

      const triggerResult = processTriggers(automation, automation.triggers, {
        type: 'scope',
      });

      if (triggerResult) {
        automation.triggers.forEach((trigger) => {
          enableTriggerContent(trigger, automation);
        });
        return;
      }

      disable();
    },
    [disableTriggerContent, enableTriggerContent, processRules, processTriggers]
  );

  const processAllContentGates = useCallback(() => {
    contentGateAutomations.forEach((automation) => processContentGates(automation));
  }, [contentGateAutomations, processContentGates]);

  useLayoutEffect(() => {
    if (contentGateAutomations.length === 0) {
      return;
    }
    processAllContentGates();
    clearInterval(timerId.current);
    timerId.current = setInterval(() => processAllContentGates(), CONTENT_GATES_TIMER);
    return () => {
      clearInterval(timerId.current);
    };
  }, [contentGateAutomations.length, processAllContentGates]);

  // Load the CSS styles from all automations
  useLayoutEffect(() => {
    if (disabled) return;
    if (hasLoadedCss) {
      return;
    }
    setHasLoadedCss(true);
    loadAutomationCSS();
  }, [disabled, hasLoadedCss, loadAutomationCSS]);

  return null;
};
