import { ExternalApiSpec } from '@/scripts/ExternalApi';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { logger } from '@/scripts/utils/log';
import { h } from 'preact';
import { useState } from 'preact/hooks';
import Button from '@/scripts/Components/Common/Button/Button';
import useHandleClose from '@/scripts/hooks/use-handle-close';

interface RequestTermsConditionsProps {
  primaryButton?: () => void;
  secondaryButton?: () => void;
}

export default function RequestTermsConditions({ primaryButton, secondaryButton }: RequestTermsConditionsProps) {
  const handleClose = useHandleClose();
  const { state } = useGlobalContext();
  const { app } = state;
  const [isLoading, setIsLoading] = useState(false);

  const privacyPolicy = app?.config?.hub?.legal?.privacy_policy_url || '';
  const termsConditions = app?.config?.hub?.legal?.terms_conditions_url || '';

  const onClickPrimaryButton = async () => {
    await updateTermsConditionsSignedDate();
    if (primaryButton) {
      primaryButton();
    } else {
      closeModal();
    }
  };

  const onClickSecondaryButton = () => {
    if (secondaryButton) {
      secondaryButton();
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    handleClose();
  };

  const updateTermsConditionsSignedDate = async () => {
    setIsLoading(true);
    const rownd = (window as any).rownd as ExternalApiSpec;
    try {
      await rownd.user.setValue('terms_conditions_date', new Date());
    } catch (err) {
      logger.log({ err });
    } finally {
      setIsLoading(false);
    }
    return;
  };

  return (
    <div className="rph-request-form-terms-conditions rph-modal">
      <div className="rph-request-form-terms-conditions__title">Terms and Conditions</div>
      <div className="rph-request-form-terms-conditions__subtitle">
        To continue, please agree to our&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={termsConditions}>
          terms and conditions
        </a>{' '}
        and&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={privacyPolicy}>
          privacy policy.
        </a>
      </div>
      <div className="rph-request-form-terms-conditions__image" />
      <div className="rph-request-form-terms-conditions__footer">
        <Button label="Cancel" height={48} type="secondary" handleOnClick={() => onClickSecondaryButton()} />
        <Button label="Agree" height={48} handleOnClick={() => onClickPrimaryButton()} isLoading={isLoading} />
      </div>
    </div>
  );
}
