import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  FileExtension,
  Options
} from 'qr-code-styling';
import { logger } from '../utils/log';

interface GeneratorOptions {
  format?: FileExtension;
  encoding?: 'utf8' | 'base64';
  color_mode: 'light' | 'dark';
}

const lightModeForegroundColor = '#333333';
const darkModeForegroundColor = '#efefef';

const defaultOptions = {
  width: 300,
  height: 300,
  type: 'svg' as DrawType,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel,
  },
  dotsOptions: {
    color: lightModeForegroundColor,
    type: 'extra-rounded' as DotType,
  },
  cornersSquareOptions: {
    color: lightModeForegroundColor,
    type: 'dot' as CornerSquareType,
  },
  cornersDotOptions: {
    color: lightModeForegroundColor,
    type: 'dot' as CornerDotType,
  },
  backgroundOptions: {
    color: 'transparent',
  },
};

const qrCode = new QRCodeStyling(defaultOptions);

function generate(data: string, overrides: Options = {}) {
  logger.debug('generating qr code with options: ', overrides);
  qrCode.update({
    ...overrides,
    data,
  });

  return qrCode;
}

export async function generateQrCode(
  data: string,
  opts: GeneratorOptions = {
    format: 'svg',
    encoding: 'base64',
    color_mode: 'light',
  },
  overrides: Options = {},
): Promise<string | Blob> {
  let computedOverrides = defaultOptions;

  if (opts.color_mode === 'dark') {
    computedOverrides.dotsOptions.color = darkModeForegroundColor;
    computedOverrides.cornersDotOptions.color = darkModeForegroundColor;
    computedOverrides.cornersSquareOptions.color = darkModeForegroundColor;
  }

  (computedOverrides as Options) = {
    ...computedOverrides,
    ...overrides,
  };

  const qrCode = generate(data, computedOverrides);

  const qrCodeRaw = await qrCode.getRawData(opts.format);

  if (!qrCodeRaw) {
    throw new Error('Failed to generate QR code');
  }

  // Safari v14 is apparently missing this API, even though MDN says otherwise
  let rawText = '';
  if (!('text' in qrCodeRaw)) {
    const arrBuffer = await new Response(qrCodeRaw).arrayBuffer();
    const dec = new TextDecoder('utf-8');
    rawText = dec.decode(arrBuffer);
  } else {
    rawText = await qrCodeRaw.text();
  }

  if (opts.encoding === 'base64') {
    rawText = btoa(rawText);
  }

  return rawText;
}

export function generateCodeForMobile(data: string) {
  const qrCode = generate(data);

  const el = document.getElementById('rownd-dynamic-content');

  if (!el) {
    throw new Error(`Content element '#rownd-dynamic-content' not found. Is this a mobile context?`);
  }

  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }

  qrCode.getRawData('svg');

  qrCode.append(el);
}
