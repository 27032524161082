import classNames from 'classnames';
import { useCallback, useLayoutEffect, useMemo, useState } from 'preact/hooks';
import { PROFILE_FORM_CLASS, PROFILE_TITLE_CLASS, ProfileTypes, profileTypesTranslation } from '../Profile';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { useInstantUser } from '@/scripts/hooks/use-instant-user';

const ProfileSideBar = () => {
  const { state } = useGlobalContext();
  const { instantUserAccessToken } = useInstantUser();
  const [activeTab, setActiveTab] = useState(ProfileTypes.Account);
  const profile = useMemo(() => state.app.config?.hub?.profile, [state.app.config?.hub?.profile]);

  useLayoutEffect(() => {
    const elem = document.querySelector(`.${PROFILE_FORM_CLASS}`);
    if (!elem) return;

    const handleScroll = () => {
      const parentTop = document.querySelector(`.${PROFILE_FORM_CLASS}`)?.getBoundingClientRect().top;
      let tab = ProfileTypes.Account;
      Object.values(ProfileTypes).forEach((item) => {
        if (item === ProfileTypes.Personal && profile?.personal_information?.enabled === false) return;
        if (item === ProfileTypes.Preferences && profile?.preferences?.enabled === false) return;
        const childElem = document.querySelector(`.${PROFILE_TITLE_CLASS}${item}`);
        const childTop = childElem?.getBoundingClientRect().top;
        const diff = (childTop || 0) - (parentTop || 0);
        if (diff < 40) {
          tab = item;
        }
      });
      setActiveTab(tab);
    };

    elem.addEventListener('scroll', handleScroll);
    return () => {
      elem.removeEventListener('scroll', handleScroll);
    };
  }, [profile?.personal_information?.enabled, profile?.preferences?.enabled]);

  const handleClick = useCallback((item: ProfileTypes) => {
    const parentElem = document.querySelector(`.${PROFILE_FORM_CLASS}`);

    let top = 0;
    if (item === ProfileTypes.Account) {
      top = 0;
    } else {
      const parentTop = parentElem?.getBoundingClientRect().top || 0;
      const scrollTop = parentElem?.scrollTop || 0;
      const childTop = document.querySelector(`.${PROFILE_TITLE_CLASS}${item}`)?.getBoundingClientRect().top || 0;
      top = childTop - parentTop + scrollTop - 16;
    }

    parentElem?.scrollTo({
      top,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="rph-profile-sidebar">
      {Object.values(ProfileTypes).map((item) => {
        if (item === ProfileTypes.Personal && profile?.personal_information?.enabled === false) return;
        if (item === ProfileTypes.Preferences && profile?.preferences?.enabled === false) return;
        let { title } = profileTypesTranslation[item];

        if (title === profileTypesTranslation.preferences.title && instantUserAccessToken) {
          title = 'Support';
        }

        return (
          <button
            className={classNames(`rph-profile-sidebar__item`, {
              'rph-profile-sidebar__item--selected': item === activeTab,
            })}
            key={`sidebar-item-${title}`}
            onClick={() => handleClick(item)}
          >
            {title}
          </button>
        );
      })}
    </div>
  );
};

export default ProfileSideBar;
