export default function PasskeySuccessIcon() {
  return (
    <svg width="115" height="96" viewBox="0 0 115 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="48" cy="48" r="48" fill="#B4B4B4" id="rph-svg-background" />
      <g clip-path="url(#clip0_15_241)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.9655 45.7C44.9932 45.7 49.0689 41.581 49.0689 36.5C49.0689 31.419 44.9932 27.3 39.9655 27.3C34.9378 27.3 30.862 31.419 30.862 36.5C30.862 41.581 34.9378 45.7 39.9655 45.7ZM39.9655 48C46.2501 48 51.3448 42.8512 51.3448 36.5C51.3448 30.1487 46.2501 25 39.9655 25C33.6809 25 28.5862 30.1487 28.5862 36.5C28.5862 42.8512 33.6809 48 39.9655 48Z"
          fill="#5B13DF"
          id="rph-svg-primary-color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.7587 61.4164C21.7587 55.4886 26.5137 50.6831 32.3793 50.6831H47.5517C53.4174 50.6831 58.1724 55.4886 58.1724 61.4164V70.9998H55.8965V61.4164C55.8965 56.7587 52.1605 52.9831 47.5517 52.9831H32.3793C27.7706 52.9831 24.0345 56.7587 24.0345 61.4164V70.9998H21.7587V61.4164Z"
          fill="#5B13DF"
          id="rph-svg-primary-color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.447 48.9773L68.4138 49.5111V50.6839V57.5833H64.6207V59.5H68.4138V61.4167H64.6207V63.3333H68.4138V66.4613L65.569 68.608L62.7241 66.4613V50.6839V49.5111L61.6909 48.9773C58.9227 47.5473 57.0345 44.6407 57.0345 41.2917C57.0345 36.5282 60.8555 32.6667 65.569 32.6667C70.2825 32.6667 74.1035 36.5282 74.1035 41.2917C74.1035 44.6407 72.2152 47.5473 69.447 48.9773ZM65.569 71L70.3104 67.4222V50.6839C73.6877 48.939 76 45.3882 76 41.2917C76 35.4697 71.3299 30.75 65.569 30.75C59.808 30.75 55.1379 35.4697 55.1379 41.2917C55.1379 45.3882 57.4502 48.939 60.8276 50.6839V67.4222L65.569 71ZM65.569 44.1667C67.1401 44.1667 68.4138 42.8795 68.4138 41.2917C68.4138 39.7038 67.1401 38.4167 65.569 38.4167C63.9979 38.4167 62.7241 39.7038 62.7241 41.2917C62.7241 42.8795 63.9979 44.1667 65.569 44.1667Z"
          fill="#5B13DF"
          id="rph-svg-primary-color"
        />
      </g>
      <path
        d="M99 34C96.2311 34 93.5243 34.8211 91.222 36.3594C88.9197 37.8978 87.1253 40.0843 86.0657 42.6424C85.0061 45.2006 84.7288 48.0155 85.269 50.7313C85.8092 53.447 87.1426 55.9416 89.1005 57.8995C91.0584 59.8574 93.553 61.1908 96.2687 61.731C98.9845 62.2712 101.799 61.9939 104.358 60.9343C106.916 59.8747 109.102 58.0803 110.641 55.778C112.179 53.4757 113 50.7689 113 48C113 44.287 111.525 40.726 108.899 38.1005C106.274 35.475 102.713 34 99 34ZM97 53.5908L92 48.5908L93.5906 47L97 50.4092L104.41 43L106.006 44.5859L97 53.5908Z"
        fill="#5B13DF"
        id="rph-svg-primary-color"
      />
      <defs>
        <clipPath id="clip0_15_241">
          <rect width="55" height="46" fill="white" transform="translate(21 25)" />
        </clipPath>
      </defs>
    </svg>
  );
}
