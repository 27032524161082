import { h } from 'preact';
import { useGlobalContext } from '../../../hooks/use-global-context';
import * as Selectors from '../../../utils/Selectors';

export default function Swoops() {
  const { state } = useGlobalContext();
  const { computed_color_mode } = state;

  const primaryColor = Selectors.primaryColor(state);

  const smartPrimaryColor = computed_color_mode === 'dark' ? '#000' : primaryColor;
  const opacity = computed_color_mode === 'dark' ? '0.1' : '0.05';
  return (
    <div className="rph-swoops">
      <svg className="rph-swoops__1" preserveAspectRatio="none" viewBox="0 0 320 254" version="1.1">
        <title>mobile-swoop-1</title>
        <g
          id="Emails-and-Verify-screens"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity={opacity}
        >
          <g id="sm-320px-4-column-copy-41" transform="translate(0.000000, -438.000000)" fill={smartPrimaryColor}>
            <path
              d="M323,436.906503 L-1,437.906503 L-0.50161874,510.672767 C53.9717795,529.479046 91.9696247,543.556958 113.491917,552.906503 C183.341381,583.24996 252.780337,630.258181 321.808787,693.931169 C324.898859,696.781502 325.29593,611.106614 323,436.906503 Z"
              id="mobile-swoop-1"
              transform="translate(161.734027, 565.453252) scale(1, -1) translate(-161.734027, -565.453252) "
            />
          </g>
        </g>
      </svg>
      <svg className="rph-swoops__2" preserveAspectRatio="none" viewBox="0 0 320 234" version="1.1">
        <title>mobile-swoop-2</title>
        <g
          id="Emails-and-Verify-screens"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity={opacity}
        >
          <g id="sm-320px-4-column-copy-41" transform="translate(0.000000, -458.000000)" fill={smartPrimaryColor}>
            <path
              d="M324,460 L-1,458 L-3,500 C52.3333333,556 98.3333333,596 135,620 C171.612022,643.964232 233.945355,669.297566 322,696 L324,460 Z"
              id="mobile-swoop-2"
              transform="translate(160.500000, 577.000000) scale(-1, -1) translate(-160.500000, -577.000000) "
            />
          </g>
        </g>
      </svg>
      <svg className="rph-swoops__3" preserveAspectRatio="none" viewBox="0 0 320 159" version="1.1">
        <title>mobile-swoop-3</title>
        <g
          id="Emails-and-Verify-screens"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          opacity={opacity}
        >
          <g id="sm-320px-4-column-copy-41" transform="translate(0.000000, -533.000000)" fill={smartPrimaryColor}>
            <path
              d="M-2,693 L-1.02497491,532.253493 C44.3250084,585.417831 89.4553625,616.639835 134.366088,625.919505 C193.24904,638.086183 255.793677,625.779681 322,589 L322,693 L-2,693 Z"
              id="mobile-swoop-3"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
