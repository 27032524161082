import { useGlobalContext, ActionType } from './use-global-context';
import { QuestionField, TQuestion } from '../DataCollector/Question';
import { logger } from '../utils/log';

export default function useQuestion(question: TQuestion) {
  const { state, dispatch } = useGlobalContext();
  const { data: userData } = state.user;
  const { is_container_visible } = state;

  // Don't ask a question if the container is currently visible
  if (is_container_visible) {
    return;
  }

  const isQuestionAnswered = (question.fields as any[]).every((field: string | QuestionField) => {
    const fieldName = (field as QuestionField).name || (field as string);

    if (userData[fieldName]) {
      return true;
    }

    return false;
  });

  if (isQuestionAnswered) {
    logger.debug('question already answered:', question.title);
    return;
  }

  dispatch({
    type: ActionType.CHANGE_ROUTE,
    payload: {
      route: `/questions/${question.name}`,
      trigger: 'nav',
      event_id: Math.random().toString(36).slice(2),
    },
  });
}
