/* eslint-disable no-undef */
import { useCallback, useEffect, useMemo, useState, StateUpdater } from 'preact/hooks';
import { ActionType, SignInMethods, useGlobalContext } from './use-global-context';
import { LoginInitBody, LoginStep, SignInState, loginTypeToFieldMap } from '../Login';
import useRoute from './use-route';
import useReCaptcha from './use-recaptcha';
import { useApi } from '.';
import { logger } from '../utils/log';
import { RequestSignInIntent } from '../ExternalApi';
import { useTranslation } from 'preact-i18next';
import useFingerprint from './use-fingerprint';
import { UserAgentType, getUserAgentType, isIosDevice, validEmail } from '../utils';
import useUserApi from './use-user-api';
import { EventType, events } from '../events';
import { ROWND_LINKS } from '../utils/links';
import { useInstantUser } from './use-instant-user';
import { MessageType, sendMessageToApp } from '../utils/mobile-app';
import { RowndApiError, RowndApiErrorCodes } from './use-api';
import { SignInErrorMessages, useSignInErrorMessages } from '../Components/Login/Error/Error';
import useReturnUrl from './use-return-url';

interface UseSignInProps {
  setSignInState: StateUpdater<SignInState>;
  setError?: (e: string) => void;
  disableIntent?: boolean;
  intent?: RequestSignInIntent;
  userIdentifier: string;
  loginType: keyof SignInMethods | null;
  fieldError: string | null;
  setFieldError: (e: string | null) => void;
  addlFieldValues: any;
  addlFieldInit: (state: Record<string, string>) => Record<string, string>;
  shouldAlwaysSendVerificationMessage?: boolean;
  setShouldAlwaysSendVerificationMessage?: (e: boolean) => void;
  setIsExistingUser: (e: boolean) => void;
  setRequestId?: (e: string) => void;
  requiresAdditionalFields?: boolean;
  setRequiresAdditionalFields?: (e: boolean) => void;
  continueWithEmail?: boolean;
  groupToJoin?: string;
}

type SignInInitBody = {
  is_anonymous?: boolean;
  email?: string;
  phone?: string;
  return_url?: string;
  user_data: Record<string, any>;
  always_send_verification_message: boolean;
  redirect?: boolean;
  intent?: RequestSignInIntent;
  continue_with_email?: boolean;
  user_id: string;
  fingerprint?: {
    hash: string;
    challenge?: string;
  };
  turnstile_challenge_response?: string;
  recaptcha_token?: string;
  group_to_join?: string;
  instant_user_id: string;
};

export enum SignInMethodTypes {
  EMAIL = 'email',
  PHONE = 'phone',
  ANONYMOUS = 'anonymous',
  GOOGLE = 'google',
  APPLE = 'apple',
  PASSKEYS = 'passkeys',
  CRYPTO_WALLET = 'crypto_wallet',
}

export default function useSignIn() {
  const { state, dispatch } = useGlobalContext();
  const { nav, app, config, user } = state;

  const { navTo } = useRoute();
  const reCaptcha = useReCaptcha();
  const { client: api } = useApi();
  const { t } = useTranslation();
  const { getFingerprint, getChallengeIfPresent, removeChallenge } = useFingerprint();
  const { saveUserData } = useUserApi();
  const { instantUserId } = useInstantUser();
  const signInErrorMessages = useSignInErrorMessages();
  const { returnUrlWithDefault } = useReturnUrl();

  const [step, setStep] = useState<LoginStep>(LoginStep.INIT);
  const [error, setError] = useState<string>('');
  const [includeUserData, setIncludeUserData] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIncludeUserData(typeof nav?.options?.include_user_data === 'boolean' ? nav.options.include_user_data : true);
  }, [nav?.options]);

  const initialStep = useMemo(() => {
    if (nav.options?.login_step) {
      return nav.options.login_step;
    }
    return state.auth.access_token && state.auth.is_verified_user ? LoginStep.SUCCESS : LoginStep.INIT;
  }, [nav.options.login_step, state.auth.access_token, state.auth.is_verified_user]);

  const initSignIn = useCallback(
    async (opts: UseSignInProps) => {
      const updateStep = (newState: SignInState) => {
        opts.setSignInState(newState);
        setStep(newState.step);
      };

      const updateError = (newError: string) => {
        opts?.setError && opts?.setError(newError);
        setError(newError);
      };

      updateError('');

      // Validation
      if (opts.fieldError) {
        return;
      }

      // Construct the user_data object for the request payload.
      const userIdentifierFieldName = opts.loginType && loginTypeToFieldMap[opts.loginType];
      const userData = {
        ...user.data, // existing user data
        ...(userIdentifierFieldName && { [userIdentifierFieldName]: opts.userIdentifier }), // userIdentifier
      };

      const intent = opts.disableIntent ? undefined : opts.intent;

      const isMobileAndroid = getUserAgentType() === UserAgentType.mobile && !isIosDevice();
      const activeAndroidAccounts = state.android?.active_accounts;
      const androidAccountIsActive =
        opts.loginType === 'email' && !!activeAndroidAccounts?.some((account) => account.email === opts.userIdentifier);

      const signInFasterWithGoogle = () => {
        events.dispatch(EventType.PROMPT_TO_SIGN_IN_WITH_GOOGLE_INSTEAD);

        navTo('/google', '/account/login', {
          ...nav.options,
          use_modal: true,
          purpose: 'authentication',
          intent,
          suggest_google_sign_in_based_on_email: true,
          identifier: opts.userIdentifier,
        });
      };

      const isGoogleEnabled = Boolean(app.config?.hub?.auth?.sign_in_methods?.google?.enabled);
      const continueWithEmail =
        !isGoogleEnabled ||
        nav.options?.auto_sign_in ||
        nav?.options?.continue_with_email ||
        opts.continueWithEmail ||
        (isMobileAndroid && !androidAccountIsActive);

      if (androidAccountIsActive && !continueWithEmail && isGoogleEnabled) {
        signInFasterWithGoogle();
        return;
      }

      const groupToJoin = nav.options?.group_to_join || opts.groupToJoin;

      const payload: Partial<SignInInitBody> = {
        [opts.loginType === 'anonymous' ? 'is_anonymous' : (opts.loginType as string)]:
          opts.loginType === 'anonymous' ? true : opts.userIdentifier,
        return_url: returnUrlWithDefault(nav?.options?.post_login_redirect),
        user_data:
          includeUserData && Object.values(userData).some((f) => f !== null && f !== undefined) ? userData : {}, // Include user data if at least one field is defined
        always_send_verification_message: opts.shouldAlwaysSendVerificationMessage || false,
        redirect: nav.options?.redirect,
        intent,
        continue_with_email: continueWithEmail,
        group_to_join: groupToJoin,
        instant_user_id: instantUserId,
      };

      // Set the user_id to the application's default user id format if it is defined
      if (app.config?.default_user_id_format) {
        payload.user_id = app.config?.default_user_id_format;
      }

      if (opts?.requiresAdditionalFields) {
        let defaultAddlFieldValues = {};
        if (Object.keys(opts.addlFieldValues).length === 0) {
          defaultAddlFieldValues = opts.addlFieldInit({});
        }

        payload.user_data = {
          ...defaultAddlFieldValues,
          ...payload.user_data,
          ...opts.addlFieldValues,
        };
      }

      // Submission
      try {
        setIsSubmitting(true);

        // Skip sending fingerprint when verifying data
        let fingerprint;
        let challengeEntry;
        if (initialStep !== LoginStep.VERIFY) {
          // Get the browser fingerprint for future sign-ins that don't require re-verification
          fingerprint = await getFingerprint();
          challengeEntry = await getChallengeIfPresent(app.id, [opts.userIdentifier]);
          payload.fingerprint = {
            hash: fingerprint.visitorId,
            challenge: challengeEntry?.value,
          };
        }

        // Add reCaptcha token if available
        try {
          const token = await reCaptcha.execute('sign_in');
          payload.recaptcha_token = token;
        } catch (err) {
          logger.error('reCaptcha activation failed:', err);
        }

        events.dispatch(EventType.SIGN_IN_STARTED, {
          method: opts.loginType,
        });
        const resp: LoginInitBody = await api
          .post('hub/auth/init', {
            headers: {
              'x-rownd-app-key': config?.appKey,
            },
            json: payload,
          })
          .json();

        // This will only be true when a user is signing in for the very first time and
        // the app allows unverified users OR the user was successfully fingerprinted.
        if (resp.auth_tokens) {
          if (opts.loginType !== 'anonymous') {
            dispatch({
              type: ActionType.SET_SIGN_IN_METHOD,
              payload: { last_sign_in: validEmail(opts.userIdentifier) ? 'email' : 'phone' },
            });
          }

          const userData = payload.user_data;
          if (userData) {
            saveUserData(userData);
          }

          events.dispatch(EventType.SIGN_IN_COMPLETED, {
            method: opts.loginType,
            user_type: resp?.user_type,
          });

          dispatch({
            type: ActionType.LOGIN_SUCCESS,
            payload: {
              ...resp.auth_tokens,
              app_id: app.id,
            },
          });

          const allowUnverifiedUsers = app?.config?.hub?.auth?.allow_unverified_users === true;
          const isRegistrationStatusNewUser = resp.registration_status === 'new_user';
          const enableRedirect = nav?.options.redirect !== false;

          if (
            opts.intent === RequestSignInIntent.SignUp &&
            (!allowUnverifiedUsers || (allowUnverifiedUsers && !isRegistrationStatusNewUser))
          ) {
            opts.setIsExistingUser(true);
          }

          // Call a post-registration endpoint if configured and this is a first-time sign in
          if (allowUnverifiedUsers && isRegistrationStatusNewUser && enableRedirect) {
            if (state.config?.postRegistrationUrl) {
              let initData = resp?.init_data && btoa(JSON.stringify(resp.init_data));
              initData = initData
                ? state.config.postRegistrationUrl.includes('?')
                  ? `&rownd_init_data=${initData}`
                  : `?rownd_init_data=${initData}`
                : '';

              updateStep({
                step: LoginStep.SUCCESS,
                postRegistrationUrl: state.config.postRegistrationUrl + initData,
              });
            }
          }

          updateStep({ step: LoginStep.SUCCESS });
          return;
        }

        if (resp.fingerprint.status === 'invalid') {
          logger.debug('Fingerprint is invalid. Removing from local storage.');
          removeChallenge(challengeEntry?.value);
        }

        if (resp.message === 'No existing account found') {
          updateStep({ step: LoginStep.NO_ACCOUNT });
          return;
        }

        if (resp.message === 'Gmail account found') {
          signInFasterWithGoogle();
          return;
        }

        events.dispatch(EventType.VERIFICATION_STARTED, {
          method: opts.loginType,
        });

        sendMessageToApp({
          type: MessageType.AUTH_CHALLENGE_INITIATED,
          payload: {
            challenge_id: resp.challenge_id,
            user_identifier: opts.userIdentifier,
          },
        });

        if (nav.current_route != '/account/login' && resp.challenge_id) {
          // Redirect to login script to complete verification
          navTo('/account/login', 'sign-in-verification', {
            ...nav.options,
            use_modal: true,
            login_step: LoginStep.WAITING,
            request_id: resp.challenge_id,
            identifier: opts.userIdentifier,
            continue_with_email: continueWithEmail,
          });
          return;
        }
        updateStep({ step: LoginStep.WAITING });
        opts.setRequestId && opts.setRequestId(resp.challenge_id);
      } catch (err: any) {
        logger.error(err);

        const supportEmail = state.app.config?.hub?.legal?.support_email || ROWND_LINKS.SUPPORT_EMAIL;
        let errorMessage = t('Sign in failed. Please try again or contact {{support_email}}{{error}}', {
          error: `${err.errorCode ? ` (Error: ${err.errorCode})` : ''}`,
          support_email: supportEmail
        });

        if (err instanceof RowndApiError) {
          // TODO: This needs a big rework to improve the way we are handling errors from the API
          // server. Ideally, the API Server would respond with error codes like E_TURNSTILE_FAIL,
          // or E_ADDITIONAL_FIELDS_REQUIRED, which we could use to construct an i18n error
          // message here in the client.
          if (err.statusCode === 400) {
            if (err.message.includes('Turnstile')) {
              err.code = 'E_TVF';
            } else {
              updateStep({ step: LoginStep.INIT });
              opts.setRequiresAdditionalFields && opts.setRequiresAdditionalFields(true);
              return;
            }
          }

          switch (err.code) {
            case RowndApiErrorCodes.E_USER_PROFILE_DISABLED:
              errorMessage = t(
                'This account is currently disabled. Please try a different account or contact {{support_email}}',
                {
                  support_email: supportEmail,
                },
              );
              break;
            case RowndApiErrorCodes.E_RESTRICT_EMAIL_DOMAIN:
              errorMessage = signInErrorMessages[SignInErrorMessages.UnauthorizedEmail];
              updateError(errorMessage);
              opts.setFieldError(errorMessage);
              return;
            case RowndApiErrorCodes.E_RESTRICT_SIGN_UPS:
              errorMessage =
                opts.loginType === 'phone'
                  ? signInErrorMessages[SignInErrorMessages.SignUpDisabledPhone]
                  : signInErrorMessages[SignInErrorMessages.SignUpDisabled];
              updateError(errorMessage);
              opts.setFieldError(errorMessage);
              return;
            default:
              break;
          }
        }

        updateStep({ step: LoginStep.ERROR });
        updateError(errorMessage);

        events.dispatch(EventType.SIGN_IN_FAILED, {
          reason: err.errorCode,
        });
      } finally {
        setIsSubmitting(false);
        opts.setShouldAlwaysSendVerificationMessage && opts.setShouldAlwaysSendVerificationMessage(false);
        setIncludeUserData(true);
      }
    },
    [
      api,
      app.config?.default_user_id_format,
      app.config?.hub?.auth?.allow_unverified_users,
      app.config?.hub?.auth?.sign_in_methods?.google?.enabled,
      app.id,
      config?.appKey,
      dispatch,
      getChallengeIfPresent,
      getFingerprint,
      includeUserData,
      initialStep,
      instantUserId,
      nav.current_route,
      nav.options,
      navTo,
      reCaptcha,
      removeChallenge,
      returnUrlWithDefault,
      saveUserData,
      signInErrorMessages,
      state.android?.active_accounts,
      state.app.config?.hub?.legal?.support_email,
      state.config?.postRegistrationUrl,
      t,
      user.data,
    ],
  );

  return {
    initSignIn,
    isSubmitting,
    step,
    error,
  };
}
