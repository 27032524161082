import { useCallback } from 'preact/hooks';
import { useGlobalContext, ActionType } from './use-global-context';

export default function useRoute() {
  const { dispatch } = useGlobalContext();

  return {
    navTo: useCallback(
      (route: string, trigger = 'nav', opts: Record<string, any> = {}) => {
        dispatch({
          type: ActionType.CHANGE_ROUTE,
          payload: {
            route,
            trigger: trigger || 'nav',
            event_id: Math.random().toString(36).slice(2),
            opts,
          },
        });
      },
      [dispatch]
    ),
    setPopupRoute: useCallback(
      (popup_route?: string) => {
        dispatch({
          type: ActionType.SET_POPUP_ROUTE,
          payload: {
            popup_route,
          },
        });
      },
      [dispatch]
    ),
  };
}
