import useApi from './use-api';
import { useGlobalContext } from './use-global-context';
import { logger } from '../utils/log';

export enum MetricEvent {
  INITIATE_SIGN_IN = 'INITIATE_SIGN_IN',
}

type Metric = {
  event: MetricEvent;
  step: string;
  signInModalTitle: string;
  signInMethods: string[];
  isTryAnotherWayButtonVisible: boolean;
};

export default function () {
  const { client: api } = useApi();
  const { state } = useGlobalContext();
  async function collectMetric(metric: Metric) {
    try {
      await api
        .post('hub/events/metrics', {
          headers: {
            'x-rownd-app-key': state.config?.appKey,
          },
          json: {
            event: metric?.event,
            data: {
              step: metric?.step,
              modal_title: metric?.signInModalTitle,
              sign_in_methods: metric?.signInMethods,
              is_try_another_way_button_visible: metric?.isTryAnotherWayButtonVisible,
            },
          },
        })
        .json();
    } catch (err) {
      logger.warn('Failed to send app event ', err);
    }
  }

  return {
    collectMetric,
  };
}
