import { useRoute } from '@/scripts/hooks';
import { SignInMethods, useGlobalContext } from '@/scripts/hooks/use-global-context';
import { FieldComponent } from '@/scripts/utils/form';
import { PROFILE_TITLE_CLASS, ProfileStep } from '../Profile';
import { LoginStep } from '@/scripts/Login';
import { useTranslation } from 'preact-i18next';
import { VerificationErrors } from '@/scripts/hooks/use-verification-method';
import { ProfileTypes, profileTypesTranslation } from '@/scripts/Components/Profile/Profile';
import Button from '../../Common/Button/Button';
import { StateUpdater, useMemo } from 'preact/hooks';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import { useInstantUser } from '@/scripts/hooks/use-instant-user';
import useSignInMethods from '@/scripts/hooks/use-sign-in-methods';

interface AccountInformationProps {
  setStep: (e: ProfileStep) => void;
  errors: Record<string, string>;
  edits: Record<string, any>;
  handleInputChange: (fieldName: string, value: any) => void;
  handleSelectChange: (fieldName: string, value: any) => void;
  handleInputBlur?: (fieldName: string, value: any) => void;
  verificationFields: string[];
  dataToShow: Record<string, any>;
  autoFocusField?: string;
  handleEmailOrPhoneUpdate: (type: 'email' | 'phone', value: string) => void;
  isSubmittingVerification: boolean;
  setFocused: StateUpdater<string | null>;
}

export function AccountInformation({
  setStep,
  errors,
  edits,
  handleInputChange,
  handleSelectChange,
  handleInputBlur,
  verificationFields,
  dataToShow,
  autoFocusField,
  handleEmailOrPhoneUpdate,
  isSubmittingVerification,
  setFocused,
}: AccountInformationProps) {
  const { state } = useGlobalContext();
  const { navTo } = useRoute();
  const { t } = useTranslation();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { instantUserAccessToken } = useInstantUser();
  const { signInMethodsEnabled } = useSignInMethods();

  const { app, user, is_loading_user_data: isLoadingUserData, config, nav } = state;
  const isDarkMode = useMemo(() => state.computed_color_mode === 'dark', [state.computed_color_mode]);

  const profile = app?.config?.hub?.profile;
  const accountInformationMethods = profile?.account_information?.methods;

  const isMobileAppView = config?.displayContext === 'mobile_app';
  const addSignInMethodsButton = profile?.add_sign_in_methods_button?.enabled !== false; // Default is to show Add Sign In Methods Button
  const unconnectedAccounts = useMemo(() => {
    return (
      (app?.config?.hub?.auth?.sign_in_methods?.apple?.enabled && !user.data['apple_id']) ||
      (app?.config?.hub?.auth?.sign_in_methods?.crypto_wallet?.enabled && !user.data['crypto_wallet_address'])
    );
  }, [app?.config?.hub?.auth?.sign_in_methods, user.data]);

  const verifyContent = useMemo(() => {
    return isSubmittingVerification ? <span className="rph-loading-circle" /> : t('Verify');
  }, [isSubmittingVerification, t]);

  return (
    <>
      {state.use_modal && (
        <div className={`rph-account-fields-form__title ${PROFILE_TITLE_CLASS}${ProfileTypes.Account}`}>
          {profileTypesTranslation.account.title}
        </div>
      )}

      {instantUserAccessToken && state.use_modal && (
        <div className="rph-account__sign-in">
          <div className="rph-account__sign-in__title">Sign in or sign up to add more to your profile</div>
          <Button
            handleOnClick={() => {
              navTo('/account/login', 'profile', {
                use_modal: true,
              });
            }}
            height={40}
            label={t('Sign in or sign up')}
          />
        </div>
      )}

      {!instantUserAccessToken &&
        state.use_modal &&
        nav.options.visible_profile_fields &&
        (isMobileAppView || isBottomSheetEnabled) && (
          <div className="rph-account-fields-form__subtitle">{t('Tap a field below to edit.')}</div>
        )}
      {!instantUserAccessToken && app.schema &&
        Object.entries(app?.schema).map(([key, field], index) => {
          const isVerificationField = verificationFields.includes(key);
          const isMethodEnabled = Boolean(accountInformationMethods?.[key as keyof SignInMethods]?.enabled ?? true);

          const isLoadingAndNoUserData = isLoadingUserData && Object.keys(state.user?.data || {}).length === 0;
          const isFieldVisible = field.user_visible;

          if (!isVerificationField || isLoadingAndNoUserData || !isFieldVisible || !isMethodEnabled) {
            return null;
          }

          const fieldValue = dataToShow?.[key];
          const shouldShowEmptyEmailWithPhoneEnabled =
            key === 'email' && !fieldValue && signInMethodsEnabled.phone && state.use_modal && state.auth.access_token;

          if (!fieldValue && !shouldShowEmptyEmailWithPhoneEnabled) {
            return null;
          }

          const verifiedData = user.verified_data;
          const userData = { ...user.data, ...user.verified_data };
          const userDataValue = userData[key];
          const verifiedDataValue = verifiedData[key];
          const error = errors[key];
          const isPhoneOrEmail = ['phone_number', 'email'].includes(key);

          return (
            <div className="rph-field" key={`rph-field-${key}`}>
              <label className="rph-field-name">
                {field.display_name}
                {!!edits[key] &&
                  !!verifiedDataValue &&
                  edits[key] !== verifiedDataValue &&
                  !error &&
                  isPhoneOrEmail && (
                    <button
                      className="rph-button-link"
                      type="button"
                      onClick={() => {
                        handleEmailOrPhoneUpdate(key === 'phone_number' ? 'phone' : 'email', edits[key]);
                      }}
                    >
                      {verifyContent}
                    </button>
                  )}
                {!!userDataValue && !verifiedDataValue && !error && isPhoneOrEmail && (
                  <button
                    className="rph-button-link"
                    type="button"
                    onClick={() => {
                      if (edits[key] && edits[key] !== userDataValue) {
                        handleEmailOrPhoneUpdate(key === 'phone_number' ? 'phone' : 'email', edits[key]);
                        return;
                      }
                      navTo('/account/login', 'nav', {
                        identifier: edits[key] || user.data[key],
                        login_step: LoginStep.VERIFY,
                        use_modal: state.use_modal,
                        auto_sign_in: true,
                      });
                    }}
                  >
                    {verifyContent}
                  </button>
                )}
                {userDataValue &&
                  userDataValue === verifiedDataValue &&
                  (typeof edits[key] !== 'string' || edits[key] === verifiedDataValue) &&
                  isPhoneOrEmail && (
                    <p className="rph-account-fields__verified">
                      <div className="rph-account-fields__verified-icon" />
                      {t('Verified')}
                    </p>
                  )}
              </label>
              <div className="rph-field-value">
                <div className="rph-field-value-inner">
                  {FieldComponent({
                    fieldName: key,
                    field,
                    index,
                    focus: autoFocusField === key,
                    state,
                    user,
                    edits,
                    handleInputChange,
                    handleSelectChange,
                    handleInputBlur: (field, value) => {
                      if (handleInputBlur) handleInputBlur(field, value);
                      setFocused(null);
                    },
                    handleInputFocus: (fieldName) => setFocused(fieldName),
                    customClass: 'rph-account-fields__request-field',
                  })}
                  {errors[key] === VerificationErrors.EXISTING_ACCOUNT && (
                    <div className="rph-account-fields__request-field__error-text">
                      This {field.display_name.toLowerCase()} belongs to an existing account.&nbsp;
                      <u onClick={() => setStep(ProfileStep.EXISTING_ACCOUNT)}>{t('Learn more')}</u>
                    </div>
                  )}
                  {errors[key] && errors[key] != VerificationErrors.EXISTING_ACCOUNT && (
                    <div className="rph-account-fields__request-field__error-text">{errors[key]}</div>
                  )}
                  {edits[key] && edits[key] !== userDataValue && !errors[key] && isPhoneOrEmail && (
                    <div className="rph-account-fields__helper-text">{t('Verify or save to update this method')}</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}

      {!instantUserAccessToken && state.use_modal &&
        !isMobileAppView &&
        state.auth.access_token &&
        unconnectedAccounts &&
        addSignInMethodsButton && (
          <div className="rph-profile-add-methods">
            {!isMobileAppView && (
              <div className="rph-profile-add-methods__text">{t('Add additional accounts and sign in methods')}</div>
            )}
            <Button
              type={isDarkMode ? 'primary' : 'secondary'}
              handleOnClick={() => {
                navTo('/account/login', 'nav', {
                  login_step: LoginStep.CONNECT_ACCOUNT,
                  use_modal: true,
                  auto_sign_in: false,
                });
              }}
              label={t('Add new sign in methods')}
            />
          </div>
        )}
    </>
  );
}
