import { useEffect, useState } from 'preact/hooks';

export default function useEsmSuported() {
  const [isSupported, setSupported] = useState<boolean>(true);
  useEffect(() => {
    doesBrowserSupportEsm().then((supported: boolean) => {
      setSupported(supported);
    });
  }, []);

  return isSupported;
}

async function doesBrowserSupportEsm(): Promise<boolean> {
  // Determine if the browser supports ES Modules
  try {
    return new Function("return import('data:text/javascript;base64,Cg==').then(r => true)")();
  } catch (e) {
    return Promise.resolve(false);
  }
}
