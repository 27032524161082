import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { h } from 'preact';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

export default function Loading() {
  const { state } = useGlobalContext();
  const loadingAnimation = state.app.config?.customizations?.animations?.loading;

  const DefaultLoading = () => <div className="rph-loading-default" />;

  if (loadingAnimation) {
    return (
      <LottieAnimation
        customClass="rph-loading-animation"
        path={loadingAnimation}
        loop={true}
        style="display: flex !important"
        fallback={<DefaultLoading />}
      />
    );
  }

  return <DefaultLoading />;
}
