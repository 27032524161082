import {
  type Account,
  type FinalExecutionOutcome,
  type NetworkId,
  type WalletSelector,
} from '@near-wallet-selector/core';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useGlobalContext } from '../use-global-context';
import { useApi, useRoute } from '..';
import { NearState, NearStatus } from './use-near';
import { logger } from '@/scripts/utils/log';
import { LoginStep } from '@/scripts/Login';
import { useTranslation } from 'preact-i18next';
import useUserApi from '../use-user-api';

let selector: WalletSelector | null = null;
let initializing = false;

export default function useWalletSelector({ nearState }: { nearState: NearState }): {
  selector: WalletSelector | null;
  signingIn: boolean;
} {
  const { t } = useTranslation();
  const { navTo } = useRoute();
  const { state } = useGlobalContext();
  const { client: api } = useApi();
  const { saveUserData } = useUserApi();
  const [handlingSignIn, setHandlingSignIn] = useState<boolean>(false);
  const [postInitFn, setPostInitFn] = useState<(() => void) | null>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const signedInHandler = useCallback(
    async ({
      walletId,
      accounts,
      contractId,
      methodNames,
    }: {
      walletId: string;
      accounts: Account[];
      contractId?: string;
      methodNames?: string[];
    }) => {
      if (handlingSignIn) {
        return;
      }
      setHandlingSignIn(true);

      // Only proceed if the user doesn't already have a personal NEAR wallet connected
      if (!state.user.loaded_once || state.user.data.near_personal_account_id) {
        setHandlingSignIn(false);
        return;
      }

      if (state.nav.current_route !== '/account/login') {
        navTo('/account/login', 'use-wallet-selector', {
          login_step: LoginStep.WAITING_FOR_WALLET_PROVIDER,
          use_modal: true,
        });
      }

      logger.debug(`Signed in to NEAR wallet: ${walletId}`);

      interface NearWalletInitResponseBody {
        challenge_id: string;
        challenge_hash_transaction: string;
        challenge_hash_transaction_signer: string;
      }

      try {
        const response: NearWalletInitResponseBody = await api
          .post('hub/auth/wallet/init', {
            headers: {
              'x-rownd-app-key': state.config?.appKey,
            },
            json: {
              account_id: accounts[0].accountId,
              wallet_id: walletId,
              network_id: `near-${nearState.config?.network_id}` || 'near-testnet',
              app_id: state.app.id,
              access_token: state.auth.access_token,
            },
          })
          .json();

        const wallet = await selector?.wallet(walletId);
        if (!wallet) {
          return;
        }

        const accountId = accounts[0].accountId;

        const contractResponse = (await wallet?.signAndSendTransaction({
          signerId: accountId,
          receiverId: contractId,
          actions: [
            {
              type: 'FunctionCall',
              params: {
                methodName: 'sign_challenge',
                args: {
                  challenge_id: response.challenge_id,
                },
                gas: '30000000000000',
                deposit: '0',
              },
            },
          ],
        })) as FinalExecutionOutcome;

        logger.debug('Response from smart contract function call:', contractResponse);
        const completeResponse = await api
          .post('hub/auth/wallet/complete', {
            headers: {
              'x-rownd-app-key': state.config?.appKey,
            },
            json: {
              challenge_id: response.challenge_id,
              transaction_hash: contractResponse.transaction.hash,
              transaction_signer_id: contractResponse.transaction.signer_id,
            },
          })
          .json();

        const userData = (completeResponse as any).user_data;
        if (userData) {
          saveUserData(userData);
        }

        navTo('/account/login', 'use-wallet-selector', {
          login_step: LoginStep.SUCCESS,
          use_modal: true,
          success_message: t('Wallet connected!'),
        });
      } catch (err) {
        navTo('/account/login', 'use-wallet-selector', {
          login_step: LoginStep.ERROR,
          use_modal: true,
        });
      } finally {
        setHandlingSignIn(false);
      }
    },
    [
      api,
      handlingSignIn,
      navTo,
      nearState.config?.network_id,
      saveUserData,
      state.app.id,
      state.auth.access_token,
      state.config?.appKey,
      state.nav.current_route,
      state.user.data.near_personal_account_id,
      state.user.loaded_once,
      t,
    ],
  );

  const init = useCallback(async () => {
    // Don't initialize more than once
    if (initializing) {
      return;
    }
    initializing = true;

    if (nearState.status !== NearStatus.Fetched) {
      // Don't initialize the wallet selector until the NEAR state has been fetched
      return;
    }

    if (!selector) {
      const { setupWalletSelector } = await import('@near-wallet-selector/core');
      const { setupMyNearWallet } = await import('@near-wallet-selector/my-near-wallet');
      const { setupHereWallet } = await import('@near-wallet-selector/here-wallet');
      const { setupMeteorWallet } = await import('@near-wallet-selector/meteor-wallet');
      const networkId = nearState.config?.network_id || 'testnet';
      const _selector = await setupWalletSelector({
        network: networkId as NetworkId,
        modules: [
          setupMyNearWallet(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setupMeteorWallet(),
          setupHereWallet(),
        ],
        debug: true,
      });

      if (_selector) {
        _selector.on('signedIn', signedInHandler);
      }

      if (_selector.isSignedIn()) {
        setPostInitFn(() => {
          const selectorState = _selector.store.getState();
          signedInHandler({
            walletId: selectorState.recentlySignedInWallets[0],
            accounts: selectorState.accounts,
            contractId: selectorState.contract?.contractId,
            methodNames: selectorState.contract?.methodNames,
          }).catch((err: Error) => {
            logger.error('Failure in NEAR wallet sign-in handler', err);
          });
        });
      }

      selector = _selector;
    }
  }, [nearState.config?.network_id, nearState.status, signedInHandler]);

  // Wait for the NEAR config to be fetched and an access token available
  useEffect(() => {
    if (nearState.status === NearStatus.Fetched && state.auth.access_token && postInitFn) {
      const fn = postInitFn;
      setPostInitFn(null);
      fn();
    }
  }, [nearState.status, postInitFn, state.auth.access_token]);

  useEffect(() => {
    init()
      .catch((err: Error) => {
        logger.error('Failed to initialize NEAR Wallet Selector', err);
      })
      .finally(() => {
        initializing = false;
      });
  }, [init]);

  return { selector, signingIn: handlingSignIn };
}
0;
