import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { useGlobalContext } from './hooks/use-global-context';

export default function ThirdPartyScripts() {
  const { state } = useGlobalContext();

  const [hasLoadedApple, setHasLoadedApple] = useState(false);
  const [hasLoadedGoogle, setHasLoadedGoogle] = useState(false);

  const hasLoaded = useMemo(() => {
    return hasLoadedApple && hasLoadedGoogle;
  }, [hasLoadedApple, hasLoadedGoogle]);

  const loadThirdPartyScripts = useCallback(async () => {
    const loadSignInWithApple = state.app.config?.hub?.auth?.sign_in_methods?.apple?.enabled;
    const loadSignInWithGoogle = state.app.config?.hub?.auth?.sign_in_methods?.google?.enabled;
    if (!hasLoadedApple && loadSignInWithApple) {
      const appleScript = document.createElement('script');
      appleScript.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      appleScript.async = false;
      document.head.appendChild(appleScript);
      setHasLoadedApple(true);
    }

    if (!hasLoadedGoogle && loadSignInWithGoogle && state.app.config?.hub?.auth?.sign_in_methods?.google?.client_id) {
      const googleScript = document.createElement('script');
      googleScript.src = 'https://accounts.google.com/gsi/client';
      googleScript.async = true;
      googleScript.defer = true;
      document.head.appendChild(googleScript);

      const googleStyle = document.createElement('style');
      googleStyle.innerText =
        "@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');";
      document.head.appendChild(googleStyle);

      setHasLoadedGoogle(true);
    }
  }, [
    state.app.config?.hub?.auth?.sign_in_methods?.apple?.enabled,
    state.app.config?.hub?.auth?.sign_in_methods?.google?.enabled,
    state.app.config?.hub?.auth?.sign_in_methods?.google?.client_id,
    hasLoadedApple,
    hasLoadedGoogle,
  ]);

  useEffect(() => {
    if (!state.app.config || hasLoaded) {
      return;
    }
    loadThirdPartyScripts();
  }, [hasLoaded, loadThirdPartyScripts, state.app.config]);

  return null;
}
