import { h } from 'preact';
import { useGlobalContext, SchemaField } from '@/scripts/hooks/use-global-context';
import { useTranslation } from 'preact-i18next';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import Checkbox from '../Common/Checkbox/Checkbox';
import Input from '../Common/Input/Input';
import Button from '../Common/Button/Button';
import useHandleClose from '@/scripts/hooks/use-handle-close';
import Picker from '../Common/Picker/Picker';
import PickerGroup from '../Common/Picker/PickerGroup';
import useUserApi from '@/scripts/hooks/use-user-api';
import { logger } from '@/scripts/utils/log';

export type FieldProps = {
  id: string;
  name: string;
  type: 'text' | 'checkbox' | 'radio';
  title?: string | undefined;
  required?: 'true' | 'false' | undefined;
  radio_true?: string;
  radio_false?: string;
};

export type CustomSchemaField = SchemaField & {
  field_props: FieldProps;
};

enum CollectInfoTypes {
  Checkbox = 'checkbox',
  Radio = 'radio',
  Text = 'text',
}

export type CustomAppSchema = Record<string, CustomSchemaField>;

export default function CollectInformation() {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const { setUser, processFieldErrors } = useUserApi();
  const handleClose = useHandleClose();
  const { nav } = state;
  const [edits, setEdits] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [isSaving, setIsSaving] = useState(false);

  const isRequired = useMemo(() => Boolean(nav.options?.prevent_closing), [nav.options?.prevent_closing]);

  const schemaFields = useMemo(() => {
    const fields: FieldProps[] | undefined = nav?.options?.fields;
    const schema = state.app.schema;

    if (!state?.app?.schema || !fields) return undefined;

    return fields.reduce((newSchema: CustomAppSchema, field) => {
      if (schema?.[field.name]) {
        newSchema[field.name] = { ...schema?.[field.name], field_props: field };
        return newSchema;
      }
      return newSchema;
    }, {});
  }, [nav?.options?.fields, state.app.schema]);

  // Set initial values
  useEffect(() => {
    if (!schemaFields) return;
    Object.keys(schemaFields).forEach((fieldName) => {
      const value = state.user.data?.[fieldName];
      if (value) {
        setEdits((prev) => ({
          ...prev,
          [fieldName]: value,
        }));
      }
    });
  }, [state.user.data, schemaFields]);

  const removeError = useCallback((fieldName: string) => {
    setErrors((prev) => {
      delete prev?.[fieldName];
      return prev;
    });
  }, []);

  const handleInputChange = useCallback(
    (fieldName: string, value: any) => {
      removeError(fieldName);
      setEdits({
        ...edits,
        [fieldName]: value,
      });
    },
    [edits, removeError],
  );

  const handleSelectChange = useCallback(
    (fieldName: string, value: 'true' | 'false') => {
      removeError(fieldName);
      setEdits({
        ...edits,
        [fieldName]: value,
      });
    },
    [edits, removeError],
  );

  const handleUniqueSchemaError = useCallback(() => {
    if (!schemaFields) return;
    Object.keys(schemaFields).forEach((fieldName) => {
      const field = schemaFields[fieldName];
      if (field.unique) {
        setErrors((prev) => ({
          ...prev,
          [fieldName]: t(`This {{displayName}} already exists. Please try again.`, {
            displayName: (field.display_name || fieldName).toLowerCase(),
          }),
        }));
      }
    });
  }, [schemaFields, t]);

  const checkIfValid = useCallback((): boolean => {
    if (!schemaFields) return false;
    const newErrors: Record<string, any> = {};
    Object.keys(schemaFields).forEach((fieldName) => {
      const field = schemaFields[fieldName];

      switch (field.field_props.type) {
        case CollectInfoTypes.Text:
          if (!edits[fieldName] && isRequired) {
            newErrors[fieldName] = t('Required');
          }
          break;
        case CollectInfoTypes.Checkbox:
          if (field.field_props.required === 'true' && edits[fieldName] !== 'true' && isRequired) {
            newErrors[fieldName] = t('Required');
          }
          break;
        case CollectInfoTypes.Radio:
          if (!edits[fieldName] && isRequired) {
            newErrors[fieldName] = t('Required');
          }
          break;
        default:
          break;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [edits, isRequired, schemaFields, t]);

  const handleSubmit = useCallback(
    async (e: h.JSX.TargetedEvent<HTMLFormElement, Event>) => {
      e.preventDefault();
      e.stopPropagation();
      if (checkIfValid()) {
        setIsSaving(true);
        try {
          await setUser({
            ...state.user.data,
            ...edits,
          });

          handleClose();
        } catch (err: any) {
          logger.error('Error saving user data', err);
          const statusCode: number | undefined = err?.statusCode;

          if (err.payload?.field_errors) {
            setErrors(processFieldErrors(err.payload.field_errors));
          }

          if (statusCode === 409) {
            handleUniqueSchemaError();
          }
        } finally {
          setIsSaving(false);
        }
      }
    },
    [checkIfValid, edits, handleClose, handleUniqueSchemaError, processFieldErrors, setUser, state.user.data],
  );

  return (
    <div className="rph-modal rph-collect-information">
      <div className="rph-collect-information__title">{nav.options?.title || t('Please provide more information')}</div>
      <div className="rph-collect-information__subtitle">
        {nav.options?.subtitle || t('Please provide additional information to continue.')}
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          {schemaFields &&
            Object.keys(schemaFields).map((fieldName) => {
              const field = schemaFields[fieldName];

              switch (field.field_props.type) {
                case CollectInfoTypes.Checkbox:
                  return (
                    <Checkbox
                      onChange={() => {
                        if (edits[fieldName] === 'true') {
                          handleSelectChange(fieldName, 'false');
                          return;
                        }
                        handleSelectChange(fieldName, 'true');
                      }}
                      isChecked={edits[fieldName] === 'true'}
                      label={field.field_props.title || field.display_name || fieldName}
                      error={errors[fieldName]}
                    />
                  );
                case CollectInfoTypes.Text:
                  return (
                    <Input
                      onChange={(e) => {
                        // eslint-disable-next-line
                        // @ts-ignore
                        handleInputChange(fieldName, e?.target?.value);
                      }}
                      value={edits[fieldName] || ''}
                      placeholder={field.field_props.title || field.display_name || fieldName}
                      label={field.field_props.title || field.display_name || fieldName}
                      error={errors[fieldName]}
                    />
                  );
                case CollectInfoTypes.Radio:
                  return (
                    <PickerGroup
                      horizontal={true}
                      label={field.field_props.title || field.display_name || fieldName}
                      error={errors[fieldName]}
                    >
                      <Picker
                        onChange={() => handleSelectChange(fieldName, 'true')}
                        isChecked={edits[fieldName] === 'true'}
                        label={field.field_props.radio_true || 'Yes'}
                      />
                      <Picker
                        onChange={() => handleSelectChange(fieldName, 'false')}
                        isChecked={edits[fieldName] === 'false'}
                        label={field.field_props.radio_false || 'No'}
                      />
                    </PickerGroup>
                  );
                default:
                  return null;
              }
            })}
        </div>
        <Button label={nav.options?.button || t('Continue')} isSubmit={true} isLoading={isSaving} />
      </form>
    </div>
  );
}
