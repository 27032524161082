import { useMemo } from 'preact/hooks';
import { INPUT_SIGN_IN_METHODS, SignInMethods, useGlobalContext } from './use-global-context';

const useSignInMethods = () => {
  const { state } = useGlobalContext();
  const { app } = state;

  const isMobileApp = useMemo(() => state.config?.displayContext === 'mobile_app', [state.config?.displayContext]);

  const allowedIdentifiers = useMemo(() => {
    return INPUT_SIGN_IN_METHODS.filter(
      (method) => app?.config?.hub?.auth?.sign_in_methods?.[method]?.enabled || false
    );
  }, [app?.config?.hub?.auth]);

  const signInMethodsEnabled = useMemo((): Record<keyof SignInMethods, boolean> => {
    const signInMethods = app?.config?.hub?.auth?.sign_in_methods;

    const passkeySignInSupported = !!signInMethods?.passkeys?.enabled;
    const appleSignInSupported = !!signInMethods?.apple?.enabled && !!signInMethods?.apple?.client_id;
    let googleSignInSupported = !!signInMethods?.google?.enabled && !!signInMethods?.google?.client_id;
    let walletSignInSupported = !!signInMethods?.crypto_wallet?.enabled;
    const anonymousSignInSupported = !!signInMethods?.anonymous?.enabled;
    const oauth2SignInSupported = Object.keys(signInMethods || {}).some((method) => method?.startsWith('oauth2_'));

    if (isMobileApp) {
      // crypto wallet sign-ins are currently not supported in mobile apps
      walletSignInSupported = false;

      // iOS-specifics
      if (window?.webkit?.messageHandlers?.rowndIosSDK) {
        googleSignInSupported = googleSignInSupported && !!signInMethods?.google?.ios_client_id;
      }
    }

    return {
      apple: appleSignInSupported,
      google: googleSignInSupported,
      passkeys: passkeySignInSupported,
      crypto_wallet: walletSignInSupported,
      phone: !!allowedIdentifiers.includes('phone'),
      email: !!allowedIdentifiers.includes('email'),
      anonymous: anonymousSignInSupported,
      oauth2: oauth2SignInSupported,
    };
  }, [allowedIdentifiers, app?.config?.hub?.auth?.sign_in_methods, isMobileApp]);

  return {
    signInMethodsEnabled,
    allowedIdentifiers,
  };
};

export default useSignInMethods;
