import { useState, useCallback } from 'preact/hooks';
import { useGlobalContext, ActionType } from '@/scripts/hooks/use-global-context';
import { useTranslation } from 'preact-i18next';
import Input from '../Common/Input/Input';
import Button from '../Common/Button/Button';
import { useApi, useRoute } from '@/scripts/hooks';
import { logger } from '@/scripts/utils/log';

export default function DeleteAccount() {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalContext();
  const { navTo } = useRoute();
  const { client: api } = useApi();

  const [deleteInput, setDeleteInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteInputChange = (event: Event) => {
    setDeleteInput((event.target as HTMLInputElement).value);
  };

  const handleDeleteAccount = useCallback(async () => {
    setIsLoading(true);
    try {
      await api
        .delete(`me/applications/${state.app.id}/data`, {
          authenticated: true,
        })
        .then(() => {
          dispatch({
            type: ActionType.SIGN_OUT,
            payload: {},
          });
          setIsLoading(false);
        });
    } catch (err: any) {
      logger.error(err);
      setIsLoading(false);
    }
  }, [api, dispatch, state.app.id]);

  return (
    <div className="rph-delete-account rph-modal">
      <div className="rph-delete-account__title">{t('Delete your account')}</div>
      <div className="rph-delete-account__subtitle">
        {t('Deleting your account will permanently erase your account and all of your data within it from this app.')}
      </div>
      <div className="rph-delete-account__subtitle">
        {t('You will no longer have access to your account and everything that is a part of it.')}
      </div>
      <div className="rph-delete-account__subtitle">
        {t('This action cannot be undone, are you sure you want to continue?')}
      </div>
      <div className="rph-delete-account__input-subtitle">
        {t('Type "DELETE" below to continue with account deletion')}
      </div>
      <div className="rph-delete-account__value">
        <Input
          type="text"
          name="delete"
          placeholder="Type here"
          value={deleteInput}
          onChange={handleDeleteInputChange}
        />
      </div>
      <div className="rph-delete-account__footer">
        <Button
          customClass="rph-delete-account__cancel-button"
          type={'secondary'}
          label={t('Cancel')}
          handleOnClick={() => {
            navTo('/account/manage', 'DeleteAccount', {
              use_modal: state.nav.options?.use_modal,
            });
          }}
        />
        <Button
          type={'danger'}
          customClass="rph-delete-account__delete-button"
          disabled={deleteInput !== 'DELETE'}
          label={t('Delete account')}
          handleOnClick={handleDeleteAccount}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
