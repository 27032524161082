import { useCallback } from 'preact/hooks';
import { ActionType, PagesData, useGlobalContext } from './use-global-context';
import useApi from './use-api';
import { logger } from '../utils/log';

type PagesDataResponse = {
  results: PagesData[];
};

const usePages = () => {
  const { client: api } = useApi();
  const { state, dispatch } = useGlobalContext();

  const fetchPages = useCallback(async (): Promise<PagesData[] | undefined> => {
    const appKey = state?.config?.appKey;
    const appId = state.app.id;
    if (!appKey || !appId) return undefined;

    dispatch({ type: ActionType.SET_IS_LOADING_PAGES, payload: true });

    try {
      const res = await api
        .get(`applications/${appId}/automations/mobile/pages`, {
          headers: {
            'x-rownd-app-key': appKey,
          },
        })
        .json<PagesDataResponse>();

      dispatch({ type: ActionType.SET_PAGES, payload: res.results });
      return res.results;
    } catch (err) {
      logger.error(err);
      return undefined;
    } finally {
      dispatch({ type: ActionType.SET_IS_LOADING_PAGES, payload: false });
    }
  }, [api, state.app.id, state?.config?.appKey, dispatch]);

  return {
    fetchPages,
  };
};

export default usePages;
