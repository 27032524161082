import preact, { h } from 'preact';
import classNames from 'classnames';

interface CheckboxProps {
  customClass?: string;
  onChange: () => void;
  isChecked: boolean;
  label?: string | object;
  disabled?: boolean;
  error?: string;
}

export default function Checkbox({ customClass, onChange, isChecked, label, disabled, error }: CheckboxProps) {
  const attributes: { disabled?: true } = {};
  if (disabled) {
    attributes.disabled = true;
  }

  const onKeyDown = (e: h.JSX.TargetedKeyboardEvent<HTMLLabelElement>) => {
    if (e.key === 'Enter') {
      onChange();
    }
  };

  return (
    <>
      <label
        className={classNames('rph-checkbox', {
          [`${customClass}`]: customClass,
          'rph-checkbox__disabled': disabled,
        })}
        tabIndex={0}
        onKeyDown={onKeyDown}
      >
        {label}
        <input {...attributes} type="checkbox" checked={isChecked} onChange={onChange} />

        <span
          className={classNames('rph-checkbox__checkmark', {
            'rph-checkbox__checkmark-active': isChecked,
            'rph-checkbox__checkmark-error': !!error,
          })}
        />
      </label>
      {error && <div className="rph-input__error">{error}</div>}
    </>
  );
}
