function QrSVG() {
  return (
    <svg id="qr-icon" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32">
      <defs>
        <style>{`.cls-1{fill:none;}`}</style>
      </defs>
      <title>qr-code</title>
      <rect fill="currentColor" x="24" y="26" width="2" height="2" transform="translate(-2 52) rotate(-90)" />
      <rect fill="currentColor" x="18" y="22" width="2" height="2" transform="translate(-4 42) rotate(-90)" />
      <polygon fill="currentColor" points="18 30 22 30 22 28 20 28 20 26 18 26 18 30" />
      <rect
        fill="currentColor"
        x="24.9999"
        y="23"
        width="4"
        height="2"
        transform="translate(2.9999 50.9999) rotate(-90)"
      />
      <polygon fill="currentColor" points="28 26 30 26 30 30 26 30 26 28 28 28 28 26" />
      <polygon fill="currentColor" points="26 20 26 18 30 18 30 22 28 22 28 20 26 20" />
      <polygon fill="currentColor" points="24 20 22 20 22 24 20 24 20 26 24 26 24 20" />
      <rect fill="currentColor" x="19" y="17" width="2" height="4" transform="translate(1 39) rotate(-90)" />
      <rect fill="currentColor" x="6" y="22" width="4" height="4" />
      <path fill="currentColor" d="M14,30H2V18H14ZM4,28h8V20H4Z" />
      <rect fill="currentColor" x="22" y="6" width="4" height="4" />
      <path fill="currentColor" d="M30,14H18V2H30ZM20,12h8V4H20Z" />
      <rect fill="currentColor" x="6" y="6" width="4" height="4" />
      <path fill="currentColor" d="M14,14H2V2H14ZM4,12h8V4H4Z" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        className="cls-1"
        width="32"
        height="32"
      />
    </svg>
  );
}

export default QrSVG;
