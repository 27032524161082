import { useCallback, useEffect, useReducer } from 'preact/hooks';
import _isEmpty from 'lodash-es/isEmpty';
import { logger } from '../../utils/log';
import type { WalletSelector } from '@near-wallet-selector/core';
import useWalletSelector from './use-wallet-selector';
import { useGlobalContext } from '../use-global-context';
import { RowndApiError } from '../use-api';

export interface NearConfig {
  network_id?: string;
  wallet_url?: string;
  creator_account_id?: string;
}

export enum NearStatus {
  Idle,
  Fetching,
  Fetched,
  Error,
  Unsupported,
}

enum NearActionType {
  Fetching,
  Fetched,
  Error,
  Unsupported,
}

export interface NearState {
  status: NearStatus;
  config?: NearConfig;
  error?: Error;
}

let config: NearConfig = {};

export default function useNear(): NearState & {
  selector: WalletSelector | null;
  signingIn: boolean;
} {
  const { state: globalState } = useGlobalContext();

  const initialState: NearState = {
    status: NearStatus.Idle,
  };

  const [nearState, nearDispatch] = useReducer((state: NearState, action: { type: NearActionType; payload?: any }) => {
    switch (action.type) {
      case NearActionType.Fetching:
        return { ...initialState, status: NearStatus.Fetching };
      case NearActionType.Fetched:
        return { ...initialState, status: NearStatus.Fetched, config: action.payload };
      case NearActionType.Error:
        return { ...initialState, status: NearStatus.Error, error: action.payload };
      case NearActionType.Unsupported:
        return { ...initialState, status: NearStatus.Unsupported };
      default:
        return state;
    }
  }, initialState);

  const { selector, signingIn } = useWalletSelector({ nearState });

  const fetchNearConfig = useCallback(async () => {
    // fetchNearConfig uses a connection action to fetch the NEAR config. We cannot call a connection action
    // without an access token.
    // TODO: When we support sign-in with NEAR, this will have to change. connection actions will need to support
    // app key authentication

    // Don't try to fetch the NEAR config if the connection action isn't even enabled
    if (
      !globalState.app.config?.capabilities?.connection_actions?.find((ca) => {
        return ca.action_type === 'near.hub-config';
      })
    ) {
      nearDispatch({ type: NearActionType.Unsupported });
      return;
    }

    if (!globalState.auth.access_token) {
      return;
    }

    // Don't try to fetch the NEAR config again if it is currently being fetched or NEAR is unsupported
    if ([NearStatus.Fetching, NearStatus.Unsupported].includes(nearState.status) || nearState.error) {
      return;
    }

    nearDispatch({ type: NearActionType.Fetching });

    if (!_isEmpty(config)) {
      return nearDispatch({ type: NearActionType.Fetched, payload: config });
    }

    try {
      const result = await window.rownd.connectionAction({
        action_type: 'near.hub-config',
      });

      if (!(result.data?.creator_account_id && result.data?.wallet_url && result.data?.network_id)) {
        return nearDispatch({
          type: NearActionType.Error,
          payload: new Error('Invalid response from near.hub-config'),
        });
      }

      config = {
        network_id: result.data.network_id,
        wallet_url: result.data.wallet_url,
        creator_account_id: result.data.creator_account_id,
      };

      nearDispatch({ type: NearActionType.Fetched, payload: config });
    } catch (err) {
      if ((err as RowndApiError).code === 'E_CONNECTION_NOT_ATTACHED') {
        nearDispatch({ type: NearActionType.Unsupported });
        logger.debug('NEAR is not supported for this app');
      } else {
        nearDispatch({ type: NearActionType.Error, payload: err as Error });
        logger.error('Failed to fetch NEAR config:', err);
      }
    }
  }, [
    globalState.app.config?.capabilities?.connection_actions,
    globalState.auth.access_token,
    nearState.error,
    nearState.status,
  ]);

  useEffect(() => {
    fetchNearConfig();
  }, [fetchNearConfig]);

  return {
    status: nearState.status,
    config: nearState.config,
    error: nearState.error,
    selector,
    signingIn,
  };
}
