import { useCallback, useMemo } from 'preact/hooks';
import { useGlobalContext } from './use-global-context';
import { events, EventType } from '../events';
import { logger } from '../utils/log';

/**
 * Returns a set of callback functions for navigating using window.location. These functions, like assign()
 * or replace(), will wait for preconditions to pass before executing. This makes them safe to call at all
 * times even if operations with side-effects are in progress, like post authentication API requests.
 * @returns A set of callback functions
 */
export default function useSafeLocation() {
  const { state } = useGlobalContext();

  const groupInviteAcceptComplete = useCallback(() => {
    return new Promise<void>((resolve) => {
      if (state.is_accepting_group_invite) {
        events.addEventListener(EventType.GROUP_INVITE_ACCEPT_COMPLETE, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, [state.is_accepting_group_invite]);

  const userDataSaved = useCallback(() => {
    return new Promise<void>((resolve) => {
      if (state.is_saving_user_data){
        events.addEventListener(EventType.USER_DATA_SAVED, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, [state.is_saving_user_data]);

  const postAuthenticationApiRequestComplete = useCallback(() => {
    return new Promise<void>((resolve) => {
      if (state.is_waiting_for_post_authentication_api) {
        events.addEventListener(EventType.POST_AUTHENTICATION_API_REQUEST_COMPLETE, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }, [state.is_waiting_for_post_authentication_api]);

  /** A list of conditions that must be true in order for window.location functions to execute safely  */
  const preconditions = useMemo(() => {
    return [
      {
        name: 'not_accepting_group_invite',
        condition: !window.location.hash.includes('rph_invite') && !state.is_accepting_group_invite,
        waitFn: groupInviteAcceptComplete,
      },
      {
        name: 'not_saving_user_data',
        condition: !state.is_saving_user_data,
        waitFn: userDataSaved,
      },
      {
        name: 'not_waiting_for_post_authentication_api',
        condition: !state.is_waiting_for_post_authentication_api,
        waitFn: postAuthenticationApiRequestComplete,
      },
    ];
  }, [
    groupInviteAcceptComplete,
    postAuthenticationApiRequestComplete,
    state.is_accepting_group_invite,
    state.is_saving_user_data,
    state.is_waiting_for_post_authentication_api,
    userDataSaved,
  ]);

  const callWithPreconditions = useCallback(
    (fn: (...args: any[]) => any, ...args: any[]) => {
      const preconditionFailures = preconditions.filter((precondition) => !precondition.condition);
      const preconditionPromises = preconditionFailures.map((precondition) => precondition.waitFn());

      if (preconditionFailures.length === 0) {
        logger.debug('useWindowLocation: Preconditions passed', {
          fn,
          args,
        });
        return fn.call(window.location, ...args);
      }

      logger.debug('useWindowLocation: Preconditions failed. Waiting...', {
        fn,
        args,
        preconditionFailures,
      });

      (async (fn: (...args: any[]) => any, ...args: any) => {
        await Promise.race([
          await Promise.all(preconditionPromises),
          new Promise<void>((_, reject) => {
            setTimeout(() => {
              logger.error('useWindowLocation: Timed out waiting for preconditions', {
                fn,
                args,
                preconditionFailures,
              });
              reject(new Error('Precondition Timeout'));
            }, 10000);
          }),
        ]);
        logger.debug('useWindowLocation: Preconditions promises resolved', {
          fn,
          args,
        });

        fn.call(window.location, ...args);
      })(fn, args);
    },
    [preconditions],
  );

  const reload = useCallback(() => {
    callWithPreconditions(window.location.reload);
  }, [callWithPreconditions]);

  const assign = useCallback(
    (url: string | URL) => {
      callWithPreconditions(window.location.assign, url);
    },
    [callWithPreconditions],
  );

  const replace = useCallback(
    (url: string | URL) => {
      callWithPreconditions(window.location.replace, url);
    },
    [callWithPreconditions],
  );

  return { reload, assign, replace };
}
