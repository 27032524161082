import TicketSVG from '@/scripts/assets/svg/TicketSVG';
import { useTranslation } from 'preact-i18next';

const LoadingTicket = () => {
  const { t } = useTranslation();
  return (
    <div className="rph-loading-ticket">
      <div className="rph-loading-ticket__svg">
        <TicketSVG />
      </div>
      <div className="rph-loading-ticket__text">
        {t('Loading your tickets')}
        <span className="rph-loading-ticket__dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </div>
    </div>
  );
};

export default LoadingTicket;
