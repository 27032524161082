import preact, { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

const ConnectPasskeyLazy: preact.FunctionalComponent = () => {
  const [LoadedComponent, setLoadedComponent] = useState<undefined | preact.FunctionalComponent>(undefined);

  useEffect(() => {
    import('./ConnectPasskey').then((module) => {
      setLoadedComponent(() => module.default);
    });
  }, []);

  return LoadedComponent ? <LoadedComponent /> : null;
};

export default ConnectPasskeyLazy;
