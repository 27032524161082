import { useCallback, useEffect, useMemo, useRef } from 'preact/hooks';
import { ActionType, useGlobalContext } from '../hooks/use-global-context';
import * as Types from './types';
import useAutomations, { ProcessAutomationsProps } from './hooks';
import useSuccessSignIn from '../hooks/use-success-signin';
import { useLocationContext } from '../Context/LocationContext/LocationContext';
import useRphHash from '../hooks/use-rph-hash';
import { automationLogger, determineEventInfo, isVisible } from './utils';
import { ContentGates } from './ContentGates';

// Content types that use event listeners to run actions.
const eventTriggerTypes = [
  Types.TriggerType.EVENT,
  Types.TriggerType.HTML_SELECTOR_VISIBLE,
  Types.TriggerType.HTML_SELECTOR,
];

const DEBOUNCE_TIME = 1000;

const eventListeners = new Map<string, Map<Document | Element,(e: any) => void>>(); // Map<automationId, Map<handler, eventListenerFunction>>

export default function Automations() {
  const { state, dispatch } = useGlobalContext();
  const {
    invokeAction,
    invokeAutomation,
    hasPostSignInAutomation,
    activeAutomation,
    processRules,
    processTriggers,
    determineAllTriggers,
  } = useAutomations();
  const successSignIn = useSuccessSignIn();
  const { url } = useLocationContext();
  const rphHash = useRphHash();

  // eslint-disable-next-line no-undef
  const timerId = useRef<NodeJS.Timeout | undefined>();

  const automations = useMemo(() => {
    return (state.app.config?.automations || []).filter((automation) => {
      const actions = automation.actions || [];
      return actions?.some((action) => action.type !== Types.ActionType.EDIT_ELEMENTS);
    });
  }, [state.app.config?.automations]);

  const validateScenario = useCallback(
    (
      rules: Types.Rule[],
      automation: Types.Automation,
      triggers: Types.Trigger[],
      props: ProcessAutomationsProps,
    ): boolean => {
      const ruleResult = processRules(rules);

      if (!ruleResult) {
        return false;
      }

      const triggerResult = processTriggers(automation, triggers, props);
      return triggerResult;
    },
    [processRules, processTriggers],
  );

  // evaluates to `true` will cause the automation to run.
  const shouldAutomationRun = useCallback(
    (automation: Types.Automation, props: ProcessAutomationsProps): boolean => {
      const scenarios = automation.scenarios;

      if (!scenarios || scenarios.length === 0) {
        return validateScenario(automation.rules, automation, automation.triggers, props);
      }

      const scenarioResults = scenarios.map((scenario) =>
        validateScenario(scenario.rules, automation, scenario.triggers, props),
      );

      return scenarioResults.some((x) => x);
    },
    [validateScenario],
  );

  // Add event listeners that fire based on the automation's configured triggers. An example of this
  // would be an EVENT automation that runs on a 'click' event on an element (or elements.).
  // Another example would be the HTML_ELEMENT_VISIBLE trigger type that fires on the document
  // 'scroll' event.
  const addContentEventListeners = useCallback(
    (automation: Types.Automation) => {
      const triggers = determineAllTriggers(automation);
      triggers.forEach((trigger) => {
        const triggerType = trigger.type.toUpperCase() as Types.TriggerType;
        if (!eventTriggerTypes.includes(triggerType)) {
          // This content type does not require an event listener.
          automationLogger.debug(`Trigger type '${triggerType}' does not require an event listener`);
          return;
        }

        // Determine info on the event listener(s) to create.
        const eventInfo = determineEventInfo(trigger);
        if (!eventInfo) {
          automationLogger.error('Could not determine event info from trigger', trigger);
          return;
        }

        const eventListenerFunction = (e?: Event) => {
          if (!e) return;

          // Don't continue until the content is visible to the user for a HTML_SELECTOR_VISIBLE
          // content type.
          if (triggerType === Types.TriggerType.HTML_SELECTOR_VISIBLE && !isVisible(eventInfo.elements[0])) {
            return;
          }

          if (!shouldAutomationRun(automation, { type: 'event' })) {
            automationLogger.debug('Automation should not run.');
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          // Perform any remediation actions.
          for (const action of automation.actions || []) {
            invokeAction({
              action: action?.type ?? Types.ActionType.NONE,
              args: action?.args,
              automation,
            });
          }
        };

        let automationEventListeners = eventListeners.get(automation.id);
        if (!automationEventListeners) {
          automationEventListeners = new Map();
          eventListeners.set(automation.id, automationEventListeners);
        }

        // Append the event listener to all handlers.
        eventInfo.handlers.forEach((handler) => {
          // Remove any existing event listeners.
          const existingEventListener = automationEventListeners?.get(handler);
          if (existingEventListener) {
            handler.removeEventListener(eventInfo.name, existingEventListener);
          }

          handler.addEventListener(eventInfo.name, eventListenerFunction);
          automationEventListeners?.set(handler, eventListenerFunction);
        });
      });
    },
    [determineAllTriggers, shouldAutomationRun, invokeAction]
  );

  const processAutomation = useCallback(
    (automation: Types.Automation): null | Types.Automation => {
      if (automation.state !== 'enabled' || (automation.platform && automation.platform !== 'web')) {
        return null;
      }

      // Add event listeners if necessary. (depends on the automation's trigger type)
      addContentEventListeners(automation);

      const willAutomationRun = shouldAutomationRun(automation, {
        type: 'scope',
      });

      // Nothing to do if the automation shouldn't run.
      if (!willAutomationRun) {
        automationLogger.debug('Automation does not need to run', automation);
        return null;
      }

      return automation;
    },
    [addContentEventListeners, shouldAutomationRun],
  );

  const processAutomations = useCallback(() => {
    if (automations.length === 0) return;
    const readyAutomations = automations.filter((automation) => !!processAutomation(automation));

    const haveRphInitError = rphHash?.rph_init?.error;
    if (haveRphInitError) {
      automationLogger.debug(`Not running automations. An error exists in rph_init`);
      return;
    }

    const isRequestSignInAutomationComplete =
      Boolean(activeAutomation) &&
      activeAutomation?.actions?.[0].type === Types.ActionType.REQUIRE_AUTHENTICATION &&
      !processAutomation(activeAutomation);

    if (activeAutomation && !isRequestSignInAutomationComplete) {
      automationLogger.debug(`Don't run automation. '${activeAutomation.id}' is currently running.`);
      return;
    }

    // No automations will be triggered
    if (readyAutomations.length === 0) {
      // Trigger a successful sign in if all post sign in conditions are met
      if (
        state.auth.access_token &&
        state.user.data.user_id &&
        !state.is_post_sign_in_requirements_done &&
        state.nav.current_route !== '/account/manage' &&
        hasPostSignInAutomation
      ) {
        successSignIn({ disablePostSignInAutomation: true });
      }
      return;
    }

    invokeAutomation({
      automation: readyAutomations[0],
      queue: readyAutomations,
    });
  }, [
    activeAutomation,
    hasPostSignInAutomation,
    invokeAutomation,
    processAutomation,
    rphHash?.rph_init?.error,
    automations,
    state.auth.access_token,
    state.is_post_sign_in_requirements_done,
    state.nav.current_route,
    state.user.data.user_id,
    successSignIn,
  ]);

  // Listen for changes to popstate to reset NAV STATE
  useEffect(() => {
    const handleResetNav = () => {
      dispatch({ type: ActionType.RESET_NAV_STATE });
    };
    // Listen for popstate events like back/forward
    window.addEventListener('popstate', handleResetNav);

    return () => {
      window.removeEventListener('popstate', handleResetNav);
    };
  }, [dispatch]);

  // ProcessAutomations immediately when url changes
  const currentUrl = useRef(new URL(location.href));
  useEffect(() => {
    if (currentUrl.current.href !== url.href) {
      currentUrl.current = url;

      clearInterval(timerId.current);
      timerId.current = setInterval(() => processAutomations(), DEBOUNCE_TIME);
      processAutomations();
    }
  }, [url, processAutomations, dispatch]);

  const debounceProcessAutomations = useCallback(() => {
    // state.pages.data === undefined state.pages.data === undefined state.pages.data === undefined state.pages.data === undefined
    if (state.is_initializing || automations.length === 0) {
      return;
    }

    // Process automations first before debounce
    if (!timerId.current) {
      processAutomations();
    }

    clearInterval(timerId.current);
    timerId.current = setInterval(() => {
      processAutomations();
    }, DEBOUNCE_TIME);

    return () => {
      clearInterval(timerId.current);
    }
  }, [state.is_initializing, automations.length, processAutomations]);

  useEffect(() => {
    debounceProcessAutomations();
  }, [debounceProcessAutomations]);

  return (
    <>
      <ContentGates />
    </>
  );
}
