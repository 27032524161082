import { h, Fragment } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { useApi } from './hooks';
import { ActionType, useGlobalContext } from './hooks/use-global-context';
import { logger } from './utils/log';
import { useTranslation } from 'preact-i18next';
import CheckmarkFilled from './assets/svg/CheckmarkFilled';
// import { FieldComponent } from './utils/form';
import pick from 'lodash-es/pick';
import merge from 'lodash-es/merge';
import useUserApi from './hooks/use-user-api';
// import Swoops from './Components/Swoops';
// import classnames from 'classnames';

export enum MergeStep {
  INIT = 'init',
  MANAGE = 'manage',
  WAITING = 'waiting',
  SUCCESS = 'success',
  FAILURE = 'failure',
  ERROR = 'error',
  VERIFY = 'verify',
  DIFFS = 'diffs',
  SUPPORT = 'support',
}

interface DuplicateAppUserResp {
  total_results: number;
  results: DuplicateUser[];
}

interface DuplicateUser {
  data: { [key: string]: any };
}

type MergeInitBody = {
  auth_tokens?: {
    access_token: string;
    refresh_token: string;
  };
};

export default function MergeAccount() {
  const { state, dispatch } = useGlobalContext();
  const { client: api } = useApi();
  const { t } = useTranslation();
  const { saveUserData } = useUserApi();
  const [step, setStep] = useState(MergeStep.INIT);
  const { app, user } = state;
  const schema = state.app.schema;
  const [secondaryUserData, setSecondayUserData] = useState<Record<string, any>>({});
  const [selectedUserData, setSelectedUserData] = useState<Record<string, any>>({});
  const [shouldAutoSubmit, setShouldAutoSubmit] = useState(false);
  const [error, setError] = useState('');
  // const [edits, setEdits] = useState<Record<string, any>>({});
  // const [mergePollStart, setMergePollStart] = useState<number | null>(null);
  const [loginType, setLoginType] = useState<'email' | 'phone' | null>(null);
  const [userIdentifier, setUserIdentifier] = useState('');
  // const [requestId, setRequestId] = useState<string | null>(null);

  // Set the app schema
  // const appSchema = useMemo(() => {
  //   const selectedSchema: AppSchema = {};
  //   logger.debug("Set the app schema");
  //   if (schema) {
  //     Object.keys(schema).forEach((field: string) => {
  //       selectedSchema[field] = schema[field];
  //     });
  //   }
  //   return selectedSchema;
  // }, [schema]);

  // Set the fields to show
  const appUserVisibleFields = useMemo(() => {
    let visibleFields: string[] = [];
    logger.debug('Set the visible fields to show');
    if (schema) {
      visibleFields = Object.entries(schema)
        .filter(([, field]) => field.user_visible)
        .map(([key]) => key);
    }
    return visibleFields;
  }, [schema]);

  // Set the app verification fields
  const appVerificationFields = useMemo(() => {
    let verificationFields: string[] = [];
    logger.debug("Set the app's verification fields");
    if (app?.user_verification_fields) {
      verificationFields = app?.user_verification_fields;
    } else if (app?.user_verification_field) {
      verificationFields = [app?.user_verification_field];
    }
    return verificationFields;
  }, [app]);

  // Set the duplicate user data
  const secondaryUserDataFields = useMemo(() => {
    logger.debug('Set the duplicate user data');
    return pick(secondaryUserData, appUserVisibleFields);
  }, [appUserVisibleFields, secondaryUserData]);

  // Set the current user fields
  const primaryUserDataFields = useMemo(() => {
    logger.debug('Set the current user data');
    return pick(user.data, appUserVisibleFields);
  }, [appUserVisibleFields, user.data]);

  // Set the duplicate user verification data
  const secondaryUserVerificationFields = useMemo(() => {
    logger.debug('Set the duplicate verification data');
    return pick(secondaryUserData, appVerificationFields);
  }, [appVerificationFields, secondaryUserData]);

  // Set the current user verification fields
  const primaryUserVerificationFields = useMemo(() => {
    logger.debug('Set the current verification data');
    return pick(user.data, appVerificationFields);
  }, [appVerificationFields, user.data]);

  // Set the user verfication diffs
  const diffUserIdentifiers = useMemo(() => {
    const diffFields: Record<string, any> = {};
    if (
      Object.entries(secondaryUserVerificationFields).length > 0 &&
      Object.entries(primaryUserVerificationFields).length > 0
    ) {
      for (const [key, field] of Object.entries(secondaryUserVerificationFields)) {
        if (
          !primaryUserVerificationFields[key] ||
          (!!primaryUserVerificationFields[key] && primaryUserVerificationFields[key] !== field)
        ) {
          diffFields[key] = field;
        }
      }
      logger.debug('setting diffs verification', diffFields);
    }
    return diffFields;
  }, [secondaryUserVerificationFields, primaryUserVerificationFields]);

  // Set the profile diffs
  const diffUserProfileFields = useMemo(() => {
    const diffFields: string[] = [];
    if (Object.entries(secondaryUserDataFields).length > 0 && Object.entries(primaryUserDataFields).length > 0) {
      for (const [key, field] of Object.entries(secondaryUserDataFields)) {
        if (!!primaryUserDataFields[key] && !!secondaryUserDataFields[key] && primaryUserDataFields[key] !== field) {
          diffFields.push(key);
        }
      }
      logger.debug('setting diffs user profile', diffFields);
    }
    return diffFields;
  }, [secondaryUserDataFields, primaryUserDataFields]);

  // const handleInputChange = (fieldName: string, value: any) => {
  //   setEdits({
  //     ...edits,
  //     [fieldName]: value
  //   });
  // }

  // const handleSelectChange = (fieldName: string, value: any) => {
  //   setEdits({
  //     ...edits,
  //     [fieldName]: app?.schema?.[fieldName]?.type === 'boolean' ? (value === 'true') : value
  //   });
  // }

  const signOut = () => {
    dispatch({ type: ActionType.SIGN_OUT });
    closeModal();
  };

  const closeModal = () => {
    dispatch({ type: ActionType.SET_CONTAINER_VISIBLE, payload: { isVisible: false } });
  };

  // This kicks off the start of merging
  const initMerge = () => {
    setStep(MergeStep.DIFFS);
    getDuplicateAppUserData();
  };

  const getDuplicateAppUserData = useCallback(async () => {
    try {
      const resp: DuplicateAppUserResp = await api
        .get(`me/applications/${state.app.id}/data/merge`, {
          headers: {
            Authorization: `Bearer ${state.auth.access_token}`,
          },
        })
        .json();
      if (resp?.results) {
        Object.entries(resp.results).forEach(([, value]) => {
          const userData = value?.data;
          setSecondayUserData(userData);
        });
      }
    } catch (err: any) {
      logger.error(err);
      setStep(MergeStep.ERROR);
      setError(err.message);
    }
  }, [api, state.app.id, state.auth.access_token]);

  const mergeAppUserData = useCallback(
    async (evt?: Event) => {
      if (step === MergeStep.WAITING) {
        return;
      }

      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      setStep(MergeStep.WAITING);

      try {
        logger.debug('selected data', selectedUserData);

        const payload = {
          app_user_id: secondaryUserData.user_id,
          app_user_data: selectedUserData,
        };

        const resp: MergeInitBody = await api
          .post(`me/applications/${app.id}/data/merge`, {
            authenticated: true,
            json: payload,
          })
          .json();

        if (resp.auth_tokens) {
          logger.debug('new tokens from merging');
          dispatch({
            type: ActionType.LOGIN_SUCCESS,
            payload: {
              ...resp.auth_tokens,
              app_id: app.id,
            },
          });
        }

        saveUserData(payload.app_user_data);

        setTimeout(() => setStep(MergeStep.SUCCESS), 250);
      } catch (err: any) {
        logger.error(err);

        // If this application isn't allowed to auto-merge then show the contact support
        if (err?.response?.status === 401) {
          setStep(MergeStep.SUPPORT);
          return;
        }
        setStep(MergeStep.ERROR);
        setError(err.message);
      }
    },
    [api, app.id, dispatch, saveUserData, secondaryUserData.user_id, selectedUserData, step],
  );

  // useEffect(() => {
  //   logger.debug("MERGE STEP IS", step)
  // }, [step]);

  // const verifyUserIdentifier = useCallback(async (evt?: Event) => {
  //   if (isSubmitting) {
  //     return;
  //   }

  //   if (evt) {
  //     evt.preventDefault();
  //     evt.stopPropagation();
  //   }

  //   const payload = {
  //     [loginType === 'phone' ? 'phone' : 'email']: userIdentifier,
  //     return_url: window.location.href,
  //     always_send_verification_message: true,
  //   };

  //   // Set the user_id to the application's default user id format if it is defined
  //   if (app.config?.default_user_id_format) {
  //     payload.user_id = app.config?.default_user_id_format;
  //   }

  //   // Submission
  //   try {
  //     setIsSubmitting(true);

  //     const resp: LoginInitBody = await api.post('hub/auth/init', {
  //       headers: {
  //         'x-rownd-app-key': config?.appKey,
  //       },
  //       json: payload,
  //     }).json();

  //     setRequestId(resp.challenge_id);
  //     setStep(MergeStep.VERIFY);
  //     setMergePollStart(Date.now());

  //   } catch (err: any) {
  //     logger.error(err);

  //     if (err?.response?.status === 400) {
  //       setStep(MergeStep.INIT);
  //       return;
  //     }

  //     setStep(MergeStep.ERROR);
  //     setError(err.message);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // }, [api, app.config?.default_user_id_format, config?.appKey, isSubmitting, loginType, userIdentifier]);

  useEffect(() => {
    // Both profiles are loaded and we've compared the fields. Now where to send them?
    if (
      step === MergeStep.DIFFS &&
      Object.entries(primaryUserDataFields).length > 0 &&
      Object.entries(secondaryUserDataFields).length > 0
    ) {
      // If they don't have any differences then go straight to merging.
      if (Object.entries(diffUserIdentifiers).length == 0 && diffUserProfileFields.length == 0) {
        setSelectedUserData(merge(primaryUserDataFields, secondaryUserDataFields));
        setShouldAutoSubmit(true);
        // But if they have a different user identifer then they need to verify it
      } else if (Object.entries(diffUserIdentifiers).length > 0) {
        const [field, value] = Object.entries(diffUserIdentifiers)[0];
        const loginType = field === 'phone_number' ? 'phone' : 'email';
        setLoginType(loginType);
        setUserIdentifier(value);
        setStep(MergeStep.VERIFY);
        // If they have different profile field values then they need to choose
      } else if (diffUserProfileFields.length > 0) {
        setStep(MergeStep.MANAGE);
      }
    }
  }, [diffUserIdentifiers, diffUserProfileFields, primaryUserDataFields, secondaryUserDataFields, step]);

  useEffect(() => {
    if (shouldAutoSubmit) {
      logger.debug('autosubmitting', 'auto submit merge!');
      mergeAppUserData();
      setShouldAutoSubmit(false);
    }
  }, [mergeAppUserData, shouldAutoSubmit]);

  // submits call to verifyUserIdentifier to merge
  // useEffect(() => {

  //   if (step === MergeStep.VERIFY) {
  //     return;
  //   }

  //   if (loginType && userIdentifier) {
  //     logger.debug("submitting verify");
  //     // verifyUserIdentifier();
  //   }
  // }, [loginType, step, userIdentifier]);

  // const pollLoginStatus = useCallback(async () => {
  //   try {
  //     const resp: LoginSuccessBody = await api.post(`hub/auth/challenge_status`, {
  //       headers: {
  //         'x-rownd-app-key': config?.appKey,
  //       },
  //       json: {
  //         challenge_id: requestId,
  //         [loginType || 'email']: userIdentifier,
  //       }
  //     }).json();

  //     let err: any;
  //     switch (resp.status) {
  //       case 'pending':
  //         err = new Error('Login challenge is still pending');
  //         err.code = LoginVerificationStatus.PENDING;
  //         throw err;

  //       case 'expired':
  //         err = new Error('Login challenge is still pending');
  //         err.code = LoginVerificationStatus.PENDING;
  //         throw err;

  //       case 'verified':
  //         break;

  //       default:
  //         err = new Error('Unknown login challenge status');
  //         throw err;
  //     }

  //     initMerge();

  //     // setStep(MergeStep.SUCCESS);
  //     // TODO: make it easier for someone to find this tab by flashing the title, etc
  //     window.focus();

  //   } catch (err: any) {
  //     logger.log('login poll error', err);

  //     // If network error, try again up to 1 minute, else fail
  //     if (!err.code && differenceInMinutes(Date.now(), mergePollStart!) > 0) {
  //       setStep(MergeStep.ERROR);
  //       setError(t('Network error, please try again later.'));
  //       return;
  //     }

  //     // If request expires, then fail (assume > 6 mins is a failure/expiration)
  //     if ((err.status || err.code) && differenceInMinutes(Date.now(), mergePollStart!) > 6) {
  //       setStep(MergeStep.ERROR);
  //       setError(t('The sign in request expired.'));
  //       return;
  //     }

  //     if (err.status && err.status >= 400) {
  //       setStep(MergeStep.FAILURE);
  //       setError(t('Sign in unsuccessful.'));
  //     }
  //   }
  // }, [api, config?.appKey, loginType, mergePollStart, requestId, t, userIdentifier]);

  // Polling when a login flow is in progress
  // useInterval(pollLoginStatus, step === MergeStep.WAITING ? 5000 : null);

  useEffect(() => {
    if (step === MergeStep.SUCCESS) {
      setTimeout(() => dispatch({ type: ActionType.SET_CONTAINER_VISIBLE, payload: { isVisible: false } }), 250);
    }
  }, [dispatch, step]);

  // function setFieldValue(field: string, value: any) {
  //   const selectedFields: Record<string, any> = {};
  //   selectedFields[field] = value;
  //   setSelectedUserData(merge(selectedUserData, selectedFields));
  // }

  return (
    <div className="rph-merge rph-modal">
      {step === MergeStep.INIT && (
        <>
          <h2>{t('Existing account found')}</h2>

          <p>{t('We found an existing account')}</p>
          <div style="text-align: center !important;">
            <button onClick={initMerge}>{t('Merge Accounts')}</button>
            <button className="rph-button-link" onClick={signOut}>
              {t('Cancel')}
            </button>
          </div>
        </>
      )}

      {/* {step === MergeStep.MANAGE && (
        <> */}
      {/* <h2>{t('Merge Accounts')}</h2>
            <div className="rph-manage">

              <div className="rph-manage-fields">
                <p>{t('Primary Account')}</p>
                {Object.keys(primaryUserDataFields).map((field: string, index: number) => {
                  const schemaField: SchemaField = appSchema[field];
                  const display_name = schemaField.display_name;
                  return (
                    <div className="rph-manage-field-item" key={`requestFields${field}`}>
                      {display_name}
                      <div>{FieldComponent({
                        field: primaryUserDataFields[field],
                        disableReadOnly: false,
                        fieldName: field,
                        index,
                        state,
                        user,
                        edits,
                        handleInputChange,
                        handleSelectChange
                      })}
                      </div>
                      <label className="rph-switch">
                        <input type="checkbox" name={field} value={primaryUserDataFields[field]}
                          onChange={(evt): void => setFieldValue(field, (evt.target as HTMLInputElement).value)} />
                        <span className="rph-slider rph-slider-round" />
                      </label>
                    </div>

                  )
                })}
              </div>
              <div className="rph-manage-fields">
                <p>{t('Secondary Account')}</p>
                {Object.keys(secondaryUserDataFields).map((field: string, index: number) => {
                  const schemaField: SchemaField = appSchema[field];
                  const display_name = schemaField.display_name;
                  return (
                    <div className="rph-manage-field-item" key={`requestFields${field}`}>
                      {display_name}
                      <div>{FieldComponent({
                        field: secondaryUserDataFields[field],
                        disableReadOnly: false,
                        fieldName: field,
                        index,
                        state,
                        user,
                        edits,
                        handleInputChange,
                        handleSelectChange
                      })}
                      </div>
                      <label className="rph-switch">
                        <input type="checkbox" name={field} value={secondaryUserDataFields[field]}
                          onChange={(evt): void => setFieldValue(field, (evt.target as HTMLInputElement).value)} />
                        <span className="rph-slider rph-slider-round" />
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>

            <button onClick={mergeAppUserData}>

              {t('Merge')}
            </button> */}

      {/* </>
      )} */}

      {step === MergeStep.SUCCESS && (
        <>
          <div className="rph-success-indicator">
            <CheckmarkFilled />
          </div>
        </>
      )}

      {(step === MergeStep.WAITING || step === MergeStep.DIFFS) && (
        <>
          <div className="rph-centered">
            <span className="rph-loading-circle rph-loading-circle-large" />
          </div>
        </>
      )}

      {/* {step === MergeStep.VERIFY && (
        <> */}

      {/* {visualSwoops && state.config?.displayContext !== 'mobile_app' && <Swoops />}
            <h2>{t('Thanks! Verify your {{identifier}} to finish', { identifier: loginType === 'phone' ? t('phone number') : t('email') })}</h2>
            <p>{t('To continue with the merge, click the link in that message')}</p>
            <div className='rph-merge-waiting-image-container'>
              <div
                className='rph-merge-waiting-image-background'
                style={`background-color: ${computed_color_mode === 'dark' ? "black" : primaryColor} !important`}
              />
              <div className={classnames('rph-merge-waiting-image', { 'rph-merge-phone': loginType === 'phone' })} />
            </div> */}
      {/* </>
      )} */}

      {step === MergeStep.FAILURE && (
        <>
          <p>{t("Whoops, that didn't work!")}</p>
        </>
      )}

      {step === MergeStep.ERROR && (
        <>
          <p>{t('An error occurred while merging.')}</p>
          {error && <p>{error}</p>}
        </>
      )}
      {(step === MergeStep.SUPPORT || step === MergeStep.VERIFY || step === MergeStep.MANAGE) && (
        <>
          <h2>{t('Existing account')}</h2>
          <p>
            {t(
              'The {{identifier}} you entered is associated with an existing account. If you own the existing account ' +
                'and would like to merge these accounts, please ',
              { identifier: loginType === 'phone' ? t('phone number') : t('email') },
            )}
            <a href="mailto:support@rownd.io?subject=Merging Accounts">{t('contact support')}</a>
            {t(' to ensure a smooth merge.')}
          </p>
          <div style="text-align: center !important;">
            <button onClick={closeModal}>{t('Close')}</button>
          </div>
        </>
      )}
    </div>
  );
}
