export enum FeatureFlagTypes {
  OpenEmailInbox = 'openEmailInbox',
  CanReceiveEventMessages = 'can_receive_event_messages',
}

export const isFeatureEnabled = ({
  storage,
  feature,
}: {
  storage: null | Record<string, string>;
  feature: string;
}): boolean => {
  if (!storage || !storage?.['rph_feature_flags']) return false;
  const featureFlags = JSON?.parse(storage?.['rph_feature_flags']) || [];

  return featureFlags.some(
    (obj: { name?: string; enabled?: string }) => obj?.name === feature && obj?.enabled === 'true',
  );
};
