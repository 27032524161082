import { useGlobalContext } from './use-global-context';
import { useDimensionContext } from './use-dimensions-context';
import { useContext, useMemo } from 'preact/hooks';
import { BottomSheetContext } from '../Components/BottomSheet/BottomSheet';

const useBottomSheet = () => {
  const { state } = useGlobalContext();
  const { width, height } = useDimensionContext();
  const context = useContext(BottomSheetContext);

  const enabled = useMemo(
    () =>
      state.config?.displayContext !== 'mobile_app' &&
      width < 450 &&
      height < 1025 &&
      state.config?.bottomSheet !== false &&
      state.app.config?.bottom_sheet?.enabled !== false,
    [height, state, width]
  );
  return {
    isBottomSheetEnabled: enabled,
    context,
  };
};

export default useBottomSheet;
