import { logger } from './log';

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    logger.log('isInIframe: true');
    return true;
  }
}
