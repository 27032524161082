import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { h } from 'preact';
import Button from '../../../Common/Button/Button';
import { useTranslation } from 'preact-i18next';
import { NearWallet } from '../../../Profile/ProfileWallets/ProfileWallets';
import { useRoute, useNear } from '@/scripts/hooks';
import SwoopsAnimation from '../../../Common/Swoops/SwoopsAnimation';
import Input from '@/scripts/Components/Common/Input/Input';
import { NearStatus } from '@/scripts/hooks/use-near/use-near';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';

export enum NearWalletDetailsStep {
  Overview,
  CreateNamedWallet,
  CreateNamedWalletSuccess,
  CreateNamedWalledLoading,
}

export default function NearWalletDetails() {
  const { t } = useTranslation();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { state } = useGlobalContext();
  const wallet: NearWallet = state.nav.options?.wallet;
  const visualSwoops =
    typeof state.app?.config?.hub?.customizations?.visual_swoops === 'boolean'
      ? state.app?.config?.hub.customizations?.visual_swoops
      : true;

  const { navTo } = useRoute();
  const nearState = useNear();

  return (
    <div className="rph-wallet-details rph-modal">
      {visualSwoops && state.config?.displayContext !== 'mobile_app' && !isBottomSheetEnabled && <SwoopsAnimation />}

      <div className="rph-wallet-details__title">{t('Your wallet')}</div>
      <div className="rph-wallet-details__subtitle">{t('View your wallet assets or create a new named wallet')}</div>
      <Input
        customClass="rph-wallet-details__wallet-id__input"
        readOnly={true}
        value={wallet.account_id}
        copyable={true}
        type="text"
      />
      <Button
        type={'primary'}
        handleOnClick={() => {
          switch (wallet.wallet_id) {
            case 'my-near-wallet' || 'near-wallet': {
              const walletUrl = nearState.config?.wallet_url ? nearState.config.wallet_url : 'https://wallet.near.org';
              if (wallet.private_key) {
                window.open(`${walletUrl}/auto-import-secret-key#${wallet.account_id}/${wallet.private_key}`, '_blank');
              } else {
                window.open(walletUrl, '_blank');
              }
              break;
            }
            default: {
              // TODO: Implement launching other wallets. Remove hide prop.
              break;
            }
          }
        }}
        label={t('View my wallet')}
        isLoading={nearState.status === NearStatus.Fetching}
        hide={!['my-near-wallet', 'near-wallet'].includes(wallet.wallet_id)}
      />
      <Button
        customClass="rph-create-named-wallet"
        type={'tertiary'}
        handleOnClick={() => {
          navTo('/account/near/createNamedAccount', 'NearWalletDetails', {
            use_modal: state.nav.options?.use_modal,
            wallet,
          });
        }}
        label={t('Create a named wallet')}
        hide={state.user.data.near_named_account_id || state.user.data.near_personal_account_id}
      />
      <Button
        type={'text'}
        customClass="rph-wallet-details__footer-button"
        handleOnClick={() => {
          navTo('/account/manage', 'NearWalletDetails', {
            use_modal: state.nav.options?.use_modal,
          });
        }}
        label={t('Go back')}
      />
    </div>
  );
}
