import { HubStorage } from "./types";

function computeStorageKey(key: string) {
  return key.startsWith('rph_') ? key : `rph_${key}`;
}

export function localStore(): HubStorage {
  const localStorage = window.localStorage;
  const store = {} as HubStorage;

  store.getItem = (key: string) => {
    return localStorage.getItem(computeStorageKey(key));
  };

  store.setItem = (key: string, value: any) => {
    return Promise.resolve(localStorage.setItem(computeStorageKey(key), value));
  };

  store.removeItem = (key: string) => {
    return Promise.resolve(localStorage.removeItem(computeStorageKey(key)));
  };

  return store;
}
