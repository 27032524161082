import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { FieldComponent } from '@/scripts/utils/form';
import { ProfileTypes, profileTypesTranslation } from '@/scripts/Components/Profile/Profile';
import { PROFILE_TITLE_CLASS } from '../Profile';
import { StateUpdater, useMemo } from 'preact/hooks';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';

interface PersonalInformationProps {
  edits: Record<string, any>;
  errors: Record<string, string>;
  handleInputChange: (fieldName: string, value: any) => void;
  handleSelectChange: (fieldName: string, value: any) => void;
  verificationFields: string[];
  dataToShow: Record<string, any>;
  autoFocusField?: string;
  setFocused: StateUpdater<string | null>;
}

export function PersonalInformation({
  errors,
  edits,
  handleInputChange,
  handleSelectChange,
  verificationFields,
  dataToShow,
  autoFocusField,
  setFocused
}: PersonalInformationProps) {
  const { state } = useGlobalContext();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { app, user, is_loading_user_data: isLoadingUserData, config } = state;
  const { schema } = app;

  const personalInformationEnabled = app.config?.hub?.profile?.personal_information?.enabled !== false;

  const isMobileAppView = config?.displayContext === 'mobile_app';

  const isPersonalInfoEmpty = useMemo(() => {
    if (!schema) return true;
    return !Object.entries(schema).some(([key]) => {
      const isVerificationField = verificationFields.includes(key);
      const value = dataToShow[key];

      return !isVerificationField && !!value;
    });
  }, [schema, verificationFields, dataToShow]);

  if (!state.use_modal || !personalInformationEnabled) return null;

  return (
    <>
      <div className="rph-account-fields-form__divider" />
      {!isMobileAppView && !isBottomSheetEnabled && (
        <div className={`rph-account-fields-form__title ${PROFILE_TITLE_CLASS}${ProfileTypes.Personal}`}>
          {profileTypesTranslation.personal.title}
        </div>
      )}
      {schema &&
        Object.entries(schema).map(([key, field], index) => {
          const isVerificationField = verificationFields.includes(key);
          const displayDefaultField = isPersonalInfoEmpty && ['first_name', 'last_name'].includes(key);

          if (!displayDefaultField) {
            if (
              !field.user_visible ||
              !Object.keys(dataToShow).includes(key) ||
              dataToShow[key] == null ||
              (isLoadingUserData && Object.keys(state.user?.data || {}).length === 0) ||
              isVerificationField
            ) {
              return null;
            }
          }

          return (
            <div className="rph-field" key={`rph-field-${key}`}>
              <label className="rph-field-name">{field.display_name}</label>
              <div className="rph-field-value">
                <div className="rph-field-value-inner">
                  {FieldComponent({
                    fieldName: key,
                    field,
                    index,
                    focus: autoFocusField === key,
                    state,
                    user,
                    edits,
                    handleInputChange,
                    handleSelectChange,
                    handleInputBlur: () => setFocused(null),
                    handleInputFocus: (fieldName) => setFocused(fieldName),
                    customClass: 'rph-account-fields__request-field',
                  })}
                  {errors[key] && <div className="rph-account-fields__request-field__error-text">{errors[key]}</div>}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
