import { h, JSX } from 'preact';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { Trans, useTranslation } from 'preact-i18next';
import CssCheckmarkAnimation from '../../Common/CssAnimation/CssCheckmarkAnimation/CssCheckmarkAnimation';
import SwoopsAnimation from '../../Common/Swoops/SwoopsAnimation';
import { useMemo } from 'preact/hooks';
import Loading from '../../Common/Loading/Loading';
import ContainerInner from '@/scripts/ContainerInner';
import { RowndHomePage } from '@/scripts/utils/links';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';

type SignInSuccessProps = {
  completingSignIn?: boolean;
  isExistingUser?: boolean;
  includeModal?: boolean;
  message?: string;
};

export function SignInSuccess({
  completingSignIn,
  isExistingUser,
  includeModal,
  message,
}: SignInSuccessProps): JSX.Element {
  const { t } = useTranslation();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { state } = useGlobalContext();
  const visualSwoops = state?.app?.config?.hub?.customizations?.visual_swoops !== false;
  const successMessage = useMemo(() => {
    if (message) return message;
    return completingSignIn ? t('Completing your sign-in...') : t('Signing you in...');
  }, [completingSignIn, message, t]);

  const Content = useMemo((): h.JSX.Element => {
    return (
      <>
        <>
          {completingSignIn ? <Loading /> : <CssCheckmarkAnimation />}
          {isExistingUser && (
            <div className="rph-login-success__text rph-login-success__account-found">
              {t('Existing account found!')}
            </div>
          )}
          <div className="rph-login-success__text">{successMessage}</div>
        </>
        {state.config?.displayContext !== 'mobile_app' && (
          <a className="rph-login-success__footer" target="_blank" rel="noopener noreferrer" href={RowndHomePage}>
            <Trans
              i18nKey="Powered by Rownd"
              components={{
                a: (
                  <a href={RowndHomePage} target="_blank" rel="noreferrer">
                    Rownd
                  </a>
                ),
              }}
            />
          </a>
        )}
      </>
    );
  }, [completingSignIn, isExistingUser, state.config?.displayContext, successMessage, t]);

  if (includeModal) {
    return (
      <ContainerInner>
        {() => (
          <div className="rph-login">
            {visualSwoops && state.config?.displayContext !== 'mobile_app' && !isBottomSheetEnabled && (
              <SwoopsAnimation />
            )}
            {Content}
          </div>
        )}
      </ContainerInner>
    );
  }

  return Content;
}
