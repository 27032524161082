import { h, JSX } from 'preact';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { Trans, useTranslation } from 'preact-i18next';
import SwoopsAnimation from '../../Common/Swoops/SwoopsAnimation';
import { useMemo } from 'preact/hooks';
import Loading from '../../Common/Loading/Loading';
import { RowndHomePage } from '@/scripts/utils/links';

type SignInLoadingProps = {
  includeModal?: boolean;
  title?: string;
  showTitle?: boolean;
};

export function SignInLoading({ includeModal, title, showTitle = true }: SignInLoadingProps): JSX.Element {
  const { t } = useTranslation();
  const { state } = useGlobalContext();
  const visualSwoops = state?.app?.config?.hub?.customizations?.visual_swoops !== false;

  const Content = useMemo((): h.JSX.Element => {
    return (
      <>
        <>
          <Loading />
          {showTitle && (
            <div className="rph-login-success__text">{title ? title : t('Waiting for authentication...')}</div>
          )}
        </>
        {state.config?.displayContext !== 'mobile_app' && (
          <a className="rph-login-success__footer" target="_blank" rel="noopener noreferrer" href={RowndHomePage}>
            <Trans
              i18nKey="Powered by Rownd"
              components={{
                a: (
                  <a href={RowndHomePage} target="_blank" rel="noreferrer">
                    Rownd
                  </a>
                ),
              }}
            />
          </a>
        )}
      </>
    );
  }, [state.config?.displayContext, t, title, showTitle]);

  if (includeModal) {
    return (
      <div className="rph-container-inner">
        <div className="rph-login">
          {visualSwoops && state.config?.displayContext !== 'mobile_app' && <SwoopsAnimation />}
          {Content}
        </div>
      </div>
    );
  }

  return Content;
}