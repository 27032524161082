import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Options } from 'qr-code-styling';
import { useGlobalContext } from '../hooks/use-global-context';

export interface QrCodeProps {
  data: string;
  overrides?: Options;
}

export default function QrCode() {
  const { state } = useGlobalContext();
  const { nav } = state;

  const [qrCode, setQrCode] = useState<string>('');
  useEffect(() => {
    (async () => {
      try {
        const { generateQrCode } = await import('./generator');
        const qrCode = await generateQrCode(
          nav.options.data,
          {
            format: 'svg',
            encoding: 'base64',
            color_mode: state.computed_color_mode,
          },
          nav.options.overrides || {},
        );
        setQrCode(qrCode as string);
      } catch (e) {
        console.error('Failed to generate QR code:', e);
      }
    })();
  }, [nav.options.data, nav.options.overrides, state.computed_color_mode]);

  return <>{qrCode && <img src={`data:image/svg+xml;base64,${qrCode}`} style="width:auto; height: 100%;" />}</>;
}
