import classNames from 'classnames';
import { h } from 'preact';
import LazyLoadedBottomSheet from './Components/BottomSheet/BottomSheetLoader';
import useBottomSheet from './hooks/use-bottom-sheet';

interface ContainerInnerProps {
  children: (props: { closeModal?: () => void }) => h.JSX.Element;
  customClass?: string;
  id?: string;
}

export default function ContainerInner({ children, customClass, id }: ContainerInnerProps) {
  const { isBottomSheetEnabled } = useBottomSheet();

  if (isBottomSheetEnabled) {
    return (
      <LazyLoadedBottomSheet customClass={customClass} id={id}>
        {({ closeModal }) => {
          return children({ closeModal });
        }}
      </LazyLoadedBottomSheet>
    );
  }

  return (
    <div
      className={classNames(`rph-container-inner`, {
        [`${customClass}`]: customClass,
      })}
      id={id}
    >
      {children({})}
    </div>
  );
}
