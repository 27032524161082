import classnames from 'classnames';
import { h } from 'preact';
import { useTranslation } from 'preact-i18next';

interface UnauthorizedWebOriginProps {
  customClass?: string;
}

export default function UnauthorizedWebOrigins({ customClass }: UnauthorizedWebOriginProps) {
  const { t } = useTranslation();
  return (
    <div
      className={classnames('rph-unauthorized-web-origins', {
        [`${customClass}`]: customClass,
      })}
    >
      <div className="rph-unauthorized-web-origins__icon" />
      <div className="rph-unauthorized-web-origins__text">
        {t("This domain is not an authorized web origin. Please contact this app's support to resolve this issue.")}
      </div>
    </div>
  );
}
