import jwt_decode, { JwtPayload } from 'jwt-decode';
import { logger } from './log';

export enum TokenClaims {
  IsVerifiedUser = 'https://auth.rownd.io/is_verified_user',
  AuthLevel = 'https://auth.rownd.io/auth_level',
  UserId = 'https://auth.rownd.io/app_user_id',
}

export function isTokenExpired(accessToken: string): boolean {
  if (!accessToken) {
    logger.debug('no access token found');
    return true;
  }

  let tokenPayload: JwtPayload;
  try {
    tokenPayload = jwt_decode(accessToken);
  } catch (err) {
    logger.debug('error decoding access token, so assuming it is invalid/expired', err);
    return true;
  }

  // Shave 5 minutes off the token expiration to account for clock skew
  const tokenExpiration = (tokenPayload.exp! - 5 * 60) * 1000;
  if (tokenExpiration > Date.now()) {
    return false; // shouldn't be expired
  }

  logger.debug('access token is expired');
  return true;
}
