import { createContext, FunctionalComponent } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

const LocationContext = createContext<undefined | { url: URL }>(undefined);

export const useLocationContext = () => {
  const context = useContext(LocationContext);

  if (context === undefined) {
    throw new Error('useLocationContext must be used within a LocationContext Provider');
  }

  return context;
};

const LocationProvider: FunctionalComponent = ({ children }) => {
  const [url, setUrl] = useState<URL>(new URL(location.href));

  // Listen for changes to URL
  useEffect(() => {
    // Add proxy for window.history.pushState for SPA's
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        const newURL = new URL(argArray[2], window.location.href);
        setUrl(newURL);
        return target.apply(thisArg, argArray as any);
      },
    });

    // Override the replaceState function
    const originalReplaceState = history.replaceState;
    history.replaceState = function(state, title, url) {
        if (!url) return;

        const newURL = new URL(url, window.location.href);
        setUrl(newURL);

        originalReplaceState.apply(this, arguments as any);
        const replaceStateEvent = new Event('replacestate');
        window.dispatchEvent(replaceStateEvent);
    };

    const handleSetUrl = () => {
      setUrl(new URL(location.href));
    };

    // Listen for popstate events like back/forward
    window.addEventListener('popstate', handleSetUrl);

    return () => {
      window.removeEventListener('popstate', handleSetUrl);
    };
  }, []);

  return (
    <LocationContext.Provider
      value={{
        url,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
