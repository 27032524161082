import { useEffect, useState, useCallback, useRef } from 'preact/hooks';
import { MessageType, sendMessageToApp } from '../utils/mobile-app';
import { useGlobalContext } from './use-global-context';

function useDynamicMobileHeight() {
  const { state } = useGlobalContext();
  const [didSetInitialHeight, setDidInitialHeight] = useState(false);
  const heightOffset = 130;
  const modalElement: HTMLElement | null = document?.querySelector('.rph-modal');

  // eslint-disable-next-line no-undef
  const timerId = useRef<NodeJS.Timeout | undefined>();

  const resizeBottomSheet = useCallback((height: string) => {
    sendMessageToApp({ type: MessageType.HUB_RESIZE, payload: { height } });
  }, []);

  const debounceResize = useCallback(
    (height: string) => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }

      timerId.current = setTimeout(() => {
        resizeBottomSheet(height);
      }, 300);
    },
    [resizeBottomSheet],
  );

  useEffect(() => {
    if (!state.app.config || state.config?.displayContext !== 'mobile_app' || !modalElement || didSetInitialHeight) {
      return;
    }
    setDidInitialHeight(true);
    resizeBottomSheet((modalElement.scrollHeight + heightOffset).toString());
  }, [didSetInitialHeight, modalElement, resizeBottomSheet, state.app.config, state.config?.displayContext]);

  useEffect(() => {
    if (!state.app.config || state.config?.displayContext !== 'mobile_app' || !modalElement) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const newHeight = entries[0].target.scrollHeight;
      if (newHeight) {
        debounceResize((newHeight + heightOffset).toString());
      }
    });

    resizeObserver.observe(modalElement);

    return () => {
      resizeObserver.unobserve(modalElement);
      resizeObserver.disconnect();
    };
  }, [debounceResize, modalElement, state.app.config, state.config?.displayContext]);

  return null;
}

export default useDynamicMobileHeight;
