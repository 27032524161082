import { h, ComponentChildren } from 'preact';
import classNames from 'classnames';

interface PickerGroupProps {
  horizontal?: boolean;
  children?: ComponentChildren;
  customClass?: string;
  label?: string;
  error?: string;
}

export default function PickerGroup({ horizontal, children, customClass, label, error }: PickerGroupProps) {
  return (
    <div
      className={classNames('rph-picker-group', {
        [`${customClass}`]: customClass,
      })}
    >
      {label && <label className={'rph-picker-group__label'}>{label}</label>}
      <div
        className={classNames('rph-picker-group__children', {
          'rph-picker-group__children-horizontal': horizontal,
        })}
      >
        {children}
      </div>
      {error && <label className={'rph-picker-group__error'}>{error}</label>}
    </div>
  );
}
