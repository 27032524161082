import { useMemo } from 'preact/hooks';
import { usePasskeys, useRoute } from '../hooks';
import * as Types from './types';
import { ActionType, useGlobalContext } from '../hooks/use-global-context';
import useSafeLocation from '../hooks/use-window-location';
import { automationLogger } from './utils';

export default function useActors() {
  const { navTo } = useRoute();
  const passkeys = usePasskeys();
  const { dispatch } = useGlobalContext();
  const safeLocation = useSafeLocation();
  return useMemo((): Record<Types.ActionType, Types.ActionFunction> => {
    return {
      [Types.ActionType.REQUIRE_AUTHENTICATION]: (args = {}) => {
        automationLogger.debug('Require authentication');
        const defaultOpts: any = {
          include_user_data: true,
        };

        // Use the external API to invoke requestSignIn()
        window.rownd.requestSignIn({
          ...defaultOpts,
          ...args,
        });
      },

      [Types.ActionType.REQUIRE_VERIFICATION]: () => {
        automationLogger.debug('Require verification');
        // TODO: implement
      },

      [Types.ActionType.PROMPT_FOR_INPUT]: () => {
        automationLogger.debug('Prompt for input');
        // TODO: implement
      },

      [Types.ActionType.REDIRECT]: (args = {}) => {
        automationLogger.debug('Redirect');
        safeLocation.assign(args?.location);
      },

      [Types.ActionType.PROMPT_FOR_PASSKEY]: (args = {}) => {
        automationLogger.debug('Prompt for passkey');
        passkeys.promptForPasskeyRegistrationIfNeeded();
      },

      [Types.ActionType.NONE]: () => {
        // no-op
        automationLogger.debug('No action');
      },

      [Types.ActionType.SIGN_OUT]: () => {
        automationLogger.debug('Sign out');
        dispatch({ type: ActionType.SIGN_OUT });
      },

      [Types.ActionType.COLLECT_INFORMATION]: (args = {}) => {
        navTo('/account/collectInformation', 'automation-collect-info', {
          ...args,
          use_modal: true,
        });
      },

      [Types.ActionType.LEGAL_OPT_IN]: (args = {}) => {
        navTo('/account/legalOptIn', 'automation-legal-agree', {
          ...args,
          use_modal: true,
        });
      },

      [Types.ActionType.OPEN_PROFILE]: () => {
        automationLogger.debug('Open profile');
        navTo('/account/manage', void 0, { use_modal: true, is_container_visible: true });
      },
      [Types.ActionType.EDIT_ELEMENTS]: () => {
        automationLogger.debug('Trigger EDIT_ELEMENTS');
      },
    };
  }, [dispatch, navTo, passkeys, safeLocation]);
}
