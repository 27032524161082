export const BOTTOM_SHEET = {
  TRANSFORM_DURATION: 300,
  MAX_SCROLLABLE_HEIGHT: 55,
  MAX_NO_SCROLLABLE_HEIGHT: 24,
  STYLE_ID: 'rph-bottom-sheet-style',
  // https://stackoverflow.com/questions/69261011/disable-pull-to-refresh-in-ios-15-safari
  STYLE_CSS: `
        body{
            overscroll-behavior-y: none;
            overflow: hidden !important;
            overflow-y: hidden !important;
            position: fixed !important;
            left: 0px !important;
            right: 0px !important;
        }

        html {
            overflow: hidden !important;
        }
        
        html {
            touch-action: pan-x pan-y;
            min-height: 100%; /* prevent pinch-zoom at page bottom */
        }

        /* prevent pull-to-refresh for Safari 16+ */
        @media screen and (pointer: coarse) {
            @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)  {
                html {
                    min-height: 100.3% !important;
                    overscroll-behavior-y: none !important;
                }
            }
        }

        /* prevent pull-to-refresh for Safari 9~15 */
        @media screen and (pointer: coarse) {
            @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))  {
                html {
                    height: 100% !important;
                    overflow: hidden !important;
                }
                body {
                    margin: 0px !important;
                    max-height: 100% !important;
                    overflow: auto !important;
                    -webkit-overflow-scrolling: touch !important;
                }
            }
        }
    `,
};
