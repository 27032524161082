import { SignInMethods } from './hooks/use-global-context';
import { logger } from './utils/log';

export const REDACTED = 'ROWND_REDACTED';

// determine accessible text color for a given background color
export function determineTextColorForBgColor(bgColor: string, isDarkMode: boolean): string {
  let red, green, blue, alpha;
  if (bgColor.includes('rgb')) {
    // rgb or rgba color
    const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
    const rgbaRegex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/;
    let match;
    if ((match = bgColor.match(rgbRegex))) {
      red = parseInt(match[1], 10);
      green = parseInt(match[2], 10);
      blue = parseInt(match[3], 10);
    } else if ((match = bgColor.match(rgbaRegex))) {
      red = parseInt(match[1], 10);
      green = parseInt(match[2], 10);
      blue = parseInt(match[3], 10);
      alpha = parseFloat(match[4]);
    } else {
      logger.warn(`Invalid button color string: ${bgColor}`);
      return bgColor;
    }
  } else {
    // Hex color
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    red = parseInt(color.substring(0, 2), 16); // hexToR
    green = parseInt(color.substring(2, 4), 16); // hexToG
    blue = parseInt(color.substring(4, 6), 16); // hexToB
  }

  if (alpha && alpha < 0.3) {
    return isDarkMode ? '#fff' : '#000';
  }

  return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? '#000' : '#fff';
}

// lighten or darken a hex, rgb(r,g,b), or rgba(r,g,b,a) color
export function adjustColor(color: string, amount: number): string {
  if (color.includes('rgb')) {
    const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
    const rgbaRegex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/;
    let match;
    let red, green, blue, alpha;
    if ((match = color.match(rgbRegex))) {
      red = parseInt(match[1], 10);
      green = parseInt(match[2], 10);
      blue = parseInt(match[3], 10);
      alpha = 1;
    } else if ((match = color.match(rgbaRegex))) {
      red = parseInt(match[1], 10);
      green = parseInt(match[2], 10);
      blue = parseInt(match[3], 10);
      alpha = parseFloat(match[4]);
    } else {
      logger.warn(`Invalid color string: ${color}`);
      return color;
    }

    // Darken the color by adding the specified amount from each RGB component
    const newRed = Math.max(0, red + amount);
    const newGreen = Math.max(0, green + amount);
    const newBlue = Math.max(0, blue + amount);

    // Return the new RGBA color string
    return `rgba(${newRed}, ${newGreen}, ${newBlue}, ${alpha})`;
  }

  return `#${color
    .replace(/^#/, '')
    .replace(/../g, (color) =>
      `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2),
    )}`;
}

export function detectColorMode(): 'light' | 'dark' {
  if (window?.matchMedia('(prefers-color-scheme: dark)')?.matches) {
    return 'dark';
  }

  return 'light';
}

export enum UserAgentType {
  mobile = 'mobile',
  desktop = 'desktop',
}

const mobileUserAgents = ['iphone', 'ipad', 'ipod', 'ios', 'android', 'iemobile', 'bb10', 'mobile safari'];

export function getUserAgentType() {
  let userAgentType: UserAgentType = UserAgentType.desktop;
  if (mobileUserAgents.some((ua) => navigator.userAgent.toLowerCase().includes(ua.toLowerCase()))) {
    userAgentType = UserAgentType.mobile;
  }

  return userAgentType;
}

export function isIosDevice(): boolean {
  return ['iphone', 'ipad', 'ipod', 'ios'].some((ua) => navigator.userAgent.toLowerCase().includes(ua));
}

const signInMethodsPriority: Record<keyof SignInMethods, number> = {
  passkeys: 1,
  google: getUserAgentType() === 'desktop' ? 2 : isIosDevice() ? 3 : 1,
  apple: getUserAgentType() === 'desktop' ? 3 : isIosDevice() ? 2 : 9,
  oauth2: 4,
  email: 5,
  phone: isIosDevice() ? 8 : 7,
  crypto_wallet: isIosDevice() ? 10 : 9,
  anonymous: 20,
};

export function determineSignInMethodBasedOnPriority({
  method,
  newMethod,
}: {
  method?: keyof SignInMethods;
  newMethod: keyof SignInMethods;
}): keyof SignInMethods {
  if (!method) {
    return newMethod;
  }
  const methodPriority = signInMethodsPriority[method];
  const newMethodPriority = signInMethodsPriority[newMethod];

  return newMethodPriority < methodPriority ? newMethod : method;
}

export const debounce = (fn: (...params: any[]) => any, n: number, immed = false) => {
  let timer: any | undefined;
  return function (this: any, ...args: any[]) {
    if (timer === undefined && immed) {
      fn.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), n);
    return timer;
  };
};

export function validEmail(email: string): boolean {
  const emailAtIdx = email?.indexOf('@');
  const emailSuffixIdx = email?.substring(emailAtIdx).indexOf('.');
  if (emailAtIdx > 0 && emailSuffixIdx > 0 && email?.substring(emailAtIdx + emailSuffixIdx).length >= 3) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return false;
}

export function isDate(dateStr: string) {
  return !isNaN(new Date(dateStr).getDate());
}

export function removeUndefinedKeysDeep(obj: Record<string, any>): Record<string, any> {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeUndefinedKeysDeep(obj[key]);
    } else if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

export const isPageActive = (): boolean => !document.hidden;
