import WarningSVG from '@/scripts/assets/svg/WarningSVG';
import { logger } from '@/scripts/utils/log';
import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import Button from '../../Common/Button/Button';
import RenewSVG from '@/scripts/assets/svg/RenewSVG';
import { useTranslation } from 'preact-i18next';

interface ErrorTicketProps {
  error: string;
  refetchTickets: () => void;
}

const ErrorTicket: FunctionalComponent<ErrorTicketProps> = ({ error, refetchTickets }) => {
  const { t } = useTranslation();
  // Log the error to the console
  useEffect(() => {
    logger.error(error);
  }, [error]);

  return (
    <div className="rph-error-ticket">
      <WarningSVG />
      <div className="rph-error-ticket__title">{t('Unable to retrieve tickets.')}</div>
      <div className="rph-error-ticket__subtitle">
        {t('Something went wrong. Try refreshing the page or check back later.')}
      </div>
      <Button type="tertiary" customClass="rph-error-ticket__button" handleOnClick={refetchTickets}>
        {t('Refresh')}
        <RenewSVG />
      </Button>
    </div>
  );
};

export default ErrorTicket;
