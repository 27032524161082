import { PageCaptureJsonPath, PageCaptureJsonPathAnd, PageCaptureJsonPathOr } from '@/scripts/hooks/use-global-context';
import { RuleEvaluator, RuleEvaluatorOptions } from '../evaluators';
import * as pagesUtils from './utils';

export const evaluatePage: RuleEvaluator = (opts: RuleEvaluatorOptions) => {
  const { rule, scope } = opts;
  const pages = scope.pages || [];

  if (pages.length === 0) {
    return [false, 'Pages are empty'];
  }

  const pageId = rule.value;
  const page = pages.find((page) => page.id === pageId);

  if (!page) {
    return [false, 'automation value (page_id) does not match existing pages'];
  }

  const latestWebCapture = page.captures.filter((capture) => capture.platform === 'web')[0];

  const jsonPath = latestWebCapture.rule_set.json_path;

  const result = evaluateJsonPath(jsonPath);

  return [
    result,
    result
      ? `Condition passed: data.${rule.attribute} ${rule.condition} ${result}`
      : `Condition failed: '${rule.entity_type}.${rule.attribute}' ${
          rule.condition
        } ${rule.value?.toString()} (was ${result})`,
  ];
};

export function isJsonPathOr(jsonPath: PageCaptureJsonPath): jsonPath is PageCaptureJsonPathOr {
  return (<PageCaptureJsonPathOr>jsonPath).$or !== undefined;
}

export function isJsonPathAnd(jsonPath: PageCaptureJsonPath): jsonPath is PageCaptureJsonPathAnd {
  return (<PageCaptureJsonPathAnd>jsonPath).$and !== undefined;
}

const evaluateJsonPath = (jsonPath: PageCaptureJsonPath): boolean => {
  if (isJsonPathOr(jsonPath)) {
    return evaluateJsonPathOr(jsonPath);
  }

  if (isJsonPathAnd(jsonPath)) {
    return evaluateJsonPathAnd(jsonPath);
  }

  const operand = jsonPath.operand; // example: $.location.href
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const operation = jsonPath.operation; // TODO: Handle different operations than EQUALS
  const value = jsonPath.value;

  if (!value) {
    return false;
  }

  const screen = {
    location: window.location,
  };

  const jsonPathValue = pagesUtils.jsonPath(screen, operand);

  // jsonPath.operation: EQUALS
  // TODO: Handle different operations and different types of values rather than just string
  return pagesUtils.wildcardMatch(jsonPathValue, value);
};

const evaluateJsonPathOr = (jsonPath: PageCaptureJsonPathOr): boolean => {
  return jsonPath.$or.some((jsonPath) => evaluateJsonPath(jsonPath));
};

const evaluateJsonPathAnd = (jsonPath: PageCaptureJsonPathAnd): boolean => {
  return jsonPath.$and.every((jsonPath) => evaluateJsonPath(jsonPath));
};
