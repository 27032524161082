import classNames from 'classnames';
import { h } from 'preact';
import { GlobalState, SchemaField, User } from '../hooks/use-global-context';
import { MutableRef, useEffect, useRef, useState } from 'preact/hooks';

type FormFieldProps = {
  id?: string;
  name: string;
  value?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  options?:
    | [
        {
          value: string;
          label: string;
        },
      ]
    | null;
  onInput?: (evt: Event) => void;
  onChange?: (evt: Event) => void;
};

export const determineInputTypeFromField = (field: SchemaField, fieldName: string) => {
  // Special case for email and phone
  if (fieldName === 'email') {
    return 'email';
  }

  if (fieldName === 'phone_number') {
    return 'tel';
  }

  switch (field.type) {
    case 'string':
      return 'text';
    case 'number':
      return 'number';
    case 'boolean':
      return 'select';
    default:
      return 'text';
  }
};

export const isReadOnly = ({ field, state }: { field: SchemaField; state: GlobalState }) => {
  return field.required ? true : field.owned_by === 'app' || !state.auth.access_token;
};

interface GetFieldComponentProps {
  fieldName: string;
  field: SchemaField;
  index: number;
  focus?: boolean;
  state: GlobalState;
  edits: Record<string, any>;
  user: User;
  errors?: Record<string, any>;
  handleSelectChange: (fieldName: string, value: any) => void;
  handleInputChange: (fieldName: string, value: any) => void;
  handleInputBlur?: (fieldName: string, value: any) => void;
  handleInputFocus?: (fieldName: string, value: any) => void;
  customClass?: string;
  disableReadOnly?: boolean;
  errorText?: string | Element;
}

export function FieldComponent({
  fieldName,
  field,
  index,
  focus,
  state,
  user,
  edits,
  errors,
  handleSelectChange,
  handleInputChange,
  handleInputBlur,
  handleInputFocus,
  disableReadOnly,
  customClass,
}: GetFieldComponentProps) {
  const fieldType = determineInputTypeFromField(field, fieldName);
  const readOnly = isReadOnly({ field, state }) && !disableReadOnly;
  const inputType = determineInputTypeFromField(field, fieldName);
  const userData = { ...user.data, ...user.verified_data };

  const elementRef = useRef<HTMLInputElement | HTMLSelectElement>();
  const [hasFocusedOnField, setHasFocusedOnField] = useState(false);

  // Auto-focus on the form field
  useEffect(() => {
    if (!focus || !elementRef.current || hasFocusedOnField) return;
    elementRef.current.focus();
    setHasFocusedOnField(true);
  }, [elementRef, focus, hasFocusedOnField]);

  if (fieldType === 'select') {
    const selectProps: h.JSX.HTMLAttributes<HTMLSelectElement> = {
      id: `rph-input-${fieldName}-${index}`,
      name: fieldName,
      autoFocus: focus,
      className:
        customClass &&
        classNames(`${customClass} ${customClass}__${inputType}`, {
          [`${customClass}__error`]: errors && errors[`${fieldName}`],
        }),
      type: determineInputTypeFromField(field, fieldName),
      value: String(edits[fieldName] !== void 0 ? edits[fieldName] : userData[fieldName]),
      ref: elementRef as MutableRef<HTMLSelectElement>,
      onChange: (e: Event): void => handleSelectChange(fieldName, (e.target as HTMLInputElement).value),
      onBlur: (e: Event): void => handleInputBlur && handleInputBlur(fieldName, (e.target as HTMLInputElement).value),
      onFocus: (e: Event): void =>
        handleInputFocus && handleInputFocus(fieldName, (e.target as HTMLInputElement).value),
    };
    return (
      <select {...selectProps} readOnly={readOnly}>
        {getSelectFieldOptions(field)}
      </select>
    );
  }

  let userDataValue = ['image', 'document'].includes(field.type)
    ? userData[fieldName]?.filename
    : userData[fieldName];

  if (field.type === 'object') {
    try {
      userDataValue = JSON.stringify(userDataValue);
    } catch { /* no-op */ }
  }

  const value = edits[fieldName] !== void 0 ? edits[fieldName] : userDataValue;
  const inputProps: h.JSX.HTMLAttributes<HTMLInputElement> = {
    id: `rph-input-${fieldName}-${index}`,
    placeholder: 'Enter here',
    className:
      customClass &&
      classNames(`${customClass} ${customClass}__${inputType}`, {
        [`${customClass}__error`]: !value && errors && errors[`${fieldName}`],
        'rph-input-populated': value !== undefined || value !== '',
      }),
    name: fieldName,
    autoFocus: focus,
    type: inputType,
    step: inputType === 'number' ? '0.0001' : void 0,
    value,
    ref: elementRef as MutableRef<HTMLInputElement>,
    onInput: (e: Event): void => handleInputChange(fieldName, (e.target as HTMLInputElement).value),
    onBlur: (e: Event): void => handleInputBlur && handleInputBlur(fieldName, (e.target as HTMLInputElement).value),
    onFocus: (e: Event): void => handleInputFocus && handleInputFocus(fieldName, (e.target as HTMLInputElement).value),
  };
  return <input {...inputProps} readOnly={readOnly} />;
}

export const getSelectFieldOptions = (field: SchemaField) => {
  switch (field.type) {
    case 'boolean':
      return [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ].map(({ value, label }, index) => {
        return (
          <option key={`${field.display_name}_option_${index}`} value={value}>
            {label}
          </option>
        );
      });
    default:
      return null;
  }
};

export function renderField({
  id,
  label = '',
  name,
  type = 'input',
  value,
  placeholder,
  options = null,
  onInput,
  onChange,
}: FormFieldProps) {
  const dom = [];

  if (!id) {
    id = `rph-input-${name}`;
  }

  if (label) {
    dom.push(<label htmlFor={id}>{label}</label>);
  }

  switch (type) {
    case 'select':
      dom.push(
        <select id={id} name={name} onChange={onChange} value={value || options?.[0]?.value}>
          {options?.map((opt, index) => (
            <option {...{ selected: index === 0 }} value={opt.value} key={opt.value}>
              {' '}
              {opt.label}{' '}
            </option>
          ))}
        </select>,
      );
      break;

    case 'input':
    default:
      dom.push(<input type="text" id={id} name={name} value={value} placeholder={placeholder} onInput={onInput} />);
  }

  return dom;
}
