import { useMemo } from 'preact/hooks';
import useRphHash from './use-rph-hash';
import type { RphOidc } from '@/scripts/hooks/use-rph-hash';
import { useGlobalContext } from './use-global-context';

interface Oidc {
  is_interaction_in_progress: boolean;
  config?: RphOidc & { computed_logo_uri?: string | null };
}

export default function useOidc(): Oidc {
  const rphHash = useRphHash();
  const { state } = useGlobalContext();

  const computedLogoUri = useMemo(() => {
    if (state.computed_color_mode === 'dark') {
      return rphHash?.rph_oidc?.client?.logo_dark_mode_uri ?? rphHash?.rph_oidc?.client?.logo_uri ?? null;
    }
    return rphHash?.rph_oidc?.client?.logo_uri ?? null;
  }, [rphHash?.rph_oidc?.client, state.computed_color_mode]);

  return {
    is_interaction_in_progress: !!rphHash?.rph_oidc,
    config: {
      ...(rphHash?.rph_oidc || {}),
      client: {
        ...(rphHash?.rph_oidc?.client || {}),
        computed_logo_uri: computedLogoUri,
      },
    },
  };
}
