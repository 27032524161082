import { useCallback } from 'preact/hooks';
import useAutomations from '../Automations/hooks';
import { ActionType, useGlobalContext } from './use-global-context';
import { EventType, events } from '../events';

export default function useHandleClose() {
  const { dispatch, state } = useGlobalContext();
  const { invokeAutomation, nextAutomation } = useAutomations();

  const handleClose = useCallback(() => {
    if (state.automations?.active_automation) {
      events.dispatch(EventType.AUTOMATION_COMPLETED, {
        id: state.automations.active_automation,
        name: state.app.config?.automations?.find(
          (automation) => automation.id === state.automations?.active_automation,
        )?.name,
      });
    }

    if (nextAutomation) {
      invokeAutomation({
        automation: nextAutomation,
      });
      return;
    }

    dispatch({
      type: ActionType.SET_CONTAINER_VISIBLE,
      payload: {
        isVisible: false,
      },
    });
  }, [dispatch, invokeAutomation, nextAutomation, state.app.config?.automations, state.automations?.active_automation]);

  return handleClose;
}
