import { h, JSX } from 'preact';
import Input from '@/scripts/Components/Common/Input/Input';
import Button from '@/scripts/Components/Common/Button/Button';
import OidcClientHeader from '../OidcClientHeader/OidcClientHeader';
import { useOidc } from '@/scripts/hooks';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import { useTranslation } from 'preact-i18next';
import { useMemo } from 'preact/hooks';

export default function OidcConfirmAccount({
  handleCancel,
}: {
  handleCancel: () => void;
}) {
  const { state } = useGlobalContext();
  const { t } = useTranslation();
  const oidc = useOidc();

  const confirmAccountEndpoint = oidc.config?.confirm_account_endpoint ?? '';

  const currentUserDisplayName = useMemo(() => {
    return state.user.data.email || state.user.data.phone_number || state.user.data.user_id || 'undefined';
  }, [state.user.data]);

  return (
    <div className="rph-oidc-confirm-account">
      <OidcClientHeader />
      <h2 className="rph-oidc-confirm-account__title">{t('Would you like to continue as')}</h2>
      <Input customClass="rph-oidc-confirm-account__input" type="text" value={currentUserDisplayName} />
      <p className="rph-oidc-confirm-account__helper">{t('You are already signed in as this account')}</p>
      <form autocomplete="off" action={confirmAccountEndpoint} method="post">
        <Button type="primary" isSubmit={true} label="Continue" />
        <Button
          type="tertiary"
          customClass="rph-oidc-confirm-account__cancel"
          label={t('Use another account')}
          handleOnClick={handleCancel}
        />
      </form>
    </div>
  );
}
