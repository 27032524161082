import { useCallback, useMemo } from 'preact/hooks';
import { ActionType, useGlobalContext } from './use-global-context';
import useRoute from './use-route';
import useReCaptcha from './use-recaptcha';
import { logger } from '../utils/log';
import useApi from './use-api';
import { LoginInitBody } from '../Login';
import jwt_decode from 'jwt-decode';
import { TokenClaims } from '../utils/token';

export const useInstantUser = () => {
  const { state, dispatch } = useGlobalContext();
  const { navTo } = useRoute();
  const { execute } = useReCaptcha();
  const { client: api } = useApi();

  const instantUserAccessToken = useMemo(
    () => (state.auth.auth_level === 'instant' && state.auth.access_token ? state.auth.access_token : undefined),
    [state.auth.access_token, state.auth.auth_level]
  );

  const instantUserId = useMemo(() => {
    if (state.auth.auth_level !== 'instant' || !state.auth.access_token) {
      return undefined;
    }
    let decodedAccessToken: Record<string, string>;
    try {
      decodedAccessToken = jwt_decode(state.auth.access_token);
    } catch (err) {
      logger.warn('Failed to decode access token', err);
      return undefined;
    }

    return decodedAccessToken?.[TokenClaims.UserId]
  }, [state.auth.access_token, state.auth.auth_level]);

  const isInstantUsersEnabled = useMemo(
    () => Boolean(state.app.config?.hub?.auth?.instant_user?.enabled),
    [state.app.config?.hub?.auth?.instant_user?.enabled]
  );

  const instantUsersFallback = useCallback(() => {
    if (isInstantUsersEnabled) {
      navTo('/account/login', 'instant_users_fallback', {
        use_modal: true,
        prevent_closing: true, // If something is wrong creating an instant user, we need to force sign-in
      });
    }
  }, [isInstantUsersEnabled, navTo]);

  const createInstantUser = useCallback(async () => {
    try {
      dispatch({ type: ActionType.SET_INSTANT_USER_IS_INITIALIZING, payload: true });
      const token = await execute();

      const resp: LoginInitBody = await api
        .post('hub/auth/instant', {
          headers: {
            'x-rownd-app-key': state.config?.appKey,
          },
          json: {
            recaptcha_challenge_response: token,
          },
        })
        .json();

      dispatch({
        type: ActionType.LOGIN_SUCCESS,
        payload: {
          ...resp.auth_tokens,
          app_id: state.app.id,
        },
      });
    } catch (err) {
      logger.error(err);
      return { error: err as Error };
    } finally {
      dispatch({ type: ActionType.SET_INSTANT_USER_IS_INITIALIZING, payload: false });
    }
  }, [api, dispatch, execute, state.app.id, state.config?.appKey]);

  return {
    isInstantUsersEnabled,
    instantUsersFallback,
    createInstantUser,
    instantUserAccessToken,
    instantUserId,
  };
};
