import _isEmpty from 'lodash-es/isEmpty';
import { ActionType, useGlobalContext } from '@/scripts/hooks/use-global-context';
import { h, Fragment } from 'preact';
import Button from '../../../Common/Button/Button';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useTranslation } from 'preact-i18next';
import LoadingModal from '../../../Common/Modals/LoadingModal/LoadingModal';
import FailureModal from '../../../Common/Modals/FailureModal/FailureModal';
import { useRoute, useNear } from '@/scripts/hooks';
import SwoopsAnimation from '../../../Common/Swoops/SwoopsAnimation';
import Input from '@/scripts/Components/Common/Input/Input';
import Loading from '@/scripts/Components/Common/Loading/Loading';
import { NearStatus } from '@/scripts/hooks/use-near';
import { RowndApiError } from '@/scripts/hooks/use-api';
import { WalletTypes } from '@/scripts/Components/Profile/ProfileWallets/ProfileWallets';
import useUserApi from '@/scripts/hooks/use-user-api';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';

export enum CreateNamedAccountStep {
  PromptForName,
  Creating,
  Success,
  Failure,
}

export default function CreateNamedAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { isBottomSheetEnabled } = useBottomSheet();
  const { state, dispatch } = useGlobalContext();
  const isMobileView = state.config?.displayContext === 'mobile_app';
  const visualSwoops =
    typeof state.app?.config?.hub?.customizations?.visual_swoops === 'boolean'
      ? state.app?.config?.hub.customizations?.visual_swoops
      : true;
  const [step, setStep] = useState<CreateNamedAccountStep>(
    state.nav.options?.step || CreateNamedAccountStep.PromptForName,
  );
  const [error, setError] = useState<Error | null>(null);
  const [accountName, setAccountName] = useState<string>('');
  const { navTo } = useRoute();
  const nearState = useNear();
  const { saveUserData } = useUserApi();

  useEffect(() => {
    setIsLoading(false);
  }, [step]);

  const errorMessage = useMemo<string>(() => {
    if (!error) {
      return '';
    }
    if (typeof error === 'string') {
      return error;
    }
    if ((error as RowndApiError).code === 'E_NEAR_ACCOUNT_NAME_NOT_UNIQUE') {
      return t('Please provide a unique name');
    }
    return (error as RowndApiError).message;
  }, [error, t]);

  const handleFormSubmit = async (e?: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const creatorAccountId = nearState.config?.creator_account_id;
    if (!creatorAccountId) {
      return setError(t('Missing creator account ID'));
    }

    if (!accountName) {
      return setError(t('Please enter a wallet name'));
    }

    setAccountName(accountName);

    // Make sure the account name we submit ends with .rownd.testnet|mainnet
    let normalizedAccountNmae = accountName;
    if (!accountName.endsWith(`.${creatorAccountId}`)) {
      normalizedAccountNmae = `${accountName}.${creatorAccountId}`;
    }

    try {
      setError(null);
      setStep(CreateNamedAccountStep.Creating);
      const response = await window.rownd.connectionAction({
        action_type: 'near.create-named-account',
        params: {
          name: normalizedAccountNmae,
        },
        timeout: 20000, // 20s
      });

      // The connection action should respond with the wallet info. We can immediately update the
      // local user data, however this will trigger a PATCh call to the API Server to update it
      // server-side. This is superfluous, but should not be harmful. It's faster to set the data
      // now while we already have it rather than making another GET call to retrieve it.
      if (response.result === 'success' && !_isEmpty(response.data)) {
        saveUserData(response.data);
      }

      setStep(CreateNamedAccountStep.Success);
    } catch (err) {
      setStep(CreateNamedAccountStep.PromptForName);
      setError(err as Error);
    }
  };

  return (
    <div className="rph-near-create-account rph-modal">
      {visualSwoops && !isMobileView && !isBottomSheetEnabled && <SwoopsAnimation />}
      {nearState.status === NearStatus.Fetching ? (
        <Loading />
      ) : (
        <>
          {step === CreateNamedAccountStep.Failure && (
            <FailureModal
              title={t('Create wallet failed')}
              subtitle={t('A wallet could not be created. Please try again.')}
              onHandleClick={() => handleFormSubmit()}
              isLoading={isLoading}
              buttonLabel={t('Retry')}
            />
          )}

          {step === CreateNamedAccountStep.Success && (
            <>
              <div className="rph-near-create-account__title">{t('Your wallet')}</div>
              <div className="rph-near-create-account__subtitle">{t('Your wallet was created successfully!')}</div>
              <Button
                customClass="rph-near-copy-private-key"
                type={'tertiary'}
                label={t('Copy private key')}
                handleOnClick={() => {
                  navigator.clipboard.writeText(state.user?.data?.near_named_private_key);
                }}
              />
              <Button
                customClass="rph-near-create-account__footer-button"
                type={'text'}
                label={t('Go back')}
                handleOnClick={() => {
                  navTo('/account/manage', 'NearWalletDetails', {
                    use_modal: state.nav.options?.use_modal,
                  });
                }}
              />
            </>
          )}

          {step === CreateNamedAccountStep.Creating && (
            <LoadingModal
              customClass="rph-near-create-account-loading"
              title={t('Generating wallet')}
              subtitle={t("Please wait while we create your wallet. Don't close the app or this page")}
              swoops={false}
            />
          )}

          {step === CreateNamedAccountStep.PromptForName && (
            <>
              <div className="rph-near-create-account__title">{t('Name your wallet')}</div>
              <div className="rph-near-create-account__subtitle">
                {t(
                  'Create a unique name for your new wallet. You can use this name to view assets in your wallet and perform transactions',
                )}
              </div>

              <form onSubmit={handleFormSubmit}>
                <div className="rph-near-account-name">
                  <label className="rph-near-account-name__label" for="account">
                    {t('Wallet name')}
                  </label>
                  <div className="rph-near-account-name__value">
                    <Input
                      customClass="rph-near-account-name__input"
                      type="text"
                      name="account"
                      placeholder="my-wallet-name"
                      // textAddOn={nearState.config?.creator_account_id}
                      value={accountName}
                      onChange={(e: Event) => {
                        // Clear previous error on change
                        if (error) {
                          setError(null);
                        }
                        setAccountName((e.target as HTMLInputElement).value);
                      }}
                    />
                  </div>
                  <div className="rph-field-error">{errorMessage || nearState.error?.message}</div>
                </div>

                <Button
                  type={'primary'}
                  isSubmit={true}
                  label={t('Submit')}
                  isLoading={isLoading}
                  disabled={!!nearState.error}
                />
              </form>

              <Button
                type={'text'}
                customClass="rph-near-create-account__footer-button"
                handleOnClick={() => {
                  if (state.nav.options?.wallet) {
                    navTo('/account/walletDetails', 'CreateNamedWallet', {
                      use_modal: state.nav.options?.use_modal,
                      type: WalletTypes.Near,
                      wallet: state.nav.options?.wallet,
                    });
                  } else {
                    navTo('/account/manage', 'CreateNamedWallet', {
                      use_modal: state.nav.options?.use_modal,
                    });
                  }
                }}
                label={t('Go back')}
                isLoading={isLoading}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
