import { h } from 'preact';
import { AppSchema, SchemaField, useGlobalContext } from './hooks/use-global-context';
import { useEffect, useState } from 'preact/hooks';
import { FieldComponent } from './utils/form';
import { ExternalApiSpec } from './ExternalApi';
import { logger } from './utils/log';
import useHandleClose from './hooks/use-handle-close';
import { useRoute } from './hooks';
import { useTranslation } from 'preact-i18next';

export default function RequestFields() {
  const { state } = useGlobalContext();
  const schema = state.app.schema;
  const { user, app } = state;
  const options = state.nav?.options;
  const fields: string[] = options?.fields;
  const buttonText: string | undefined = options?.buttonText;
  const questionText: string | undefined = options?.questionText;

  const { setPopupRoute } = useRoute();
  const { t } = useTranslation();
  const handleClose = useHandleClose();
  const [selectedSchemaFields, setSelectedSchemaFields] = useState<AppSchema>({});
  const [edits, setEdits] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  useEffect(() => {
    const selectedSchemas: AppSchema = {};
    if (schema) {
      Object.keys(schema).forEach((field: string) => {
        if (fields.slice(0, 2).includes(field)) {
          // Only use the first two fields requested
          selectedSchemas[field] = schema[field];
        }
      });
    }
    setSelectedSchemaFields(selectedSchemas);
  }, [fields, schema]);

  const closeModal = () => {
    handleClose();
  };

  const submitValues = async (formDataValues: Record<string, any>) => {
    if (!isLoading && containsAllValues(formDataValues)) {
      setIsLoading(true);
      const rownd = (window as any).rownd as ExternalApiSpec;
      try {
        await rownd.user.set(formDataValues);
        closeModal();
      } catch (err) {
        logger.log({ err });
        setSubmitError('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const containsAllValues = (formDataValues: Record<string, any>) => {
    return Object.keys(selectedSchemaFields).every((value) => formDataValues[`${value}`]);
  };

  const handleInputChange = (fieldName: string, value: any) => {
    setSubmitError('');
    setEdits({
      ...edits,
      [fieldName]: value,
    });
  };

  const handleSelectChange = (fieldName: string, value: any) => {
    setSubmitError('');
    setEdits({
      ...edits,
      [fieldName]: app?.schema?.[fieldName]?.type === 'boolean' ? value === 'true' : value,
    });
  };

  const handleFormSubmit = (e: Event) => {
    const formData = new FormData(e.target as HTMLFormElement);
    if (!e || !e.target) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const formDataValues: Record<string, any> = {};

    formData.forEach((value, key) => {
      formDataValues[key] = value;
      const fieldType = app?.schema?.[key]?.type;
      let typedValue: any;

      if (!value) {
        setErrors((prevError) => ({ ...prevError, ...{ [`${key}`]: 'required' } }));
        return;
      }

      switch (fieldType) {
        case 'boolean':
          typedValue = value === 'true';
          break;
        case 'number':
          typedValue = Number(value as string);
          break;
        case 'object':
          try {
            typedValue = JSON.parse(value as string);
          } catch (err) {
            typedValue = (value as string).split(',');
          }
          break;
        default:
          typedValue = value;
          break;
      }
      formDataValues[`${key}`] = typedValue;
    });
    submitValues(formDataValues);
  };

  return (
    <div className="rph-request-fields rph-modal">
      <h2 className="rph-request-fields__title">
        {questionText ? questionText : 'Please provide more information to continue'}
      </h2>
      <form onSubmit={handleFormSubmit}>
        {Object.keys(selectedSchemaFields).map((field: string, index: number) => {
          const schemaField: SchemaField = selectedSchemaFields[field];
          const display_name = schemaField.display_name;

          return (
            <div key={`requestFields${display_name}`}>
              {display_name}
              <div>
                {FieldComponent({
                  field: schemaField,
                  disableReadOnly: true,
                  fieldName: field,
                  index,
                  state,
                  user,
                  edits,
                  errors,
                  handleInputChange,
                  handleSelectChange,
                  customClass: 'rph-input__request-fields',
                })}
              </div>
            </div>
          );
        })}
        {submitError && <h2 className="rph-request-fields__error-message">{submitError}</h2>}
        <button type="submit">
          {isLoading ? <span className="rph-loading-circle" /> : buttonText ? buttonText : 'Continue'}
        </button>
      </form>
      {state.use_modal && (
        <button className="rph-text-powered-by" onClick={() => setPopupRoute('/powered')}>
          {t('Powered by Rownd')}
        </button>
      )}
    </div>
  );
}
