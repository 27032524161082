import { h } from 'preact';

import { useGlobalContext } from '../hooks/use-global-context';
import CloseControl from '../CloseControl';
import Question, { TQuestion } from './Question';

type DataCollectorProps = {
  setContainerVisible: (isVisible: boolean) => void;
};

export default function DataCollector({ setContainerVisible }: DataCollectorProps) {
  const { state } = useGlobalContext();
  const { config } = state;
  const { questions } = config!;

  const { current_route } = state.nav;

  const questionName = current_route.split('/').pop();

  const question = questions.find((question: TQuestion) => question.name === questionName);

  if (!question) {
    return null;
  }

  return (
    <div className="rph-container-inner">
      <CloseControl onClose={() => setContainerVisible(false)} />
      <Question question={question} closeCollector={() => setContainerVisible(false)} />
    </div>
  );
}
