import preact, { FunctionComponent } from 'preact';
import { SquadUpEventData, SquadUpTicketData } from '../../useTickets';
import TicketSVG from '@/scripts/assets/svg/TicketSVG';
import QrSVG from '@/scripts/assets/svg/QrSVG';
import Button from '@/scripts/Components/Common/Button/Button';
import { StateUpdater, useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { generateQrCode } from '@/scripts/QrCodeGenerator/generator';
import { useGlobalContext } from '@/scripts/hooks/use-global-context';
import useBottomSheet from '@/scripts/hooks/use-bottom-sheet';
import { getUserAgentType, UserAgentType } from '@/scripts/utils';
import { logger } from '@/scripts/utils/log';
import DownloadSVG from '@/scripts/assets/svg/DownloadSVG';
import { useTranslation } from 'preact-i18next';
import useElementsWidth from '@/scripts/hooks/use-elements-width';
import { downloadPDF } from '@/scripts/utils/pdf';

interface TicketProps {
  ticket: SquadUpTicketData;
  event: SquadUpEventData;
  index: number;
  showQRCode: boolean;
  setShowQRCode: StateUpdater<boolean>;
}

const Ticket: FunctionComponent<TicketProps> = ({ ticket, event, index, showQRCode, setShowQRCode }) => {
  const { width, ref } = useElementsWidth();
  const { state } = useGlobalContext();
  const { t } = useTranslation();
  const isDarkMode = state.computed_color_mode === 'dark';
  const { isBottomSheetEnabled } = useBottomSheet();
  const [qrCode, setQrCode] = useState<{ state: 'loading' | 'loaded' | 'error'; data?: string | Blob }>({
    state: 'loading',
    data: undefined,
  });

  const month = useMemo(() => {
    const date = new Date(event.start_at);
    return date.toLocaleString('default', { month: 'short' }).toUpperCase();
  }, [event.start_at]);

  const day = useMemo(() => {
    const date = new Date(event.start_at);
    return date.getDate().toString();
  }, [event.start_at]);

  const isMobile = useMemo(() => getUserAgentType() === UserAgentType.mobile, []);

  const eventTime = useMemo(() => {
    const startDate = new Date(event.start_at);
    const endDate = new Date(event.end_at);

    const date = `${startDate.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' })}`;

    const time = `${startDate
      .toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
      .split(' ')
      .join('')} - ${endDate.toLocaleString('default', { hour: 'numeric', minute: 'numeric' }).split(' ').join('')}`;

    const timezoneString = startDate.toLocaleString('default', { timeZoneName: 'short' });
    const timezone = timezoneString.slice(timezoneString.length - 3, timezoneString.length);

    return `${date} ${time} ${timezone}`;
  }, [event.start_at, event.end_at]);

  const isTicketAvailable = useMemo(() => {
    if (!isBottomSheetEnabled) {
      return Boolean(ticket.pdf_url);
    }

    return Boolean(ticket.qrcode_str);
  }, [isBottomSheetEnabled, ticket.pdf_url, ticket.qrcode_str]);

  useEffect(() => {
    (async () => {
      if (!ticket.qrcode_str) {
        return setQrCode({ state: 'loaded', data: undefined });
      }
      try {
        const qrCode = await generateQrCode(ticket.qrcode_str, {
          format: 'svg',
          encoding: 'base64',
          color_mode: state.computed_color_mode,
        });
        setQrCode({ state: 'loaded', data: qrCode });
      } catch (e) {
        setQrCode({ state: 'error', data: undefined });
      }
    })();
  }, [ticket.qrcode_str, state.computed_color_mode]);

  const ticketIndex = useMemo(() => {
    if (isBottomSheetEnabled) {
      return `${index + 1} of ${event.tickets.length}`;
    }
    return `${index + 1}`;
  }, [isBottomSheetEnabled, index, event.tickets.length]);

  const buttonText = useMemo(() => {
    if (isMobile) {
      return showQRCode ? t('Hide QR Code') : t('Show QR Code');
    }
    return t('Download');
  }, [showQRCode, isMobile, t]);

  const downloadTicket = useCallback(() => {
    if (!ticket.pdf_url) {
      logger.error('The ticket does not have a PDF URL', ticket);
      return;
    }

    downloadPDF({
      url: ticket.pdf_url as string,
      name: event.name,
      ticketIndex: index,
      ticketsLength: event.tickets.length,
    });
  }, [event.name, event.tickets.length, index, ticket]);

  const handleButtonClick = useCallback(() => {
    if (isMobile) {
      setShowQRCode((prev) => !prev);
    } else {
      downloadTicket();
    }
  }, [downloadTicket, isMobile, setShowQRCode]);

  const imageUrl = useMemo(() => {
    const image = event.image;
    return image.thumbnail_url || image.default_url || '';
  }, [event.image]);

  return (
    <div className="rph-ticket">
      <div className="rph-ticket__header">
        <div className="rph-ticket__header__index">{ticketIndex}</div>
        <TicketSVG />
      </div>
      <div className="rph-ticket__body">
        <div className="rph-ticket__body__container">
          <div className="rph-ticket__body__image">
            {imageUrl ? (
              <img src={imageUrl} />
            ) : (
              <div className="rph-ticket__body__date">
                <div className="rph-ticket__body__date__month">{month}</div>
                <div className="rph-ticket__body__date__day">{day}</div>
              </div>
            )}
          </div>
          <div className="rph-ticket__body__content">
            <div className="rph-ticket__content__title">{event.name}</div>
            <div className="rph-ticket__content__date">{eventTime}</div>
            <div className="rph-ticket__content__type">{ticket.type}</div>
            <div className="rph-ticket__content__address">
              {event.location.name}, {event.location.address_line_1}
            </div>
          </div>
        </div>
        <div className="rph-ticket__footer" ref={ref}>
          {showQRCode && qrCode.data ? (
            <img
              className="rph-ticket__footer__qr-code"
              src={`data:image/svg+xml;base64,${qrCode.data}`}
              style="width:auto; height: 100%;"
            />
          ) : null}
          <Button
            disabled={!isTicketAvailable}
            type={isDarkMode ? 'primary' : 'secondary'}
            handleOnClick={handleButtonClick}
          >
            {buttonText}
            {isMobile ? <QrSVG /> : <DownloadSVG />}
          </Button>
          {!isTicketAvailable && (
            <div style={`max-width: ${width || 80}px !important`} className="rph-ticket__footer__not-available">
              {t('Ticket will appear 1 hour before showtime')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ticket;
