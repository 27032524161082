import { useRef, useCallback } from 'preact/hooks';
import type { PhoneResult } from 'phone';

interface ValidatorsHookApi {
  validateAndNormalizePhoneNumber: (phoneNumber: string) => Promise<PhoneResult>;
}

export const useValidators = (): ValidatorsHookApi => {
  const phone = useRef<any>(null);

  const validateAndNormalizePhoneNumber = useCallback(async (phoneNumber: string): Promise<PhoneResult> => {
    if (!phone?.current) {
      const p: any = await import('phone');
      // This is a quirk of bundle splitting
      phone.current = typeof p.default === 'function' ? p.default : p.default.phone;
    }
    return phone.current(phoneNumber);
  }, []);

  return {
    validateAndNormalizePhoneNumber,
  };
};
