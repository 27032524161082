import { useCallback, useEffect, useRef } from 'preact/hooks';

const SCRIPT_ID = 'google-recaptcha-script';

export interface RecaptchaClient {
  execute: () => Promise<string>;
}

function useReCaptcha() {
  useEffect(() => {
    if (!document.getElementById(SCRIPT_ID)) {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?onload=onloadRowndRecaptchaCallback&render=${
        process.env.GRECAPTCHA_SITE_KEY || '6LcEutAmAAAAANco6SL4wwmWtaA78M4sAIfEYNYT'
      }`;
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      recaptchaScript.id = SCRIPT_ID;
      document.head.appendChild(recaptchaScript);
    }
  }, []);

  const execute = useCallback((action = 'sign_in'): Promise<string> => {
    return new Promise((resolve, reject) => {
      function _execute() {
        const grecaptcha = window.grecaptcha;

        grecaptcha.ready(async () => {
          try {
            const token = await grecaptcha.execute(
              process.env.GRECAPTCHA_SITE_KEY || '6LcEutAmAAAAANco6SL4wwmWtaA78M4sAIfEYNYT',
              { action },
            );
            resolve(token);
          } catch (err) {
            reject(err);
          }
        });
      }

      if (!window.grecaptcha) {
        window.onloadRowndRecaptchaCallback = _execute;
      } else {
        _execute();
      }
    });
  }, []);

  return useRef({
    execute,
  }).current;
}

export default useReCaptcha;
