import classnames from 'classnames';
import { h, Fragment } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { JSXInternal } from 'preact/src/jsx';

interface InputProps
  extends Pick<
    JSXInternal.HTMLAttributes<HTMLInputElement>,
    'disabled' | 'id' | 'onChange' | 'height' | 'readOnly' | 'name' | 'placeholder' | 'value' | 'type'
  > {
  customClass?: string;
  height?: 32 | 40 | 48;
  copyable?: boolean;
  textAddOn?: string;
  label?: string;
  error?: string;
}

export default function Input({ label, textAddOn, copyable, height, customClass, error, ...props }: InputProps) {
  const [value, setValue] = useState<typeof props.value>(props.value || '');
  const [textAddOnPosition, setTextAddOnPosition] = useState(0);

  const inputHeight = height || 40;
  const type = props.type || 'text';

  const updateTextAddOnPosition = useCallback(
    (newText: string | string[] | number) => {
      if (textAddOn) {
        const exampleText = document.getElementById('rph-input__example-text');
        if (!exampleText) return;
        exampleText.innerText = newText.toString();
        if (typeof exampleText?.offsetWidth === 'number') {
          setTextAddOnPosition(exampleText.offsetWidth);
        }
      }
    },
    [textAddOn],
  );

  useEffect(() => {
    if (!textAddOn) return;
    // @ts-ignore
    updateTextAddOnPosition(value || '');
  }, [textAddOn, updateTextAddOnPosition, value]);

  const onChange = (evt: Event) => {
    setValue((evt.target as HTMLInputElement).value);
    if (props.onChange) {
      (props as any).onChange(evt);
    }
  };

  return (
    <>
      {label && <label className="rph-input__label">{label}</label>}
      <div className="rph-input-container">
        <input
          height={inputHeight}
          {...props}
          className={classnames(`rph-input rph-input-height-${inputHeight} rph-input-${type} cleanslate`, {
            'rph-input-disabled': props.disabled,
            'rph-input-copyable': copyable,
            'rph-input-error': !!error,
            [`${customClass}`]: customClass,
          })}
          onChange={onChange}
        />

        {copyable && (
          <span
            className={`rph-input-copy rph-input-copy-height-${inputHeight}`}
            onClick={(e: Event) => {
              if (value) window.navigator.clipboard.writeText(value.toString());
              (e.target as HTMLSpanElement).classList.add('rph-copied');
              setTimeout(() => {
                (e.target as HTMLSpanElement).classList.remove('rph-copied');
              }, 2000);
            }}
          />
        )}
        <span id="rph-input__example-text" />
        {textAddOn && textAddOnPosition !== 0 && (
          <>
            <div className="rph-input__input-text-addon" style={{ marginLeft: `${textAddOnPosition + 15} !important` }}>
              {textAddOn}
            </div>
          </>
        )}
      </div>
      {error && <div className="rph-input__error">{error}</div>}
    </>
  );
}
