import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { BottomSheetProps } from './BottomSheet';

const LazyLoadedBottomSheet = ({ children, customClass, id }: BottomSheetProps) => {
  const [LoadedComponent, setLoadedComponent] = useState<any>();

  useEffect(() => {
    import('./BottomSheet').then((module: any) => {
      setLoadedComponent(() => module.default);
    });
  }, []);

  return LoadedComponent ? (
    <LoadedComponent customClass={customClass} id={id}>
      {children}
    </LoadedComponent>
  ) : null;
};

export default LazyLoadedBottomSheet;
