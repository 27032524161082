import { logger } from './log';

export const downloadPDF = (opts: { url: string; name: string; ticketIndex: number; ticketsLength: number }) => {
  const { url, name, ticketIndex, ticketsLength } = opts;
  fetch(url)
    .then(async (t: Response) => {
      return t.blob().then((b: Blob) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', `${name} Ticket ${ticketIndex + 1} of ${ticketsLength}.pdf`);
        a.click();
      });
    })
    .catch((err) => {
      logger.error('Failed to download ticket', err);
    });
};
