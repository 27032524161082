import StatusIcon from '@/scripts/assets/svg/StatusIcon';
import { Status } from '@/scripts/ExternalApi';
import { h } from 'preact';
import Button from '../../Button/Button';

interface FailureModalProps {
  title: string;
  subtitle: string;
  buttonLabel: string;
  onHandleClick: () => void;
  isLoading?: boolean;
}

export default function FailureModal({ title, subtitle, onHandleClick, buttonLabel, isLoading }: FailureModalProps) {
  return (
    <div className="rph-failure-modal">
      <div className="rph-failure-modal__image">
        <StatusIcon type={Status.Failed} />
      </div>
      <div className="rph-failure-modal__title">{title}</div>
      <div className="rph-failure-modal__subtitle">{subtitle}</div>
      <Button handleOnClick={() => onHandleClick()} label={buttonLabel} isLoading={isLoading} />
    </div>
  );
}
