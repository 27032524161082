import { useCallback, StateUpdater } from 'preact/hooks';
import union from 'lodash-es/union';
import { logger } from '../utils/log';
import { ActionType, useGlobalContext } from './use-global-context';
import useRoute from './use-route';
import { useApi } from '.';
import { LoginStep, SignInState } from '../Login';
import { isInIframe } from '../utils/iframe-detect';
import { useInstantUser } from './use-instant-user';

const LOG_PREFIX = '[Google Sign-in]';

export default function useGoogleSignIn() {
  const { state, dispatch } = useGlobalContext();
  const { app } = state;
  const { navTo } = useRoute();
  const { client: api } = useApi();
  const { instantUserId } = useInstantUser();

  const authenticate = useCallback(
    ({
      purpose,
      intent,
      userData,
      setSignInState,
      setError,
      groupToJoin,
      loginHint,
    }: {
      purpose?: 'authentication' | 'connect_account';
      intent?: string;
      userData?: Record<string, any>;
      setError?: (e: string) => void;
      setSignInState?: StateUpdater<SignInState>;
      groupToJoin?: string;
      loginHint?: string;
    }) => {
      const uxMode: 'redirect' | 'popup' = isInIframe() ? 'popup' : 'redirect';
      if (!app.id) {
        return;
      }

      const googleConfig = app?.config?.hub?.auth?.sign_in_methods?.google;
      const clientId = googleConfig?.client_id;
      if (!clientId) {
        return logger.error(`${LOG_PREFIX} Unable to perform sign in. Missing client_id`);
      }

      const google = window.google;

      if (uxMode === 'redirect') {
        const codeClient = google.accounts.oauth2.initCodeClient({
          client_id: clientId,
          scope: union(googleConfig.scopes || [], ['openid', 'profile', 'email']).join(' '),
          redirect_uri: state.config?.googleIdCallbackUrl,
          state: JSON.stringify({
            app_id: app.id,
            return_to: window.location.href,
            purpose: purpose || 'authentication',
            user_data: userData,
            intent,
            group_to_join: groupToJoin,
            instant_user_id: instantUserId,
          }),
          ux_mode: 'redirect',
          login_hint: loginHint,
        });
        // Request an auth code
        codeClient.requestCode();
        return;
      }

      const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: union(googleConfig.scopes || [], ['openid', 'profile', 'email']).join(' '),
        ux_mode: uxMode,
        callback: async (response: any) => {
          if (setSignInState) setSignInState({ step: LoginStep.COMPLETING });
          try {
            const tokenResponse: any = await api
              .post('hub/auth/token', {
                json: {
                  access_token: response.access_token,
                  access_token_type: 'google',
                  app_id: app.id,
                  intent,
                  group_to_join: groupToJoin,
                  instant_user_id: instantUserId,
                },
              })
              .json();

            dispatch({
              type: ActionType.SET_SIGN_IN_METHOD,
              payload: { last_sign_in: 'google' },
            });

            dispatch({
              type: ActionType.LOGIN_SUCCESS,
              payload: {
                ...tokenResponse,
                app_id: app.id,
              },
            });

            if (setSignInState) {
              setSignInState({ step: LoginStep.SUCCESS });
            } else {
              navTo('/account/login', 'google-sign-in', {
                use_modal: true,
                login_step: LoginStep.SUCCESS,
              });
            }
          } catch (error) {
            logger.debug(`${LOG_PREFIX} Popup callback failed`, error);
            if (setSignInState) setSignInState({ step: LoginStep.ERROR });
            if (setError) setError('Google/Rownd token exchange failed');
          }
        },
        error_callback: async (err: any) => {
          if (setSignInState) setSignInState({ step: LoginStep.ERROR });
          if (setError) setError(err?.message || 'Google popup failed');
        },
      });

      tokenClient.requestAccessToken();
    },
    [
      api,
      app?.config?.hub?.auth?.sign_in_methods?.google,
      app.id,
      dispatch,
      instantUserId,
      navTo,
      state.config?.googleIdCallbackUrl,
    ],
  );

  /** The purpose of this callback is to "probe" the google One Tap prompt to see if the user has
   * any valid sessions and has not disabled the prompt for any reason. If the probe fails, then
   * we can fallback to the traditional Sign in with Google flow instead of One Tap.
   *
   * @param opts - a required parameter containing the Element ID of the One Tap prompt. This should
   * be an HTML Element hidden from view.
   */
  const probeOneTap = useCallback(
    async (opts: { promptParentId: string }): Promise<boolean> => {
      const googleConfig = app?.config?.hub?.auth?.sign_in_methods?.google;
      const clientId = googleConfig?.client_id;
      if (!clientId) {
        logger.error(`${LOG_PREFIX} Missing client_id`);
        4;
        return Promise.resolve(false);
      }

      // Tip for anyone working on this in the future: if One Tap is not showing up, clear the
      // 'g_state' cookie
      const google = window.google;
      google.accounts.id.initialize({
        use_fedcm_for_prompt: false,
        client_id: clientId,
        callback: () => {
          // This should never be called since this is just a probe. It should never take over
          // the actual authentication logic as this is totally hidden from the user.
          logger.warn(`${LOG_PREFIX} Sign in probe callback invoked. This should never happen.`);
        },
        prompt_parent_id: opts.promptParentId,
      });

      return new Promise<boolean>((resolve) => {
        google.accounts.id.prompt((notification: any) => {
          resolve(notification?.isNotDisplayed() || notification?.isSkippedMoment());
        });
      });
    },
    [app?.config?.hub?.auth?.sign_in_methods?.google],
  );

  const showOneTap = useCallback(
    async ({
      setHasLoadedOneTap,
      setLoadingOneTap,
      setSignInState,
      setError,
      initializeCallback,
      promptParentId,
      promptCallback,
      userData,
      groupToJoin,
      loginHint,
    }: {
      setHasLoadedOneTap?: (loaded: boolean) => void;
      setLoadingOneTap?: (loading: boolean) => void;
      setSignInState?: StateUpdater<SignInState>;
      initializeCallback?: (response: any) => void;
      setError?: (error: null | string) => void;
      promptParentId?: string;
      promptCallback?: (notification: any) => void;
      userData?: Record<string, any>;
      groupToJoin?: string;
      loginHint?: string;
    }): Promise<boolean> => {
      if (setLoadingOneTap) setLoadingOneTap(true);

      // Don't show one-tap when the Hub is opened in a mobile app. The mobile SDKs handle displaying
      // one-tap.
      if (state.config?.displayContext === 'mobile_app') {
        logger.debug(`${LOG_PREFIX} Not displaying Google One Tap UI in mobile_app display context`);
        return false;
      }

      if (!app.id) {
        return false;
      }

      const googleConfig = app?.config?.hub?.auth?.sign_in_methods?.google;
      const clientId = googleConfig?.client_id;
      if (!clientId) {
        logger.error(`${LOG_PREFIX} Unable to perform sign in. Missing client_id`);
        return false;
      }

      // Tip for anyone working on this in the future. If One-Tap is not showing up, clear the
      // 'g_state' cookie
      const google = window.google;
      google.accounts.id.initialize({
        use_fedcm_for_prompt: false,
        client_id: clientId,
        cancel_on_tap_outside: false,
        auto_select: false,
        login_hint: loginHint,
        prompt_parent_id: promptParentId,
        callback: async (response: any) => {
          try {
            if (setSignInState) setSignInState({ step: LoginStep.COMPLETING });
            logger.debug(`${LOG_PREFIX} One Tap callback invoked:`, response);
            logger.debug(`${LOG_PREFIX} Exchanging Google ID token for a Rownd JWT...`);
            const tokenResponse: any = await api
              .post('hub/auth/token', {
                json: {
                  id_token: response.credential,
                  app_id: app.id,
                  purpose: state.nav.options?.purpose,
                  user_data: userData,
                  group_to_join: groupToJoin,
                  instant_user_id: instantUserId,
                },
              })
              .json();

            dispatch({
              type: ActionType.SET_SIGN_IN_METHOD,
              payload: { last_sign_in: 'google' },
            });

            dispatch({
              type: ActionType.LOGIN_SUCCESS,
              payload: {
                ...tokenResponse,
                app_id: app.id,
              },
            });

            if (setSignInState) {
              setSignInState({ step: LoginStep.SUCCESS });
            } else {
              navTo('/account/login', 'google-sign-in', {
                use_modal: true,
                login_step: LoginStep.SUCCESS,
              });
            }

            if (initializeCallback) {
              initializeCallback(response);
            }
          } catch (error) {
            logger.debug(`${LOG_PREFIX} One Tap callback failed`, error);
            if (setError) setError('Google/Rownd token exchange failed');
          }
        },
      });

      return new Promise<boolean>((resolve) => {
        google.accounts.id.prompt(async (notification: any) => {
          if (setLoadingOneTap) setLoadingOneTap(false);
          if (setHasLoadedOneTap) setHasLoadedOneTap(true);

          if (notification.getDismissedReason() === 'credential_returned') {
            return resolve(true);
          }

          let displayed = true;
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            logger.error(
              `${LOG_PREFIX} One-Tap prompt was not displayed. ${
                notification.getDismissedReason() || 'not-displayed or skipped'
              }`,
            );
            displayed = false;
          }

          if (promptCallback) {
            promptCallback(notification);
          }

          resolve(displayed);
        });
      });
    },
    [
      api,
      app?.config?.hub?.auth?.sign_in_methods?.google,
      app.id,
      dispatch,
      instantUserId,
      navTo,
      state.config?.displayContext,
      state.nav.options?.purpose,
    ],
  );

  return {
    authenticate,
    showOneTap,
    probeOneTap,
  };
}
