import { h } from 'preact';
import { useTranslation } from 'preact-i18next';
import { useGlobalContext } from './hooks/use-global-context';

interface Props {
  onBack?: (() => void) | null;
  showText?: boolean;
}

export default function BackControl({ onBack, showText = true }: Props) {
  const { state } = useGlobalContext();
  const { t } = useTranslation();

  // Mobile apps handle their own view lifecycle
  if (state.config?.displayContext === 'mobile_app') {
    return null;
  }

  function handleBack() {
    onBack && onBack();
  }

  return !state.is_container_visible ? null : (
    <span className="rph-back" onClick={handleBack}>
      {showText && <span className="rph-back-text">{t('Back')}</span>}
    </span>
  );
}
