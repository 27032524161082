import { useEffect, useState } from 'preact/hooks';

export const sessionContainer = {
  storage: {} as Record<string, string>,
};

const useSessionStorage = () => {
  const [storage, setStorage] = useState<null | Record<string, string>>(null);
  useEffect(() => {
    const updateStorage = () => {
      // localStorage is included to help debug
      const sessionStorage = { ...window?.sessionStorage, ...window?.localStorage };
      const filteredSessionStorage: Record<string, string> = {};
      Object.keys(sessionStorage).forEach((key) => {
        if (key.startsWith('rph_')) {
          filteredSessionStorage[key] = sessionStorage[key];
        }
      });
      setStorage(filteredSessionStorage);
      sessionContainer.storage = filteredSessionStorage;
    };
    updateStorage();

    window.addEventListener('storage', updateStorage);
    return () => {
      window.removeEventListener('storage', updateStorage);
    };
  }, []);

  return storage;
};

export default useSessionStorage;
