type JSONValue = { [key: string]: any } | any[];

export const jsonPath = (obj: JSONValue, query: string): any => {
  // Remove leading '$' if present, as it represents the root object
  if (query.startsWith('$')) {
    query = query.substring(1);
  }

  // Remove leading dot if the query starts with one after the '$'
  if (query.startsWith('.')) {
    query = query.substring(1);
  }

  const pathParts = query.split('.');

  let current = obj;
  for (const part of pathParts) {
    if (Array.isArray(current)) {
      if (part === '*') {
        // Flatten and apply the remaining path to all elements in the array
        return current.flatMap((item) => jsonPath(item, pathParts.slice(1).join('.')));
      } else if (!isNaN(Number(part))) {
        // Access array by index
        current = current[Number(part)];
      } else {
        return undefined;
      }
    } else if (typeof current === 'object' && current !== null) {
      current = current[part];
    } else {
      return undefined;
    }
  }
  return current;
};

export const wildcardMatch = (str: string, pattern: string): boolean => {
  // Convert wildcard pattern to regular expression
  const regexPattern = pattern.replace(/\*/g, '.*').replace(/\?/g, '.');
  const regex = new RegExp(`^${regexPattern}$`);

  // Test the string against the regular expression
  return regex.test(str);
}
