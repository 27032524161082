import { useCallback, useState } from 'preact/hooks';
import { useInterval } from './hooks';
import { useGlobalContext, ActionType } from './hooks/use-global-context';
import { QuestionField } from './DataCollector/Question';
import { logger } from './utils/log';

const MIN_TIME_BETWEEN_QUESTIONS = 90; // seconds

export default function TimerLoop() {
  const { state, dispatch } = useGlobalContext();

  const [lastQuestionAskTime, setLastQuestionAskTime] = useState(Date.now());

  const checkForTimedQuestions = useCallback(() => {
    const now = Date.now();

    // Don't interrupt the user if something else is open
    // Reset the clock so we don't jump out at them as soon
    // as they close the container
    if (state.is_container_visible || state.use_modal || state.automations?.active_automation) {
      setLastQuestionAskTime(now);
      return;
    }

    if (now - MIN_TIME_BETWEEN_QUESTIONS * 1000 > lastQuestionAskTime) {
      logger.debug('ready to ask another question');
      const { questions } = state.config!;

      // Find the next unanswered, 'auto/timed' question
      questions
        .filter((question) => question.trigger.type === 'auto')
        .some((question) => {
          const isAlreadyAnswered = (question.fields as any[]).every(
            (field) => !!state.user.data[(field as QuestionField).name || (field as string)],
          );
          if (!isAlreadyAnswered) {
            dispatch({
              type: ActionType.CHANGE_ROUTE,
              payload: {
                route: `/questions/${question.name}`,
                trigger: 'nav',
                event_id: Math.random().toString(36).slice(2),
              },
            });
            setLastQuestionAskTime(now);
            return true;
          }

          return false;
        });
    }
  }, [
    dispatch,
    lastQuestionAskTime,
    state.automations?.active_automation,
    state.config,
    state.is_container_visible,
    state.use_modal,
    state.user.data,
  ]);

  // App loop to run every 15 seconds for checking for events that should fire
  useInterval(checkForTimedQuestions, 15000);

  return null;
}
