import classnames from 'classnames';
import { h } from 'preact';
import Loading from '../../Loading/Loading';
import SwoopsAnimation from '../../Swoops/SwoopsAnimation';

interface LoadingModalProps {
  title: string;
  subtitle?: string;
  customClass?: string;
  swoops?: boolean;
}

export default function LoadingModal({ title, subtitle, customClass, swoops }: LoadingModalProps) {
  return (
    <div
      className={classnames('rph-loading-modal', {
        [`${customClass}`]: customClass,
      })}
    >
      {swoops && <SwoopsAnimation />}
      <div className="rph-loading-modal__title">{title}</div>
      {subtitle && <div className="rph-loading-modal__subtitle">{subtitle}</div>}
      <Loading />
    </div>
  );
}
