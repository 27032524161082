import type { TFunction } from 'i18next';
import { initReactI18next, I18nextProvider } from 'preact-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

type I18nInitOpts = {
  baseUrl: string;
};

export async function init({ baseUrl }: I18nInitOpts): Promise<TFunction> {
  const { default: i18n } = await import('i18next');
  return i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      // debug: true,
      interpolation: {
        escapeValue: false, // already done by React
      },
      backend: {
        loadPath: `${baseUrl}/static/locales/{{lng}}.json`,
        crossDomain: true,
      },
      react: {
        useSuspense: false,
      },
    });
}

export { I18nextProvider };
